import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { completeTaskCurrentUser } from '../../../../../actions/auth';
import { setNotification } from '../../../../../actions/notification';
import { adaptBackToFrontTablePanelControl } from '../../../../../adapters/esgCustomMetrics';
import { ROUTES } from '../../../../../constants/routes';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import { EsgMetricsEnhanced } from '../../../../../types/entities/esgMetrics';
import { IGetBackendTask } from '../../../../../types/entities/task';
// import { InputSize } from '../../../../../types/utilsEnums/input';
import { useGetCustomMetrics } from '../../../../customize/customMetrics/hooks/useGetCustomMetrics';
// import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../layout/NewFilters/Filters';
// import CreateTask from '../../../../tasks/components/createTask/CreateTask';
// import EditTask from '../../../../tasks/components/editTask/EditTask';
import Button from '../../../../ui/button/Button';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import SlideToggleFacade from '../../../../ui/formComponents/slideToggle3/SlideToggleFacade';
import Icon from '../../../../ui/icon/Icon';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../ui/modal/Modal';
import NarrativeModal from './components/narrativeModal/NarrativeModal';
import PersonalizedMetricsBookMeeting from './components/personalizedMetricsBookMeeting/PersonalizedMetricsBookMeeting';
import useColumns from './hooks/useColumns';
import { useGetMetrics } from './hooks/useGetMetrics';
import useToggleOptions from './hooks/useToggleOptions';
import { renderFrameworks, renderStatus } from './utils/utils';
import useGetData from 'components/tasks/hooks/useGetData';
import { UserContext } from 'context/userContext';
import CreateTask from 'components/tasks/components/createTask/CreateTask';
import { completeTaskCurrentUser } from 'actions/auth';

const Metrics = () => {
  const { t: trans } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics'
  });

  const { startDate, endDate } = useContext(FilterDatesContext);
  const { data: customMetricsData, isLoading: isLoadingCustomMetrics } = useGetCustomMetrics();

  const user = useContext(UserContext);
  const { users, tags, loading } = useGetData();

  const [showCreate, setShowCreate] = useState(false);
  const [tasks, setTasks] = useState<IGetBackendTask[]>([]);
  const navigate = useNavigate();
  const toggleOptions = useToggleOptions();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<string>(toggleOptions[0].id);

  const [filters, setFilters] = useState<IFilter[]>([
    {
      field: 'data_source',
      type: 'eq',
      value: 'T'
    },
    {
      field: 'esg_category',
      type: 'eq',
      value: mode
    }
  ]);

  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'name',
      order: 'asc'
    }
  ]);

  const [showPersonalizedModal, setShowPersonalizedModal] = useState(false);
  const [narrativeToEdit, setNarrativeToEdit] = useState<EsgMetricsEnhanced>();

  const [metricToEdit, setMetricToEdit] = useState<EsgMetricsEnhanced>();

  const handleAddTask = (task: IGetBackendTask) => {
    setTasks((prev) => [task, ...prev]);
    setShowCreate(false);
    dispatch(setNotification(t('notification.createTask')));
    if (task.assigned_to === user?.id) {
      const newTasks = [...(user?.tasks?.items ?? [])];
      newTasks.push(task);
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total + 1,
          total_completed: user.tasks.total_completed,
          items: newTasks
        })
      );
    }
  };
  useEffect(() => {
    setFilters([
      {
        field: 'data_source',
        type: 'eq',
        value: 'T'
      },
      {
        field: 'esg_category',
        type: 'eq',
        value: mode
      }
    ]);
  }, [mode]);
  const columns = useColumns(mode === 'generalInformation');

  // const sortingOptions = useSortingOptions();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, editElement } = useGetMetrics({
    filters,
    sorters,
    mode,
    startDate,
    endDate
  });

  const handleEditMetric = (task: IGetBackendTask) => {
    if (!metricToEdit) return;
    dispatch(setNotification(t('notification.createTask')));

    const editedMetric: EsgMetricsEnhanced = {
      ...metricToEdit,
      id: metricToEdit.id ?? '',
      responsible_task_id: task.id,
      assigned_to: {
        id: task.assigned_to,
        first_name: task.assigned_user,
        last_name: ''
      }
    };
    editElement(editedMetric);
    setMetricToEdit(undefined);
  };

  const renderEditButton = (metric: EsgMetricsEnhanced) => {
    const routeMapper: {
      [key: string]: string;
    } = {
      vehicles: ROUTES.MEASURE_VEHICLES,
      facilities: ROUTES.MEASURE_FACILITIES,
      hubs: ROUTES.MEASURE_HUBS,
      purchases: ROUTES.MEASURE_ACTIVITY_PURCHASES,
      employees: ROUTES.MEASURE_ACTIVITY_EMPLOYEES,
      travels: ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS,
      electricity: ROUTES.MEASURE_FACILITIES,
      transport_distribution_upstream:
        ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream',
      transport_distribution_downstream:
        ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream'
    };

    const onClickEdit = () => {
      if (mode === 'social' || mode === 'governance') {
        setNarrativeToEdit(metric);
      } else if (metric.source_type in routeMapper) {
        navigate(routeMapper[metric.source_type]);
      }
    };

    const dropdownOptions = [
      {
        id: 'edit',
        name: metric.status === 'COMPLETE' ? t('editMetric') : t('addData'),
        onClick: onClickEdit
      }
    ];

    if (metric?.responsible_task_id) {
      dropdownOptions.push({
        id: 'editResponsible',
        name: t('editResponsible'),
        onClick: () => {
          setShowCreate(true);
        }
      });
    } else {
      dropdownOptions.push({
        id: 'assignResponsible',
        name: t('assignResponsible'),
        onClick: () => {
          setShowCreate(true);
        }
      });
    }

    return (
      <div className='flex justify-end' style={{ width: '100%' }}>
        <ButtonDropdown
          button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
          options={dropdownOptions}
        />
      </div>
    );
  };

  const renderCategory = (category: string) => {
    if (!category) return '-';
    if (category === 'social_governance') {
      return t(`categories.${mode}`);
    }
    return t(`categories.${category}`);
  };
  const placeholderGeneralInfoData = {
    page: 1,
    total: 5,
    total2: 2,
    items: [
      {
        id: 'f4031d5b-4c6c-49b7-a010-f582f3ab5968',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        data_point_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Doble Materialidad',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'COMPLETE',
        assigned_to: {
          id: 'abcd1234',
          first_name: 'José',
          last_name: 'L.'
        },
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: 'f4031d5b-4c6c-49b7-a010-f582f3ab5968',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        data_point_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Taxonomía de la UE',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: 'f4031d5b-4c6c-49b7-a010-f582f3ab5968',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        data_point_id: 'ad94be10-bb6f-4583-977f-318862ac2ef3',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Bases para la preparación de la declaración de sostenibilidad.',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: 'a954261d-9786-41e4-887e-7a21f88e7d86',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: 'bb3e0983-8af0-4927-8688-69016c580979',
        data_point_id: 'bb3e0983-8af0-4927-8688-69016c580979',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'El alcance de consolidación de la declaración de sostenibilidad consolidada es el mismo que el de los estados financieros.',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: '67147cc5-d4ea-43e9-b245-b95557c5c7f5',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: '9b241ca4-7fe5-4a5b-9df5-195dd9e3083d',
        data_point_id: '9b241ca4-7fe5-4a5b-9df5-195dd9e3083d',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Indicación de empresas subsidiarias incluidas en la consolidación que están exentas de informes de sostenibilidad individuales o consolidados.',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: '168ce304-dc5b-4348-a036-8672af5baf1c',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: 'd164d450-3672-4b7c-b1bc-d533481228cb',
        data_point_id: 'd164d450-3672-4b7c-b1bc-d533481228cb',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Divulgación del grado en que la declaración de sostenibilidad cubre la cadena de valor ascendente y descendente.',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      },
      {
        id: '4a78ab3e-cb06-4727-b9f7-c7f5b605b8f2',
        organization_data_point_id: '12345678-1234-1234-1234-1234567890ab',
        datapoint_id: '9a348018-d9fc-4978-9226-be3a1d64ffa2',
        data_point_id: '9a348018-d9fc-4978-9226-be3a1d64ffa2',
        esg_category: 'environmental',
        category: 'environmental',
        esg_subcategory: '',
        name: 'Se ha utilizado la opción de omitir información específica correspondiente a propiedad intelectual, know-how o resultados de innovación.',
        short_name: null,
        unit: 'travel',
        data_type: 'business_travels',
        data_format: 'float',
        source_type: 'travels',
        sector: 'all',
        auto_calculated: false,
        default_mandatory: true,
        data_source: true,
        value: null,
        description: null,
        file_urls: [],
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        responsible_task_id: null,
        last_updated_by: null,
        mandatory: true,
        status: 'INCOMPLETE',
        assigned_to: null,
        frameworks: ['CSRD'],
        type: 'some_type',
        subcategory: 'some_subcategory',
        relevance: 'high',
        value_unit_id: 'unit123',
        year: {
          id: 'year2024',
          name: '2024'
        }
      }
    ]
  };
  const renderDobleMat = () => {
    const dropdownOptions = [
      {
        id: 'double_materiality',
        name: t('go_double_materiality'),
        onClick: () => {
          navigate(ROUTES.MEASURE_ESG_METRICS + '/double_materiality');
        }
      },
      {
        id: 'editResponsible',
        name: t('editResponsible'),
        onClick: () => {
          setShowCreate(true);
        }
      }
    ];

    return (
      <div className='flex justify-end' style={{ width: '100%' }}>
        <ButtonDropdown
          button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
          options={dropdownOptions}
        />
      </div>
    );
  };

  const parseData = () => {
    if (mode === 'generalInformation') {
      return placeholderGeneralInfoData.items?.map((item, index) => {
        return {
          ...item,
          name: item.name,
          frameworks: renderFrameworks(item.frameworks, t),
          status: renderStatus(item.status, trans),
          edit: renderDobleMat()
        };
      });
    }
    if (!data) {
      return [];
    }
    return data.items?.map((item) => {
      return {
        ...item,
        name: t(`list.${item.name}`),
        category: renderCategory(item.category),
        frameworks: renderFrameworks(item.frameworks, t),
        status: renderStatus(item.status, trans),
        edit: renderEditButton(item)
      };
    });
  };

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };
  return (
    <>
      <div className='control-panel-metrics flex flex-col'>
        <div className='flex gap-2 items-center'>
          <span className='font-14 weight-600'>{t('title')}</span>
          <SlideToggleFacade
            handleSwitchMode={(mode) => {
              setMode(mode);
            }}
            idSelected={mode}
            options={toggleOptions}
          />
        </div>
        <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
          <Filters.Menu></Filters.Menu>

          <InfiniteList
            data={
              mode === 'personalized'
                ? adaptBackToFrontTablePanelControl(customMetricsData, trans)
                : parseData()
            }
            columns={columns}
            loading={customMetricsData ? isLoadingCustomMetrics : isFetchingNextPage}
            firstLoading={isLoading}
            fetchData={fetchNextPage}
            total={data?.total}
            header={
              <>
                <div className='flex gap-x-2 items-center'>
                  {/* <FilterText
                    field='name'
                    type='il'
                    placeholder={t('searchMetric')}
                    size={InputSize.SMALL}
                  /> */}
                  {/* <Sorting
                      options={sortingOptions}
                      label={t('input.sorting.orderBy')}
                      placeholder={t('input.placeholderSelect')}
                      onSort={onSort}
                      defaultSort={{
                        id: sorters[0].field,
                        name: t(`input.sorting.datapoints.${sorters[0].field}`),
                        direction: sorters[0].order
                      }}
                    /> */}
                </div>

                {mode === 'personalized' && (
                  <div className='flex ml-auto gap-2'>
                    <Button
                      style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
                      lookAndFeel='secondary'
                      size='small'
                      onClick={() => setShowPersonalizedModal(true)}>
                      {t('moreInfo')}
                    </Button>
                  </div>
                )}
              </>
            }
          />
        </Filters.Root>
      </div>

      {/* <Modal
        show={!!(metricToEdit && !metricToEdit.assigned_to.id)}
        onClose={() => setMetricToEdit(undefined)}
        maxWidth='600px'
        width='600px'>
        <CreateTask
          handleAddTask={handleEditMetric}
          users={users}
          tags={tags}
          selectedMetric={
            metricToEdit
              ? {
                  ...metricToEdit,
                  category:
                    metricToEdit?.category === 'social_governance'
                      ? mode
                      : metricToEdit
                      ? metricToEdit?.category
                      : ''
                }
              : undefined
          }
          preSetOrganization={null}
        />
      </Modal>
      <Modal
        show={!!(metricToEdit && metricToEdit.assigned_to.id)}
        onClose={() => setMetricToEdit(undefined)}
        maxWidth='600px'
        width='600px'>
        <EditTask
          handleEditTask={handleEditMetric}
          users={users}
          tags={tags}
          idTaskControlPanel={metricToEdit?.responsible_task_id}
        />
      </Modal> */}
      <Modal.WithPortal
        show={showPersonalizedModal}
        onClose={() => setShowPersonalizedModal(false)}
        width='584px'
        maxWidth='584px'>
        <PersonalizedMetricsBookMeeting />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!narrativeToEdit}
        onClose={() => setNarrativeToEdit(undefined)}
        width='756px'
        maxWidth='756px'>
        {narrativeToEdit && (
          <NarrativeModal
            metric={narrativeToEdit}
            closeModal={() => setNarrativeToEdit(undefined)}
            type={mode}
            editElement={editElement}
          />
        )}
      </Modal.WithPortal>
      <Modal show={showCreate} onClose={() => setShowCreate(false)} maxWidth='600px' width='600px'>
        <CreateTask
          handleAddTask={handleAddTask}
          users={users}
          tags={tags}
          preSetOrganization={null}
        />
      </Modal>
    </>
  );
};

export default Metrics;
