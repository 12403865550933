import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  patchTransportSections,
  updateShipment
} from '../../../../../services/api/transportRoutes';
import { Shipment, TransportSection } from '../../../../../types/entities/shipment';
import { User } from '../../../../../types/entities/user';
import apiFetch from '../../../../../utils/apiFetch';
import { convertStringToDate } from '../../../../../utils/convertDates';
import { formatDate } from '../../../../../utils/formatDate';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormShipment from '../common/FormShipment';

type Props = {
  editShipment: (shipment: Shipment) => void;
  shipmentToEdit: Shipment;
  user: User;
};
const EditShipment = ({ editShipment, shipmentToEdit, user }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction') || 'downstream';

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());

  let shipmentStartDate = null;
  if (shipmentToEdit.start_date && transportDirection === 'downstream') {
    shipmentStartDate = new Date(shipmentToEdit.start_date);
  }
  if (shipmentToEdit.end_date && transportDirection === 'upstream') {
    shipmentStartDate = new Date(shipmentToEdit.end_date);
  }

  if (shipmentStartDate) {
    shipmentStartDate.setHours(0, 0, 0, 0);
  }

  const [formData, setFormData] = useState({
    name: shipmentToEdit.name ?? '',
    quantity: shipmentToEdit.quantity_transported.toString() ?? '',
    unit: {
      id: shipmentToEdit.unit_id,
      name: ''
    },
    supplier: shipmentToEdit.supplier ?? '',
    refrigerated: {
      id: shipmentToEdit.refrigerated ? 'yes' : 'no',
      name: shipmentToEdit.refrigerated ? t('shipments.yes') : t('shipments.no')
    },
    startDate: shipmentStartDate ? formatDate(shipmentStartDate) : formatDate(today),
    frequency: {
      id: shipmentToEdit.transport_frequency
        ? shipmentToEdit.transport_frequency.toString()
        : 'once',
      name: shipmentToEdit.transport_frequency
        ? t(`shipments.${shipmentToEdit.transport_frequency.toString()}`)
        : t('shipments.once')
    },
    transportSections: [
      {
        location_origin_id: '',
        location_destination_id: '',
        origin: '',
        destination: '',
        transport_type: ''
      }
    ],
    errors: [] as ErrorType[]
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    // Get transport sections
    const responseTransportSections = await apiFetch(
      'get',
      '/transport_sections',
      {},
      { 'transport-route-id': shipmentToEdit.id }
    );

    setLoading(false);

    const orderedTransportSections: TransportSection[] = [];
    const firstElement = responseTransportSections.data.find(
      (elem: TransportSection) =>
        !responseTransportSections.data.find(
          (elem2: TransportSection) => elem.location_origin_id === elem2.location_destination_id
        )
    );
    if (firstElement) {
      orderedTransportSections.push(firstElement);
    }

    for (let i = 0; i < responseTransportSections.data.length - 1; i++) {
      const foundElement = responseTransportSections.data.find(
        (elem: TransportSection) =>
          elem.location_origin_id === orderedTransportSections[i].location_destination_id
      );
      if (foundElement) {
        orderedTransportSections.push(foundElement);
      }
    }

    setFormData((prev) => ({
      ...prev,
      transportSections: orderedTransportSections.map((elem: TransportSection) => {
        let transportType = elem.transport_type;
        if (transportType === 'road') {
          if (elem.travel_method === 'car' && elem.electric) {
            transportType = 'electric_car';
          } else {
            transportType = elem.travel_method || '';
          }
        }
        return {
          ...elem,
          transport_type: transportType
        };
      })
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditShipment = async () => {
    try {
      setLoadingButton(true);

      const transportTypeList = formData.transportSections.map((section) => section.transport_type);

      const transportTypeSet = new Set(transportTypeList);

      const startDateParsed = new Date(
        moment(convertStringToDate(formData.startDate)).format('YYYY-MM-DD')
      );
      const endDateParsed = new Date(
        moment(convertStringToDate(formData.startDate)).format('YYYY-MM-DD')
      );

      if (transportDirection === 'downstream') {
        endDateParsed.setDate(endDateParsed.getDate());
      }
      if (transportDirection === 'upstream') {
        startDateParsed.setDate(startDateParsed.getDate());
      }

      await updateShipment(shipmentToEdit.id ?? '', {
        name: formData.name,
        supplier: formData.supplier,
        start_date: startDateParsed,
        end_date: endDateParsed,
        quantity_transported: parseFloat(formData.quantity),
        unit_id: formData.unit.id,
        organization_id: user.selectedOrganization ?? ''
      });

      // Bulk edit transport sections
      const transportSectionsEdit: TransportSection[] = [];
      formData.transportSections.forEach((elem, index) => {
        let transport_type = elem.transport_type;
        let travel_method = undefined;
        let electric = false;
        if (elem.transport_type === 'truck' || elem.transport_type === 'car') {
          transport_type = 'road';
          travel_method = elem.transport_type;
        }
        if (elem.transport_type === 'electric_car') {
          transport_type = 'road';
          travel_method = 'car';
          electric = true;
        }
        transportSectionsEdit.push({
          location_origin_id:
            index === 0 ? uuidv4() : transportSectionsEdit[index - 1].location_destination_id,
          location_destination_id: uuidv4(),
          origin: elem.origin,
          destination: elem.destination,
          transport_type,
          travel_method,
          electric,
          transport_route_id: shipmentToEdit.id
        });
      });

      const dataTransportSections = await patchTransportSections(
        user?.selectedOrganization ?? '',
        transportSectionsEdit
      );

      if (
        dataTransportSections === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
        dataTransportSections === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
        dataTransportSections === 'SAME_COUNTRY_FOR_MARITIME_TRANSPORT'
      ) {
        setFormData((prev) => ({
          ...prev,
          errors: [
            ...prev.errors,
            {
              error: 'invalidTravelMode'
            }
          ]
        }));
        return;
      }
      editShipment({
        ...shipmentToEdit,
        name: formData.name,
        supplier: formData.supplier,
        start_date: startDateParsed,
        end_date: endDateParsed,
        transport_route_type: transportTypeSet.size === 1 ? transportTypeList[0] : 'multiple',
        transport_origin: formData.transportSections[0].origin,
        transport_destination:
          formData.transportSections[formData.transportSections.length - 1].destination,
        transport_frequency: formData.frequency.id,
        transport_direction: transportDirection,
        status: 'pending',
        quantity_transported: parseFloat(formData.quantity),
        unit_id: formData.unit.id
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (
        err.response.status === 422 &&
        (err.response.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
          err.response.data === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
          err.response.data === 'SAME_COUNTRY_FOR_MARITIME_TRANSPORT')
      ) {
        setFormData((prev) => ({
          ...prev,
          errors: [...formData.errors, { error: 'invalidTravelMode' }]
        }));
      }
      setLoadingButton(false);
    }
  };

  return (
    <div className='new-shipment'>
      <FormHeader
        title={t(`shipments.${transportDirection}.editShipment`)}
        description={t(`shipments.${transportDirection}.editShipmentDescription`)}
      />
      {(transportDirection === 'upstream' || transportDirection === 'downstream') && (
        <FormShipment
          formData={formData}
          setFormData={setFormData}
          transportDirection={transportDirection}
        />
      )}
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('shipments.save')}
          onClick={handleEditShipment}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {formData.errors && formData.errors.find((elem) => elem.error === 'invalidTravelMode') && (
        <span className='error-text error-font error-text-color'>
          {t('error.invalidTravelMode')}
        </span>
      )}
    </div>
  );
};

export default EditShipment;
