import InfiniteScroll from 'react-infinite-scroll-component';
import Checkbox from '../../../ui/formComponents2/checkbox/Checkbox';
import { MaterialIcon } from './MaterialIcon/MaterialIcon';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import { useLCAMaterials } from '../../hooks/useLCAMaterials';
import { LCAFormMaterial } from './SelectMaterialModal/constants';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { adaptLCAMaterialsForMaterialTable } from 'adapters/lcaMaterials';

type Props = {
  selectedMaterials: LCAFormMaterial[];
  setSelectedMaterials: React.Dispatch<React.SetStateAction<LCAFormMaterial[]>>;
  filters?: IFilter[];
};

export const MaterialTable = ({ selectedMaterials, setSelectedMaterials, filters }: Props) => {
  const { data, hasNextPage, fetchNextPage, isFetching } = useLCAMaterials({
    filters,
    adapter: adaptLCAMaterialsForMaterialTable
  });
  const { t } = useLCATranslation({ keyPrefix: 'lcaDetail' });

  const onCheck = (material: LCAFormMaterial) => (selected: boolean) => {
    if (selected) {
      setSelectedMaterials((prev) => [...prev, material]);
    } else {
      setSelectedMaterials((prev) => prev.filter((m) => m.material_id !== material.material_id));
    }
  };

  return (
    <InfiniteScroll
      dataLength={data?.length ?? 0}
      next={fetchNextPage}
      loader={null}
      hasMore={hasNextPage}
      style={{ overflow: 'hidden', paddingBottom: '1rem' }}
      scrollableTarget='modal-content'>
      <div className='flex-col gap-y-4'>
        {data?.map((material) => (
          <Checkbox
            key={material.material_id}
            text={
              <div className='flex gap-x-2'>
                <MaterialIcon
                  type={material.type}
                  disabled={!material.impact_source_id}
                  lookAndFeel='material'
                />
                <div className='flex-col gap-y-1'>
                  <span className='font-body-b2-sb text-neutral-gray-cold-20'>{material.name}</span>
                  <span className='font-body-b2-r text-neutral-gray-warm-20'>
                    {material.supplier}
                  </span>
                </div>
                {!material.impact_source_id && <Tooltip text={t('matchingTooltip')} />}
              </div>
            }
            onChange={onCheck(material)}
            selected={selectedMaterials.some((m) => m.material_id === material.material_id)}
          />
        ))}

        {isFetching && <LoaderTables mode='fit' />}
      </div>
    </InfiniteScroll>
  );
};
