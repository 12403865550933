import { useLocation } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';
import DatapointListRenderer, { FrameworkDatapointBox } from './DatapointListRenderer';
import { useContext } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useCSRDOutputData, useGHGOutputData } from 'customHooks/api/useSolOrgData';
import Dot from 'components/ui/dot/Dot';
import { UserContext } from 'context/userContext';
import { CSRDOutputData, GHGOutputData } from 'services/api/sotOrganizationData';

const FrameworkDatapoints = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const category = pathname.split('/')[4];
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  const sector = foundOrganization?.sector;
  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];
  const getCategoryMode = (category: string, t: any): any => {
    const esgCategories = ['general', 'environmental', 'social', 'governance'];
    const ghgScopes = ['general_information', 'scope_1', 'scope_2', 'scope_3'];

    if (esgCategories.includes(category)) {
      return {
        type: 'csrd',
        esgCategory: category,
        title: t(`controlPanel.frameworkProgress.${category}Datapoints`),
        icon:
          category === 'general'
            ? 'vat'
            : category === 'environmental'
            ? 'earth'
            : category === 'social'
            ? 'members'
            : 'own_facility'
      };
    }

    if (ghgScopes.includes(category)) {
      return {
        type: 'ghg',
        scope: category,
        title: renderGHGTitle(category, t),
        icon:
          category === 'general_information'
            ? 'vat'
            : category === 'scope_1'
            ? 'fire'
            : category === 'scope_2'
            ? 'electricity'
            : 'cloud'
      };
    }

    return null;
  };

  const renderGHGTitle = (category: string, t: any) => {
    return (
      <>
        {t(
          `controlPanel.frameworkProgress.${
            category === 'general_information' ? 'generalInfo' : category.replace('scope_', 'scope')
          }`
        )}
        <Dot type='text' style={{ width: '0.3rem', height: '0.3rem' }} />
        {t(
          `controlPanel.frameworkProgress.${
            category === 'general_information'
              ? 'generalInfoTitle'
              : `${category.replace('scope_', 'scope')}Title`
          }`
        )}
      </>
    );
  };

  const categoryMode = getCategoryMode(category, t);
  const categoryModeMapper = (categoryItem: string | undefined) => {
    if (categoryItem === 'general_information' || !categoryItem) {
      return undefined;
    }
    return categoryItem.replace('scope_', 's');
  };

  const { csrdOutputData, isLoading: csrdLoading } = useCSRDOutputData({
    sector,
    year: year?.toString(),
    esgCategory: categoryMode?.type === 'csrd' ? categoryMode.esgCategory : undefined
  });

  const { ghgOutputData, isLoading: ghgLoading } = useGHGOutputData({
    sector,
    year: year?.toString(),
    scope: categoryMode?.type === 'ghg' ? categoryModeMapper(categoryMode.scope) : undefined
  });

  const loading = csrdLoading || ghgLoading;

  const datapoints = categoryMode?.type === 'csrd' ? csrdOutputData : ghgOutputData;
  const excludedDatapointNames = [
    'total_s1_emissions',
    'total_s2_emissions',
    'total_s3_emissions',
    'total_organization_emissions'
  ];
  const filteredDatapoints =
    (datapoints as Array<GHGOutputData | CSRDOutputData>)?.filter(
      (datapoint: GHGOutputData | CSRDOutputData) =>
        !excludedDatapointNames.includes(datapoint.name_dcycle)
    ) || [];
  const datapointBoxes: FrameworkDatapointBox[] = [
    {
      boxColor: '#fafafa',
      dotColor: '#a3aab2',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.pending'),
      total: filteredDatapoints.length || 0,
      datapoints: filteredDatapoints
    },
    {
      boxColor: '#f0f3fe',
      dotColor: '#6e86f7',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.inProgress'),
      total: 0,
      datapoints: []
    },
    {
      boxColor: '#ebfafb',
      dotColor: '#38cfd9',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.completed'),
      total: 0,
      datapoints: []
    }
  ];
  return (
    <div className='framework-dashboard__body'>
      {!loading && categoryMode ? (
        <>
          <CardPanel className='p-6 gap-4'>
            <div className='framework-scope__iconTitle flex gap-4 items-center'>
              <div
                className='icon-wrapper icon-bg-color shadow-purple-dark'
                style={{ height: '48px', width: '48px' }}>
                <Icon
                  icon={categoryMode.icon as IconName}
                  color='gradient'
                  size='large'
                  style={categoryMode.icon === 'own_facility' ? { width: '30px' } : undefined}
                />
              </div>
              <div className='framework-scope__titleNumber flex items-center gap-4 justify-between'>
                <h2 className='font-20 regular-font flex gap-2 items-center'>
                  {categoryMode.title}
                </h2>
              </div>
            </div>
            <DatapointListRenderer datapointBoxes={datapointBoxes} category={category} />
          </CardPanel>
        </>
      ) : (
        <LoaderCard style={{ gridColumn: '1 / -1' }} />
      )}
    </div>
  );
};

export default FrameworkDatapoints;
