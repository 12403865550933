import moment from 'moment';
import controller from '../../environments/controller';
import { FormEmployeeData } from '../../types/entities/employee';
import { FormEmployeePeriodData } from '../../types/entities/employeePeriod';
import { CommutingType } from '../../types/entitiesEnums/employeePeriod';
import apiFetch from '../../utils/apiFetch';

type EmployeePeriodPost = {
  employee_id: string;
  transport_type?: string;
  vehicle_size?: string;
  fuel_type?: string;
  renewable_energy?: string | null;
  weekly_travels?: number[];
  origin?: string;
  destination?: string;
  total_km?: number;
  start_date?: string;
  end_date?: string;
  telecommuting?: boolean;
  daily_trips?: number;
  situation: string;
  commuting_type: string;
  carpool?: boolean;
};

export const postEmployeePeriod = async (
  employeeId: string,
  formData: FormEmployeeData & FormEmployeePeriodData,
  commutingType: 'in_itinere' | 'in_labore',
  renewableEnergy: string,
  fuel: SelectOptionFormat,
  organization_id: string
) => {
  try {
    const data: EmployeePeriodPost = {
      employee_id: employeeId,
      weekly_travels: formData.weeklyTravels,
      daily_trips: Number(formData.numberWeeklyTravels),
      start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: formData.endDate
        ? moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      transport_type: formData.transport.id ? formData.transport.id : undefined,
      vehicle_size: formData.size ?? undefined,
      fuel_type: fuel.id ? fuel.id : undefined,
      renewable_energy: renewableEnergy ? renewableEnergy : undefined,
      situation: formData.situation.id,
      commuting_type: commutingType,
      total_km: formData.totalKm ? Number(formData.totalKm) : 0,
      carpool: formData.carpooling.id === 'yes' ? true : false
    };

    const response = await apiFetch('POST', `/employees/period`, data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

type EmployeePeriodPatch = {
  transport_type?: string;
  vehicle_size?: string;
  fuel_type?: string;
  renewable_energy?: string | null;
  weekly_travels?: number[];
  origin?: string;
  destination?: string;
  total_km?: number;
  start_date?: string;
  end_date?: string;
  telecommuting?: boolean;
  daily_trips?: number;
  situation: string;
  commuting_type: string;
  carpool?: boolean;
};

export const patchPeriod = async (
  employeeId: string,
  periodId: string,
  formData: FormEmployeePeriodData,
  commutingType: CommutingType,
  renewableEnergy: string,
  fuel: SelectOptionFormat,
  organization_id: string
) => {
  try {
    const data: EmployeePeriodPatch = {
      weekly_travels: formData.weeklyTravels,
      start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: formData.endDate
        ? moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      transport_type: formData.transport.id ? formData.transport.id : undefined,
      vehicle_size: formData.size ?? undefined,
      daily_trips: Number(formData.numberWeeklyTravels),
      fuel_type: fuel.id ? fuel.id : undefined,
      renewable_energy: renewableEnergy ? renewableEnergy : undefined,
      situation: formData.situation.id,
      total_km: formData.totalKm ? Number(formData.totalKm) : 0,
      commuting_type: commutingType,
      carpool: formData.carpooling.id === 'yes' ? true : false
    };

    const response = await apiFetch('PATCH', `/employees/${employeeId}/${periodId}`, data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const removePeriod = async (
  employeeId: string,
  periodId: string,
  organization_id: string
) => {
  try {
    const response = await apiFetch('DELETE', `/employees/${employeeId}/${periodId}`, null, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const environment = controller();

export const getEmployeeFormData = async (id: string) => {
  try {
    const response = await fetch(`${environment.backendUrl}/webhook/employees/form/${id}`, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
      }
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeFormDataQR = async (id: string) => {
  try {
    const response = await fetch(`${environment.backendUrl}/webhook/employees/form/qr/${id}`, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
      }
    });
    return response;
  } catch (error) {
    return error;
  }
};
