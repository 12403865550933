import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessTravelReport } from '../../../../types/entities/businessTravels';
import formatNumber from '../../../../utils/formatNumber';
import { limitString } from '../../../../utils/limitString';
import numberToDecimal from '../../../../utils/numberToDecimal';
import BarSection from '../../components/barSection/BarSection';
import BlueWrapper from '../../../ui/blueWrapper/BlueWrapper';
import InfoLabel from '../../components/infoLabel/InfoLabel';
import ReportTable from '../../components/reportTable/ReportTable';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';
import './styles.scss';

type Props = {
  businessTravels: BusinessTravelReport[];
};
function BusinessTrips({ businessTravels }: Props) {
  const { t } = useTranslation();
  let totalCo2 = 0;
  const BAR_SIZE = 5;
  const LIST_SIZE = 4;

  const businessTravelsGroupedByType: BusinessTravelReport[] = [];
  // Group by transport type
  businessTravels?.forEach((businessTravel) => {
    totalCo2 += businessTravel.co2e;

    const index = businessTravelsGroupedByType.findIndex(
      (item) => item.transport_type === businessTravel.transport_type
    );
    if (index === -1) {
      businessTravelsGroupedByType.push({ ...businessTravel });
    } else {
      businessTravelsGroupedByType[index].co2e += businessTravel.co2e;
    }
  });

  const getIcon = (type: string) => {
    const data = {
      car: {
        src: '/images/icons/car.svg',
        alt: 'car'
      },
      train: {
        src: '/images/icons/train.svg',
        alt: 'train'
      },
      aircraft: {
        src: '/images/icons/plane.svg',
        alt: 'plane'
      },
      motorbike: {
        src: '/images/icons/car.svg',
        alt: 'car'
      },
      remaining: {
        src: '/images/icons/people.svg',
        alt: 'remaining'
      }
    };

    type =
      type === 'metro' || type === 'train' || type === 'bus' || type === 'trolleybus'
        ? 'train'
        : type;

    return (
      data[type as keyof typeof data] ?? {
        src: '/images/icons/people.svg',
        alt: 'remaining'
      }
    );
  };

  const renderName = (businessTravel: BusinessTravelReport) => {
    if ((!businessTravel.origin || !businessTravel.destination) && businessTravel.distance_km)
      return t('report.travelWithDistance', { distance: businessTravel.distance_km });
    if (!businessTravel.origin || !businessTravel.destination) return '';
    return `${limitString(businessTravel.origin, 3, false)}-${limitString(
      businessTravel.destination,
      3,
      false
    )}`;
  };

  const parseData = (data: BusinessTravelReport[]) => {
    let returnData = data.map((value) => ({
      name: (
        <p className='name-logo body1-bold-font'>
          <img {...getIcon(value.transport_type)} /> {renderName(value)}
        </p>
      ),
      transport: <p className='body1-font'>{t(`report.${value.transport_type}`)}</p>,
      nTravels: <p className='body1-font'>{value.total_travel_number}</p>,
      co2: <p className='body1-font'>{formatNumber(value.co2e / 1000)}</p>,
      total: <BlueWrapper>{formatNumber(value.total_perc)}%</BlueWrapper>
    }));

    returnData = returnData.slice(0, LIST_SIZE);
    const restData = data.slice(LIST_SIZE, data.length);
    const nTravels = restData.reduce((acc, current) => acc + current.total_travel_number, 0);
    const co2 = restData.reduce((acc, current) => acc + current.co2e, 0);
    const total = restData.reduce((acc, current) => acc + current.total_perc, 0);

    returnData.push({
      name: (
        <span className='name-logo body1-bold-font'>
          <img {...getIcon('remaining')} />
          {t('report.remaining')}
        </span>
      ),
      transport: <p className='body1-font'> - </p>,
      nTravels: <p className='body1-font'>{nTravels}</p>,
      co2: <p className='body1-font'>{formatNumber(co2 / 1000)}</p>,
      total: <BlueWrapper>{formatNumber(total)}%</BlueWrapper>
    });

    return returnData;
  };

  const header = [
    {
      key: 'name',
      name: t('report.businessTravelsSection.name')
    },
    {
      key: 'transport',
      name: t('report.businessTravelsSection.transport')
    },
    {
      key: 'nTravels',
      name: t('report.businessTravelsSection.nTravels')
    },
    {
      key: 'co2',
      name: t('report.businessTravelsSection.co2')
    },
    {
      key: 'total',
      name: t('report.businessTravelsSection.total')
    }
  ];

  const tableData = parseData(businessTravels);

  let barData = businessTravelsGroupedByType.map((businessTravel) => ({
    id: businessTravel.transport_type,
    name: t(`report.${businessTravel.transport_type}`),
    value: businessTravel.co2e,
    total: totalCo2,
    image: getIcon(businessTravel.transport_type)
  }));

  const rest = barData.slice(BAR_SIZE - 1, barData.length);
  const remaining = rest.reduce((acc, current) => acc + current.value, 0);
  barData = barData.slice(0, BAR_SIZE - 1);

  barData.push({
    id: 'remaining',
    name: t('report.remaining'),
    value: numberToDecimal(remaining),
    total: totalCo2,
    image: getIcon('remaining')
  });

  const totalTravels = useMemo(() => {
    let total = 0;
    businessTravels.forEach((element) => (total += element.total_travel_number));
    return total;
  }, [businessTravels]);

  const co2Travel = useMemo(() => {
    let totalCo2e = 0;
    businessTravels.forEach((element) => (totalCo2e += element.co2e));
    return Number.isNaN(totalCo2e / totalTravels) ? 0 : totalCo2e / totalTravels;
  }, [businessTravels]);

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.businessTravels')}
          description={t('report.businessTravelsDescription')}
        />

        <CardTotalCo2 totalCo2={totalCo2} />
        <ReportTable data={tableData} header={header} />
      </div>
      <div className='card main-bg-color '>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='business-travel-bars-wrapper'>
          <BarSection data={barData} />
        </div>

        <div className='business-travel-bars-bottom-section total-info-cards'>
          <InfoLabel
            img={{
              src: '/images/icons/pin.svg',
              alt: 'localization'
            }}
            title={t('report.businessTravelsSection.totalTravels')}
            data={totalTravels}
          />
          <InfoLabel
            img={{
              src: '/images/icons/plane.svg',
              alt: 'travel'
            }}
            title={t('report.businessTravelsSection.kg/travel')}
            data={formatNumber(co2Travel)}
          />
        </div>
      </div>
    </div>
  );
}

export default BusinessTrips;
