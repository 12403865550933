import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadUser } from '../../actions/auth';
import { ROUTES } from '../../constants/routes';
import { authParseLoginCallback } from '../../services/authService';
import { Session } from '../../types/entities/user';
import { sessionStorageSetItem } from '../../utils/sessionStorage';
import LoaderLogin from '../ui/loaders/loaderLogin/LoaderLogin';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { useAmplitude } from 'customHooks/useAmplitude';
import { EVENTS } from 'constants/amplitude';

const Callback = () => {
  useAmplitude(EVENTS.PAGE_VIEW);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flags = useFeatureFlags();

  useEffect(() => {
    const parseSessionData = async () => {
      try {
        /**
         * Here the token gets loaded into the default amplify storage (cookies)
         * this is the moment to dispatch actions or watherver thing you require to happen
         * post logging
         */
        const sessionData: Session = await authParseLoginCallback();

        await dispatch(loadUser(sessionData, true, true, '', flags?.sotDcycleDemos));

        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          sessionStorageSetItem('showWelcomeModal', true);
          navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true });
        }
      } catch (error) {
        console.error(error);
        navigate(ROUTES.LOGIN, { replace: true });
      }
    };

    parseSessionData();
  }, []);

  return <LoaderLogin />;
};

export default Callback;
