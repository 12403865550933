import React from 'react';
import './styles.scss';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  descriptions: string[];
}
const DescriptionBox = ({ descriptions, ...rest }: Props) => {
  const parseText = (text: string) => {
    text = text.replace(/\n/g, '<br />');

    // Replace spaces if there are more than 1 with &nbsp;
    text = text.replace(/ {2,}/g, (match) => {
      return '&nbsp;'.repeat(match.length);
    });

    return text;
  };

  return (
    <div className='description-box icon-bg-color' {...rest}>
      <ul>
        {descriptions.map((elem) => (
          <li
            key={elem}
            className='font-10 weight-400'
            dangerouslySetInnerHTML={{ __html: parseText(elem) }}
            style={{ whiteSpace: 'pre-wrap' }}></li>
        ))}
      </ul>
    </div>
  );
};

export default DescriptionBox;
