import { useTranslation } from 'react-i18next';
import { METRICS_ARTICLE } from '../../../../constants';
import Button from '../../../ui/button/Button';
import HelpCTA from '../../../ui/helpCTA/HelpCTA';
import './styles.scss';
import SustainabilityRoadmap from '../../controlPanel/ControlPanel';
import { DashboardEsg } from '../../dashboardEsg/DashboardEsg';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import { useContext, useEffect } from 'react';
import { ESGModeContext } from '../../../../context/esgModeContext';
import { useLocation, useParams } from 'react-router-dom';
type Props = {
  children: React.ReactNode;
};
export const Layout = ({ children }: Props) => {
  const { t, i18n } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);

  const { mode, setMode } = useContext(ESGModeContext);

  const { frameworkName } = useParams();
  const { pathname } = useLocation();
  // If the pathname is /dashboard/total and some more, show the breadcrumb
  const showBreacrumb = pathname.includes('/dashboard/total/');

  useEffect(() => {
    const mode = queryParams.get('mode');

    if (mode) {
      setMode(mode);
    }
  }, [window.location.search]);

  const handleGoToMetrics = () => {
    window.open(METRICS_ARTICLE[i18n.resolvedLanguage as keyof typeof METRICS_ARTICLE], '_blank');
  };

  const renderChildren = () => {
    if (mode === 'controlPanel' && !frameworkName) {
      return <SustainabilityRoadmap />;
    }

    if (mode === 'socialAndGovernance') {
      return <DashboardEsg />;
    }

    return children;
  };

  return (
    <div className='dashboard-wrapper'>
      <section className='dashboard'>
        <div className='dashboard__header page-header'>
          <h3 className='headline3-font on-light-text-color'>{t('dashboard.title')}</h3>
          {mode === 'controlPanel' && showBreacrumb && (
            <div className='mt-4'>
              <Breadcrumb
                crumbsReplace={[{ key: 'dashboard/total', value: t('controlPanel.controlPanel') }]}
                excludeTranslationIndexes={[3]}
              />
            </div>
          )}
          {!showBreacrumb && (
            <h4 className='subtitle2-font on-light-text-color'>{t('dashboard.subtitle')}</h4>
          )}
        </div>
        <div className='dashboard-wrapper__mode flex justify-between items-center'>
          <div
            style={{
              marginLeft: 'auto'
            }}>
            <HelpCTA description={t('dashboard.help')}>
              <div className='flex'>
                <Button
                  lookAndFeel='primary'
                  text={t('dashboard.helpButton')}
                  size='tiny'
                  onClick={handleGoToMetrics}
                />
              </div>
            </HelpCTA>
          </div>
        </div>
        {renderChildren()}
      </section>
    </div>
  );
};
