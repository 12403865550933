import { getUrl } from 'utils/url';
import './styles.scss';

type Props = {
  url: string;
  options?: {
    hide_owner?: boolean;
    hide_share?: boolean;
    hide_title?: boolean;
    hideEmbedTopBar?: boolean;
  };
};

const DEFAULT_OPTIONS = {
  hide_owner: true,
  hide_title: true,
  hideEmbedTopBar: true
} as const;

const LoomVideo = ({ url, options }: Props) => {
  const queryParams: QueryParams[] = [];
  const optionsWithDefault = Object.assign({}, DEFAULT_OPTIONS, options);

  Object.entries(optionsWithDefault).forEach(([key, value]) => {
    if (value) {
      queryParams.push({ key, value });
    }
  });

  const finalUrl = getUrl(url, { queryParams });

  return (
    <div className='video-player'>
      <iframe src={finalUrl} title='Loom video player' allowFullScreen></iframe>
    </div>
  );
};

export default LoomVideo;
