import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDataVehicles, VehicleFuel } from '../../../../types/entities/vehicle';
import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import useGetDataForm from '../hooks/useGetDataForm';
import FormCharacteristics from './FormCharacteristics';
import './styles.scss';

/* enum VehicleSteps {
  CHARACTERISTICS = 'characteristics',
  PROPERTIES = 'properties'
} */

type Props = {
  onSubmit: (formData: FormDataVehicles, unknownVehicles: any[], fuels: VehicleFuel[]) => void;
  formDataFilled?: FormDataVehicles;
  title: string;
  description: string;
  errorRequest?: ErrorType;
  edit?: boolean;
};
const formDataEmpty: FormDataVehicles = {
  type: '',
  unknownType: {
    id: '',
    name: ''
  },
  name: '',
  carRegistration: '',
  fuel: {
    id: '',
    name: ''
  },
  vehicleType: {
    id: '',
    name: ''
  },
  country: {
    id: '',
    name: ''
  },
  emissionFactor: '',
  errors: []
};
const FormVehicle = ({
  onSubmit,
  formDataFilled,
  title,
  description,
  errorRequest,
  edit
}: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(formDataFilled || formDataEmpty);

  const [loadingButton, setLoadingButton] = useState(false);

  const { unknownVehicles, fuels, groups } = useGetDataForm({
    country: formData.country.id,
    unknownType: formData.unknownType.id
  });

  useEffect(() => {
    if (errorRequest?.error === 'vehicleFuel') {
      setFormData((prev) => ({
        ...prev,
        errors: [
          ...prev.errors,
          {
            error: 'fuel',
            description: errorRequest.description
          }
        ]
      }));
    }
    if (errorRequest?.error === 'emissionFactor') {
      setFormData((prev) => ({
        ...prev,
        errors: [
          ...prev.errors,
          {
            error: 'emissionFactor',
            description: errorRequest.description
          }
        ]
      }));
    }
  }, [JSON.stringify(errorRequest)]);

  const handleErrors = () => {
    const optionalFields = ['name'];

    if (formData.emissionFactor) {
      optionalFields.push('fuel');
    } else {
      optionalFields.push('emissionFactor');
    }
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length > 0 && formData) {
      setFormData({
        ...formData,
        errors: newErrors
      });
    }
    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleSubmit = async () => {
    if (loadingButton || handleErrors()) return;
    setLoadingButton(true);
    onSubmit(formData, unknownVehicles, fuels ?? []);
    setLoadingButton(false);
  };

  return (
    <div className='form-vehicle'>
      <FormHeader title={title} description={description} />
      <FormCharacteristics
        formData={formData}
        setFormData={setFormData}
        unknownVehicles={unknownVehicles}
        fuels={fuels ?? []}
        groups={groups}
        edit={edit}
      />

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('vehicles.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {errorRequest && errorRequest.error === 'general' && (
        <span className='error-text-color body2-font'>{errorRequest.description}</span>
      )}
    </div>
  );
};

export default FormVehicle;
