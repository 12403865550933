import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/userContext';
import { getVehicle } from '../../../services/api/vehicle';

type Props = {
  id: string;
};
const useGetUnits = ({ id }: Props) => {
  const user = useContext(UserContext);

  const hardCodedUnits: Unit[] = [
    {
      id: '54a709bf-79fe-4acb-ab85-5e1d7cd26eb4',
      name: 'litre_(l)',
      type: 'liquid'
    },
    {
      id: 'accd2a4d-ff0c-4e7c-8e17-a187b01e49b4',
      name: 'kilometer_(km)',
      type: 'gas'
    }
  ];

  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    if (!user?.selectedOrganization) return;
    setLoading(true);
    const response = await getVehicle(id);
    console.log('response', response);
    if (response?.response?.status >= 400 || !response?.vehicle_fuel) {
      setLoading(false);
      setUnits(hardCodedUnits);
      return;
    }
    setUnits(response.vehicle_fuel.units);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  console.log('units', units);

  return { units, loading };
};

export default useGetUnits;
