import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { User } from '../../../../../types/entities/user';
import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import Modal from '../../../../ui/modal/Modal';

type Props = {
  deleteShipment: (id: string) => void;
  shipmentToDelete: string;
  user: User | null;
};
function DeleteShipment({ user, shipmentToDelete, deleteShipment }: Props) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction') || 'downstream';

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteShipment();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteShipment = async () => {
    if (user) {
      try {
        setLoadingButton(true);
        await apiFetch(
          'delete',
          `/transport_routes/${shipmentToDelete}`,
          {},
          { 'x-organization-id': user.selectedOrganization }
        );
        deleteShipment(shipmentToDelete);
        setLoadingButton(false);
      } catch (err) {
        console.error(err);
        setLoadingButton(false);
      }
    }
  };

  return (
    <div className='delete-modal'>
      <Modal.Header
        title={t(`shipments.${transportDirection}.deleteShipment`)}
        description={t(`shipments.${transportDirection}.areYouSureDelete`)}
      />
      <Modal.Buttons>
        <Button
          lookAndFeel='warning'
          text={t('shipments.delete')}
          onClick={handleDeleteShipment}
          loading={loadingButton}
        />
      </Modal.Buttons>
    </div>
  );
}

export default DeleteShipment;
