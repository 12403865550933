import { useTranslation } from 'react-i18next';
import { highlight_color, highlight_color2 } from '../../../../styles/colors';
import { FacilityReport } from '../../../../types/entities/facility';
import { Organization } from '../../../../types/entities/organization';
import { limitString } from '../../../../utils/limitString';
import numberToDecimal, { numberToScientificNotation } from '../../../../utils/numberToDecimal';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import HorizontalBar from '../common/horizontalBar/HorizontalBar';
import List from '../common/list/List';
import './styles.scss';
import Dot from '../../../ui/dot/Dot';

type CategoryCo2 = {
  category: string;
  total_emissions: number;
  ghg_gas: string;
};
type GroupedFacility = {
  id: string;
  name: string;
  total_emissions: number;
  ghg_gas: string;
  categories: CategoryCo2[];
};
type Props = {
  facilities: FacilityReport[];
  organizationCo2e: number;
  organization: Organization;
  categories: string[];
};

const FROM_KG_TO_TON = 1000;
const FROM_G_TO_TON = 1_000_000;

function StationaryEmissions({ facilities, organizationCo2e, organization, categories }: Props) {
  const { t } = useTranslation();
  let totalCo2e = 0;
  let totalCo2 = 0;
  let totalCH4 = 0;
  let totalN2O = 0;
  let totalCo2eCH4 = 0;
  let totalCo2eN20 = 0;
  let totalCo2Biomass = 0;

  const fugitiveGases: string[] = [];

  // filter facilities only by those that are of category 'stationary' and category 'recharge'
  const filteredFacilities = facilities.filter((facility) => {
    const conditions = [];
    if (categories.includes('stationary')) {
      conditions.push(facility.category === 'stationary');
    }
    if (categories.includes('recharge')) {
      conditions.push(facility.category === 'recharge');
    }
    if (categories.includes('waste_water_treatments')) {
      conditions.push(facility.category === 'waste_water_treatments');
    }
    return conditions.some((condition) => condition);
  });

  // group facilities of same id and add a list of co2e values per cateogry
  const groupedFacilities: GroupedFacility[] = [];
  filteredFacilities.forEach((facility) => {
    if (facility.ghg_gas === 'co2e') {
      totalCo2e += facility.total_emissions;
    } else if (facility.ghg_gas === 'co2') {
      totalCo2 += facility.total_emissions;
    } else if (facility.ghg_gas === 'ch4') {
      totalCH4 += facility.total_emissions;
    } else if (facility.ghg_gas === 'n2o') {
      totalN2O += facility.total_emissions;
    } else if (facility.ghg_gas === 'co2e_ch4') {
      totalCo2eCH4 += facility.total_emissions;
    } else if (facility.ghg_gas === 'co2e_n2o') {
      totalCo2eN20 += facility.total_emissions;
    } else if (facility.ghg_gas === 'co2_biomass') {
      totalCo2Biomass += facility.total_emissions;
    }

    // Get fugitive gases
    if (facility.category === 'recharge' && facility.ghg_gas === 'co2e' && facility.fuel_name) {
      fugitiveGases.push(facility.fuel_name);
    }
    const index = groupedFacilities.findIndex((elem) => elem.id === facility.id);
    if (index === -1) {
      groupedFacilities.push({
        id: facility.id,
        name: facility.name,
        total_emissions: facility.ghg_gas === 'co2e' ? facility.total_emissions : 0,
        ghg_gas: 'co2e',
        categories: [
          {
            category: facility.category,
            total_emissions: facility.total_emissions,
            ghg_gas: facility.ghg_gas
          }
        ]
      });
    } else {
      if (facility.ghg_gas === 'co2e') {
        groupedFacilities[index].total_emissions += facility.total_emissions;
      }

      const foundCategory = groupedFacilities[index].categories.findIndex(
        (elem) => elem.category === facility.category && elem.ghg_gas === facility.ghg_gas
      );

      if (foundCategory === -1) {
        groupedFacilities[index].categories.push({
          category: facility.category,
          total_emissions: facility.total_emissions,
          ghg_gas: facility.ghg_gas
        });
      } else {
        groupedFacilities[index].categories[foundCategory].total_emissions +=
          facility.total_emissions;
      }
    }
  });

  groupedFacilities.sort((a, b) => b.total_emissions - a.total_emissions);

  const groupedFacilitiesCo2e = groupedFacilities.filter((facility) => {
    return facility.ghg_gas === 'co2e';
  });

  const calculateRemaining = (category: string, ghg_gas: string) => {
    let remaining = 0;
    groupedFacilitiesCo2e.slice(3).forEach((elem) => {
      const categoryCo2 = elem.categories.find(
        (categoryCo2) => categoryCo2.category === category && categoryCo2.ghg_gas === ghg_gas
      );
      if (categoryCo2) {
        remaining += categoryCo2.total_emissions;
      }
    });
    return remaining;
  };

  const remainingStationaryCo2e = calculateRemaining('stationary', 'co2e');
  const remainingFugitiveCo2e = calculateRemaining('recharge', 'co2e');
  const remainingTreatmentCo2e = calculateRemaining('waste_water_treatments', 'co2e');
  const remainingStationaryCo2 = calculateRemaining('stationary', 'co2');
  const remainingFugitiveCo2 = calculateRemaining('recharge', 'co2');
  const remainingTreatmentCo2 = calculateRemaining('waste_water_treatments', 'co2');
  const remainingStationaryCH4 = calculateRemaining('stationary', 'ch4');
  const remainingFugitiveCH4 = calculateRemaining('recharge', 'ch4');
  const remainingTreatmentCH4 = calculateRemaining('waste_water_treatments', 'ch4');
  const remainingStationaryN2O = calculateRemaining('stationary', 'n2o');
  const remainingFugitiveN2O = calculateRemaining('recharge', 'n2o');
  const remainingTreatmentN2O = calculateRemaining('waste_water_treatments', 'n2o');
  const remainingStationaryCo2eCh4 = calculateRemaining('stationary', 'co2e_ch4');
  const remainingFugitiveCo2eCh4 = calculateRemaining('recharge', 'co2e_ch4');
  const remainingTreatmentCo2eCh4 = calculateRemaining('waste_water_treatments', 'co2e_ch4');
  const remainingStationaryCo2eN2o = calculateRemaining('stationary', 'co2e_n2o');
  const remainingFugitiveCo2eN2o = calculateRemaining('recharge', 'co2e_n2o');
  const remainingTreatmentCo2eN2o = calculateRemaining('waste_water_treatments', 'co2e_n2o');
  const remainingStationaryCo2Biomass = calculateRemaining('stationary', 'co2_biomass');
  const remainingFugitiveCo2Biomass = calculateRemaining('recharge', 'co2_biomass');
  const remainingTreatmentCo2Biomass = calculateRemaining('waste_water_treatments', 'co2_biomass');
  const columns2 = [
    t('ghgReport.total'),
    t('ghgReport.type'),
    t('ghgReport.n2o(tns)'),
    t('ghgReport.ch4(tns)'),
    t('ghgReport.co2(tns)'),
    t('ghgReport.co2Biomass(tns)'),
    t('ghgReport.co2eN2o(tns)'),
    t('ghgReport.co2eCh4(tns)'),
    t('ghgReport.co2eq.(tns)')
  ];

  const rows: string[][] = [];
  let totalStationaryCo2e = remainingStationaryCo2e;
  let totalFugitiveCo2e = remainingFugitiveCo2e;
  groupedFacilities.slice(0, 3).forEach((facility: GroupedFacility) => {
    const stationaryCo2 = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'co2'
    )?.total_emissions;
    const fugitiveCo2 = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'co2'
    )?.total_emissions;
    const treatmentCo2 = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'co2'
    )?.total_emissions;
    const stationaryCH4 = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'ch4'
    )?.total_emissions;
    const fugitiveCH4 = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'ch4'
    )?.total_emissions;
    const treatmentCH4 = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'ch4'
    )?.total_emissions;
    const stationaryN2O = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'n2o'
    )?.total_emissions;
    const fugitiveN2O = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'n2o'
    )?.total_emissions;
    const treatmentN2O = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'n2o'
    )?.total_emissions;
    const stationaryCo2eCh4 = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'co2e_ch4'
    )?.total_emissions;
    const fugitiveCo2eCh4 = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'co2e_ch4'
    )?.total_emissions;
    const treatmentCo2eCh4 = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'co2e_ch4'
    )?.total_emissions;
    const stationaryCo2eN2o = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'co2e_n2o'
    )?.total_emissions;
    const fugitiveCo2eN2o = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'co2e_n2o'
    )?.total_emissions;
    const treatmentCo2eN2o = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'co2e_n2o'
    )?.total_emissions;
    const stationaryCo2Biomass = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'co2_biomass'
    )?.total_emissions;
    const fugitiveCo2Biomass = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'co2_biomass'
    )?.total_emissions;
    const treatmentCo2Biomass = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'co2_biomass'
    )?.total_emissions;
    const stationaryCo2e = facility.categories.find(
      (elem) => elem.category === 'stationary' && elem.ghg_gas === 'co2e'
    )?.total_emissions;
    totalStationaryCo2e += stationaryCo2e ?? 0;
    const fugitiveCo2e = facility.categories.find(
      (elem) => elem.category === 'recharge' && elem.ghg_gas === 'co2e'
    )?.total_emissions;
    totalFugitiveCo2e += fugitiveCo2e ?? 0;
    const treatmentCo2e = facility.categories.find(
      (elem) => elem.category === 'waste_water_treatments' && elem.ghg_gas === 'co2e'
    )?.total_emissions;
    const row = [
      limitString(facility.name, 60),
      t('ghgReport.stationary'),
      numberToScientificNotation((stationaryN2O || 0) / FROM_G_TO_TON),
      numberToScientificNotation((stationaryCH4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((stationaryCo2 || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((stationaryCo2Biomass || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((stationaryCo2eN2o || 0) / FROM_G_TO_TON),
      numberToScientificNotation((stationaryCo2eCh4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((stationaryCo2e || 0) / FROM_KG_TO_TON)
    ];

    rows.push(row);

    const row2 = [
      limitString(facility.name, 60),
      t('ghgReport.fugitive'),

      numberToScientificNotation((fugitiveN2O || 0) / FROM_G_TO_TON),
      numberToScientificNotation((fugitiveCH4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((fugitiveCo2 || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((fugitiveCo2Biomass || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((fugitiveCo2eN2o || 0) / FROM_G_TO_TON),
      numberToScientificNotation((fugitiveCo2eCh4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((fugitiveCo2e || 0) / FROM_KG_TO_TON)
    ];

    rows.push(row2);
    if (categories.includes('waste_water_treatments')) {
      const tratamientoRow = [
        limitString(facility.name, 60),
        t('ghgReport.wasteWaterTreatment'),

        numberToScientificNotation((treatmentN2O || 0) / FROM_G_TO_TON),
        numberToScientificNotation((treatmentCH4 || 0) / FROM_G_TO_TON),
        numberToScientificNotation((treatmentCo2 || 0) / FROM_KG_TO_TON),
        numberToScientificNotation((treatmentCo2Biomass || 0) / FROM_KG_TO_TON),
        numberToScientificNotation((treatmentCo2eN2o || 0) / FROM_G_TO_TON),
        numberToScientificNotation((treatmentCo2eCh4 || 0) / FROM_G_TO_TON),
        numberToScientificNotation((treatmentCo2e || 0) / FROM_KG_TO_TON)
      ];

      rows.push(tratamientoRow);
    }
  });

  // Add remaining to rows
  rows.push([
    t('ghgReport.remaining'),
    t('ghgReport.stationary'),
    numberToScientificNotation(remainingStationaryN2O / FROM_G_TO_TON),
    numberToScientificNotation((remainingStationaryCH4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingStationaryCo2 || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingStationaryCo2Biomass || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingStationaryCo2eN2o || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingStationaryCo2eCh4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingStationaryCo2e || 0) / FROM_KG_TO_TON)
  ]);

  rows.push([
    t('ghgReport.remaining'),
    t('ghgReport.fugitive'),
    numberToScientificNotation(remainingFugitiveN2O / FROM_G_TO_TON),
    numberToScientificNotation((remainingFugitiveCH4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingFugitiveCo2 || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingFugitiveCo2Biomass || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingFugitiveCo2eN2o || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingFugitiveCo2eCh4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingFugitiveCo2e || 0) / FROM_KG_TO_TON)
  ]);
  if (categories.includes('waste_water_treatments')) {
    rows.push([
      t('ghgReport.remaining'),
      t('ghgReport.wasteWaterTreatment'),
      numberToScientificNotation(remainingTreatmentN2O / FROM_G_TO_TON),
      numberToScientificNotation((remainingTreatmentCH4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((remainingTreatmentCo2 || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((remainingTreatmentCo2Biomass || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((remainingTreatmentCo2eN2o || 0) / FROM_G_TO_TON),
      numberToScientificNotation((remainingTreatmentCo2eCh4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((remainingTreatmentCo2e || 0) / FROM_KG_TO_TON)
    ]);
  }

  return (
    <>
      <div className='stationary-emissions__header'>
        <div className='legend'>
          <Dot type='gradient' />
          <span className='body2-font'>{t('ghgReport.stationary')}</span>
        </div>
        <div className='legend'>
          <Dot type='gray' />
          <span className='body2-font'>{t('ghgReport.fugitive')}</span>
        </div>
        <span className='body2-font'>t CO₂ eq.</span>
      </div>
      <div className='stationary-emissions__bar-chart on-card-gray-bg-color'>
        {groupedFacilitiesCo2e.slice(0, 3).map((facility, index) => (
          <div className='stationary-emissions__bar-chart__item' key={index}>
            <span className='body2-font facility-name'>{`${facility.name.substring(0, 20)}${
              facility.name.length > 20 ? '...' : ''
            }`}</span>
            <HorizontalBar
              total={totalCo2e}
              values={facility.categories
                .filter((elem) => elem.ghg_gas === 'co2e')
                .sort((a) => (a.category === 'stationary' ? -1 : 1))
                .map((elem) => ({
                  value: elem.total_emissions,
                  color:
                    elem.category === 'stationary'
                      ? `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                      : 'rgba(209, 212, 216, 0.5)'
                }))}
            />
            <span className='body2-font facility-total-co2'>
              {numberToDecimal(facility.total_emissions / FROM_KG_TO_TON)}
            </span>
          </div>
        ))}
        {remainingStationaryCo2e > 0 ||
          (remainingFugitiveCo2e > 0 && (
            <div className='stationary-emissions__bar-chart__item' key='remaining'>
              <span className='body2-font facility-name'>{t('ghgReport.remaining')}</span>
              <HorizontalBar
                total={totalCo2e}
                values={[
                  {
                    value: numberToDecimal(remainingStationaryCo2e),
                    color: `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                  },
                  {
                    value: numberToDecimal(remainingFugitiveCo2e),
                    color: 'rgba(209, 212, 216, 0.5)'
                  }
                ]}
              />
              <span className='body2-font facility-total-co2'>
                {numberToDecimal(
                  (remainingStationaryCo2e + remainingFugitiveCo2e) / FROM_KG_TO_TON
                )}
              </span>
            </div>
          ))}
      </div>
      {/* <ReportTable columns={columns} data={rows} /> */}
      <div className='smaller'>
        <List
          icon={'/images/icons/building.svg'}
          columns={columns2}
          rows={rows}
          totalRow={[
            '',
            numberToScientificNotation(totalN2O / FROM_G_TO_TON),
            numberToScientificNotation(totalCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2 / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2Biomass / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2eN20 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2eCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2e / FROM_KG_TO_TON)
          ]}
        />
      </div>
      <div
        className='flex-col gap-1'
        style={{
          marginTop: 'auto'
        }}>
        <DescriptionBox
          descriptions={[
            t('ghgReport.stationaryEmissionsDescription.total', {
              total: `${numberToDecimal(totalStationaryCo2e / FROM_KG_TO_TON)}`,
              percentage: `${numberToDecimal((totalStationaryCo2e / organizationCo2e) * 100)}`,
              tradeName: organization.trade_name ?? ''
            }),
            totalFugitiveCo2e > 0
              ? t('ghgReport.stationaryEmissionsDescription.fugitive', {
                  percentage: `${numberToDecimal((totalFugitiveCo2e / organizationCo2e) * 100)}`,
                  gasName: fugitiveGases.map((gas) => t(`facility_fuels.${gas}`)).join(', ')
                })
              : t('ghgReport.stationaryEmissionsDescription.noFugitive'),
            t('ghgReport.stationaryEmissionsDescription.dirtiestFacility', {
              facilityName:
                groupedFacilities.length > 0 && groupedFacilitiesCo2e[0]
                  ? groupedFacilitiesCo2e[0].name
                  : ''
            })
          ]}
        />
        <span className='body1-font on-light-text-color2'>{t('ghgReport.foot.info1')}</span>
        <span className='body1-font on-light-text-color2'>{t('ghgReport.foot.info2')}</span>
      </div>
      <div
        style={{
          marginTop: 'auto'
        }}></div>
      <div
        style={{
          marginTop: 'auto'
        }}></div>
    </>
  );
}

export default StationaryEmissions;
