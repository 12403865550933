import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes from '../../../../constants/phonePrefixes';
import { UserContext } from '../../../../context/userContext';
import { updateVehicle } from '../../../../services/api/vehicle';
import { FormDataVehicles, Vehicle, VehicleFuel } from '../../../../types/entities/vehicle';
import FormVehicle from '../formVehicle/FormVehicle';
import useCards from '../hooks/useCards';
import useCountries from '../hooks/useCountries';

type Props = {
  vehicleToEdit: Vehicle;
  editVehicle?: (value: Vehicle, id: string) => void;
};

const EditVehicle = ({ vehicleToEdit, editVehicle }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);

  const cards = useCards();

  const countriesOptions = useCountries();
  const [error, setError] = useState<ErrorType>();

  const handleEditVehicle = async (
    formData: FormDataVehicles,
    unknownVehicles: any,
    fuels: VehicleFuel[]
  ) => {
    setError(undefined);
    const unknownVehicleId = unknownVehicles.find(
      (vehicle: { type: string }) => vehicle.type === formData.unknownType.id
    )?.id;
    const vehicleFuelId = formData.fuel.id || null;

    let nameValue = formData.name;
    if (!nameValue) {
      nameValue = formData.unknownType.id;
    }

    const vehicleModified = {
      unknown_vehicle_id: unknownVehicleId ?? undefined,
      ownership: formData.type,
      vehicle_fuel_id: vehicleFuelId ?? undefined,
      is_known: false,
      type: formData.vehicleType.id ? formData.vehicleType.id : vehicleToEdit.type || '',
      name: nameValue,
      license_plate: formData.carRegistration || '',
      country: formData.country.id || vehicleToEdit.country,
      organization_id: user?.selectedOrganization ?? '',
      custom_emission_factor_id: formData.emissionFactor ?? vehicleToEdit.custom_emission_factor_id
    };

    const newVehicle = await updateVehicle(vehicleToEdit.id, vehicleModified);

    if (typeof newVehicle === 'string' && newVehicle === 'ERR_CANNOT_RECALCULATE_CONSUMPTIONS') {
      if (vehicleModified.custom_emission_factor_id) {
        setError({
          error: 'emissionFactor',
          description: t('error.cannotRecalculateConsumptions')
        });
        return;
      } else {
        setError({
          error: 'vehicleFuel',
          description: t('error.cannotRecalculateConsumptions')
        });
        return;
      }
    }
    if (typeof newVehicle === 'string' && newVehicle.includes('Vehicle with license plate')) {
      setError({
        error: 'carRegistration',
        description: t('error.licensePlateAlreadyExists')
      });
      return;
    }
    if (editVehicle && newVehicle) {
      const foundFuel = fuels?.find((fuel) => fuel.id === newVehicle.vehicle_fuel_id);
      if (foundFuel) {
        newVehicle.vehicle_fuel_id = foundFuel?.id;
      }
      editVehicle(
        {
          ...newVehicle,
          unknown_vehicle_type: formData.unknownType.id,
          fuel: foundFuel?.fuel,
          vehicle_fuel: foundFuel?.fuel
        },
        vehicleToEdit.id
      );
    }
  };

  let countryFound = { id: '', name: '' };
  if (vehicleToEdit?.country) {
    const foundValue = getPrefixes(i18n.resolvedLanguage).find(
      (elem) => elem.code === vehicleToEdit.country
    );
    if (foundValue) {
      countryFound = { id: foundValue.code, name: foundValue.name };
    }
  } else {
    countryFound =
      countriesOptions && countriesOptions.length > 0 ? countriesOptions[0] : { id: '', name: '' };
  }

  return (
    <>
      <FormVehicle
        onSubmit={handleEditVehicle}
        title={t('vehicles.editVehicle')}
        description={t('vehicles.editVehicleDescription')}
        formDataFilled={{
          type: cards.find((card) => card.id === vehicleToEdit.ownership)?.id ?? cards[0].id,
          unknownType: vehicleToEdit?.unknown_vehicle_type
            ? {
                id: vehicleToEdit.unknown_vehicle_type,
                name: t(`vehicles.${vehicleToEdit.unknown_vehicle_type}`)
              }
            : { id: '', name: '' },
          name:
            vehicleToEdit?.name && !i18n.exists(`vehicles.${vehicleToEdit.name}`)
              ? vehicleToEdit?.name
              : '',
          carRegistration: vehicleToEdit?.license_plate || '',
          fuel:
            vehicleToEdit?.vehicle_fuel && vehicleToEdit.vehicle_fuel_id
              ? {
                  id: vehicleToEdit.vehicle_fuel_id,
                  name: t(`vehicle_fuels.${vehicleToEdit.vehicle_fuel}`)
                }
              : { id: '', name: '' },
          vehicleType: vehicleToEdit?.type
            ? { id: vehicleToEdit.type, name: t(`vehicles.${vehicleToEdit.type}`) }
            : { id: '', name: '' },
          country: countryFound,
          emissionFactor: vehicleToEdit.custom_emission_factor_id || '',
          errors: []
        }}
        errorRequest={error}
        edit
      />
    </>
  );
};

export default EditVehicle;
