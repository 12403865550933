import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../../../../../customHooks/useSelectedOrganization';
import { getMetricsEnhanced } from '../../../../../../../../services/api/esg';
import { SIZE_PAGINATION_SMALL } from '../../../../../../../../constants';
import { EsgMetricsEnhanced } from '../../../../../../../../types/entities/esgMetrics';
import { editElement } from '../../../../../../../../lib/ReactQuery/utils';

type Params = {
  filters: IFilter[];
  sorters: ISorter[];
  mode?: string;
  startDate: string;
  endDate: string;
};

export const useGetDatapoints = ({ filters, sorters, mode, startDate, endDate }: Params) => {
  const org_id = useSelectedOrganization()?.id;
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      'metrics',
      org_id,
      JSON.stringify(filters),
      JSON.stringify(sorters),
      mode,
      startDate,
      endDate
    ],
    queryFn: (page) => getMetricsEnhanced(startDate, endDate, filters, sorters, page.pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * SIZE_PAGINATION_SMALL >= lastPage.total || lastPage === undefined) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1
  });

  const dataParsed = data?.pages.reduce(
    (acc, page) => {
      return {
        page: page.page,
        total: page.total,
        total2: page.total2,
        items: [...acc.items, ...page.items]
      };
    },
    {
      page: 1,
      total: 0,
      total2: 0,
      items: []
    }
  );

  const editMetric = (newValue: EsgMetricsEnhanced, key: keyof EsgMetricsEnhanced = 'name') => {
    queryClient.setQueryData(
      [
        'metrics',
        org_id,
        JSON.stringify(filters),
        JSON.stringify(sorters),
        mode,
        startDate,
        endDate
      ],
      editElement(newValue, key)
    );
  };

  return {
    data: dataParsed,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    editElement: editMetric
  };
};
