import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Mode } from '../../../../types/entitiesEnums/esgMetrics';
import { getCurrentYear, getPreviousYear } from '../utils/utils';
import { useGetEsgMetrics } from './useGetEsgMetrics';

type Params = {
  enabled?: boolean;
};

const useIntensityMetrics = ({ enabled }: Params = {}) => {
  const { t } = useTranslation();
  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const [selects, setSelects] = useState({
    impactPerMetricIntensityMetric: { id: '', name: '' },
    absoluteOrIntensity: { id: Mode.ABSOLUT, name: t('dashboard.absolute') }
  });

  const { impactPerMetricIntensityMetric, absoluteOrIntensity } = selects;

  const onChangeValue = function (attribute: keyof typeof selects) {
    return function changeValue(value: SelectOptionFormat<string>) {
      setSelects((prev) => ({
        ...prev,
        [attribute]: value
      }));
    };
  };

  const { data: metricsData, isPending: isLoadingMetrics } = useGetEsgMetrics({
    intensityMetric:
      impactPerMetricIntensityMetric.id !== '' ? impactPerMetricIntensityMetric.id : null,
    dateRanges: [
      [`${previousYear}/01/01`, `${previousYear}/12/31`],
      [`${currentYear}/01/01`, `${currentYear}/12/31`]
    ],
    mode: absoluteOrIntensity.id,
    enabled
  });

  return {
    metricsData,
    isLoadingMetrics,
    impactPerMetricIntensityMetric,
    absoluteOrIntensity,
    setAbsoluteOrIntensity: onChangeValue('absoluteOrIntensity'),
    setImpactPerMetricIntensityMetric: onChangeValue('impactPerMetricIntensityMetric')
  };
};

export default useIntensityMetrics;
