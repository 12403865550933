import {
  error_color,
  gradient_color,
  gray_color,
  scope1_color,
  scope2_color,
  scope3_color,
  gray_dark_color
} from '../../../styles/colors';
import './styles.scss';

type Props = {
  type: 'scope1' | 'scope2' | 'scope3' | 'gradient' | 'gray' | 'error' | 'custom' | 'text';
  color?: string;
  style?: React.CSSProperties;
};

function Dot({ type, color, style }: Props) {
  const colors = {
    scope1: scope1_color,
    scope2: scope2_color,
    scope3: scope3_color,
    error: error_color,
    gradient: gradient_color,
    gray: gray_color,
    text: gray_dark_color,
    custom: color ?? ''
  };

  return <div className='dot' style={{ ...style, background: colors[type] }}></div>;
}

export default Dot;
