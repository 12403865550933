import moment from 'moment';
import { SIZE_PAGINATION_SMALL } from '../../constants';
import type { EsgMetricsEnhanced } from 'types/entities/esgMetrics';
import type { Mode } from 'types/entitiesEnums/esgMetrics';
import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { uploadFilePresignedUrl } from './aws';

type IGetESGPresignedUrl = {
  fileName: string;
  folder: 'double_materiality' | 'environmental' | 'general_information' | 'social' | 'governance';
};

export const getPresignedUrlEsg = async ({ fileName, folder }: IGetESGPresignedUrl) => {
  try {
    const response = await apiFetch('POST', '/esg/upload', {
      file_name: fileName,
      folder
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

type IGetESGExcel = {
  dateRanges: string[][];
  mode: Mode;
  intensitymetricGroupId: string;
  lang: string;
};

export const downloadEsgExcel = async ({
  dateRanges,
  mode,
  intensitymetricGroupId,
  lang
}: IGetESGExcel): Promise<string> => {
  const response = await apiFetch('POST', '/esg/excel', {
    date_ranges: dateRanges,
    mode,
    intensitymetric_group_id: intensitymetricGroupId,
    lang
  });

  return response.data;
};

export const getOrganizationESGStatus = async (
  start_date: string,
  end_date: string,
  filters: IFilter[],
  returnRequest = false
) => {
  try {
    const url = getUrl('/esg/organization_esg_completeness', {
      filters
    });

    const request = apiFetch('POST', url, {
      start_date,
      end_date
    });

    if (returnRequest) {
      return request;
    }

    const response = await request;

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getOrganizationFrameworkStatus = async (
  start_date: string,
  end_date: string,
  returnRequest = false
) => {
  try {
    const params: {
      [key: string]: string;
    } = {
      start_date,
      end_date
    };

    const url = getUrl('/esg/framework_progress');
    const request = apiFetch('GET', url, {}, {}, params);
    if (returnRequest) {
      return request;
    }

    const response = await request;

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMetricsEnhanced = async (
  start_date: string,
  end_date: string,
  filters: IFilter[],
  sorters?: ISorter[],
  page?: number
) => {
  const startDateParsed = moment(start_date, 'DD/MM/YYYY').format('YYYY/MM/DD');
  const endDateParsed = moment(end_date, 'DD/MM/YYYY').format('YYYY/MM/DD');
  const body: {
    [key: string]: string | boolean | string[][];
  } = {
    date_ranges: [[startDateParsed, endDateParsed]]
  };

  const response = await apiFetch(
    'POST',
    getUrl('/esg/metrics_enhanced', {
      queryParams: generateQueryParamsFromObject({
        page,
        size: SIZE_PAGINATION_SMALL
      }),
      filters,
      sorters
    }),
    body
  );

  const frameworks: string[] = [];

  const data = response.data.items as EsgMetricsEnhanced[] | BackendDatapoint[];

  data.forEach((item) => {
    item.frameworks.forEach((framework) => {
      if (!frameworks.includes(framework)) {
        frameworks.push(framework);
      }
    });
  });

  return {
    items: response.data.items as EsgMetricsEnhanced[],
    total: response.data.total,
    total2: frameworks.length,
    page: response.data.page
  };
};

export const getOrganizationDatapoints = async (
  start_date: string,
  end_date: string,
  filters: IFilter[],
  sorters?: ISorter[],
  page?: number
) => {
  const startDateParsed = moment(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const endDateParsed = moment(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const params: {
    [key: string]: string | boolean | string[][];
  } = {
    start_date: startDateParsed,
    end_date: endDateParsed
  };

  const response = await apiFetch(
    'GET',
    getUrl('/esg/organization_datapoints', {
      queryParams: generateQueryParamsFromObject({
        ...params,
        page,
        size: SIZE_PAGINATION_SMALL
      }),
      filters,
      sorters
    })
  );

  const frameworks: string[] = [];

  const data = response.data.items as EsgMetricsEnhanced[] | BackendDatapoint[];

  data.forEach((item) => {
    item.frameworks.forEach((framework) => {
      if (!frameworks.includes(framework)) {
        frameworks.push(framework);
      }
    });
  });

  return {
    items: response.data.items as EsgMetricsEnhanced[],
    total: response.data.total,
    total2: frameworks.length,
    page: response.data.page
  };
};

export type UpdateDatapointBody = {
  value?: string | boolean | number | File;
  description?: string;
  start_date: string;
  end_date: string;
};
export const updateDatapointValue = async (
  org_data_point_id: string,
  body: UpdateDatapointBody,
  category?:
    | 'double_materiality'
    | 'environmental'
    | 'general_information'
    | 'social'
    | 'governance'
) => {
  try {
    if (body.value instanceof File && !category) {
      return null;
    }
    // Check if the value is a file
    if (body.value instanceof File && category) {
      // Get presigned url
      const presignedUrlData = await getPresignedUrlEsg({
        fileName: body.value.name,
        folder: category
      });
      const uploadUrl = presignedUrlData.upload_url;

      await uploadFilePresignedUrl(body.value, uploadUrl);

      // Set the value to the file upload url
      body.value = uploadUrl;
    }
    const response = await apiFetch(
      'PUT',
      `/esg/organization_data_point/${org_data_point_id}`,
      body
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export type CreateDatapointBody = {
  data_point_id: string;
  value: string | boolean | number | File;
  description: string;
  start_date: string;
  end_date: string;
};
export const createDatapointValue = async (
  body: CreateDatapointBody,
  category?:
    | 'double_materiality'
    | 'environmental'
    | 'general_information'
    | 'social'
    | 'governance'
) => {
  try {
    if (body.value instanceof File && !category) {
      return null;
    }
    // Check if the value is a file
    if (body.value instanceof File && category) {
      // Get presigned url
      const presignedUrlData = await getPresignedUrlEsg({
        fileName: body.value.name,
        folder: category
      });
      const uploadUrl = presignedUrlData.upload_url;

      await uploadFilePresignedUrl(body.value, uploadUrl);

      // Set the value to the file upload url
      body.value = uploadUrl;
    }
    const response = await apiFetch('POST', `/esg/organization_data_point`, body);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const esgUnits = async (dataType: string) => {
  const response = await apiFetch('GET', `/esg/units?data_type=${dataType}`);
  return response.data;
};

type PatchDatasourceBody = {
  value?: string | boolean | number;
  description?: string;
  file_urls?: string[];
};

export const patchDatasource = async (id: string, body: PatchDatasourceBody) => {
  try {
    const response = await apiFetch('PATCH', `/esg/organization_datasource/${id}`, body);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDatasourceProgress = async (
  start_date: string,
  end_date: string,
  returnRequest = false
) => {
  try {
    const url = getUrl('/esg/datasource_progress', {});

    const request = apiFetch(
      'GET',
      url,
      {},
      {},
      {
        start_date,
        end_date
      }
    );

    if (returnRequest) {
      return request;
    }

    const response = await request;

    return response.data;
  } catch (error) {
    return null;
  }
};
