import { useTranslation } from 'react-i18next';
import BlueWrapper from '../../../../ui/blueWrapper/BlueWrapper';
import Icon from '../../../../ui/icon/Icon';
import './styles.scss';

export const InfoTag = () => {
  const { t } = useTranslation();
  const linkIT = {
    name: t('sendEmailForm.linkIt'),
    link: 'dcycle_avoid_spam'
  };

  const linkCom = {
    name: t('sendEmailForm.linkCom'),
    link: 'dcycle_communication_employees'
  };

  const handleLink = (urlName: string) => {
    const url = `/files/communicationEmployees/${urlName}.pdf`;
    const link = document.createElement('a');
    link.href = url;
    link.download = `${urlName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const renderLink = (link: string, name: string) => {
    return (
      <span
        onClick={() => handleLink(link)}
        className='link-word pointer'
        style={{ fontSize: '10px' }}>
        {name}
      </span>
    );
  };

  return (
    <BlueWrapper className='infotag'>
      <div style={{ margin: '1em' }}>
        <Icon icon='multi_star' color='blocked' />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {t('sendEmailForm.tooltipInfoSpam1')}
          {renderLink(linkIT.link, linkIT.name)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {t('sendEmailForm.tooltipInfoSpam2')}
          {renderLink(linkCom.link, linkCom.name)}
        </div>
      </div>
    </BlueWrapper>
  );
};
