import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDatapointToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useDebounceValue from '../../../../../../../customHooks/useDebounceValue';
import { createDatapointValue, updateDatapointValue } from '../../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../../types/entities/esgMetrics';
import { InputSize } from '../../../../../../../types/utilsEnums/input';
import { FilterText } from '../../../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../../../layout/NewFilters/Filters';
import Button from '../../../../../../ui/button/Button';
import InfiniteList from '../../../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../../../ui/modal/Modal';
import Sorting from '../../../../../../ui/sorting/Sorting';
import TotalLegend from '../../../../../../ui/totalLegend/TotalLegend';
import '../../styles.scss';
import { ModalDatapoint } from '../modal/ModalDatapoint';
import useColumns from './hooks/useColumns';
import useSortingOptions from './hooks/useSortingOptions';
import { CSRDModalPremium } from './components/CSRDModalPremium';
import { useGetDatapoints } from './hooks/useGetDatapoints';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import SlideToggleFacade from 'components/ui/formComponents/slideToggle3/SlideToggleFacade';
import useToggleOptions from './hooks/useToggleOptions';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import Icon from 'components/ui/icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from 'constants/routes';
import {
  renderFrameworks,
  renderStatus
} from 'components/dashboard/controlPanel/components/metrics/utils/utils';
import useGetData from 'components/tasks/hooks/useGetData';
import { useGetMetrics } from 'components/dashboard/controlPanel/components/metrics/hooks/useGetMetrics';
import CreateTask from 'components/tasks/components/createTask/CreateTask';
import { IGetBackendTask } from 'types/entities/task';
import { setNotification } from 'actions/notification';
import { UserContext } from 'context/userContext';
import { completeTaskCurrentUser } from 'actions/auth';

type Props = {
  category: string;
  subcategory?: string;
  setOnlyFirstStep?: (value: boolean) => void;
};

export const DatapointsTable = ({ category, subcategory, setOnlyFirstStep }: Props) => {
  const { t } = useTranslation();
  const { t: tDataSource } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics'
  });
  const dispatch = useDispatch();
  const [datapointToEdit, setDatapointToEdit] = useState<EsgMetricsEnhanced>();
  const toggleOptions = useToggleOptions();
  const [mode, setMode] = useState<string>(toggleOptions[0].id);
  const [filters, setFilters] = useState<IFilter[]>([
    { field: 'esg_category', type: 'eq', value: category },
    { field: 'data_source', type: 'eq', value: 'F' }
  ]);

  const { users, tags, loading } = useGetData();
  const user = useContext(UserContext);
  const [filtersDataSource, setFiltersDataSource] = useState<IFilter[]>([
    {
      field: 'data_source',
      type: 'eq',
      value: 'T'
    },
    {
      field: 'esg_category',
      type: 'eq',
      value: mode
    }
  ]);

  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'short_name',
      order: 'asc'
    }
  ]);
  const flags = useFeatureFlags();
  const { startDate, endDate } = useContext(FilterDatesContext);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const navigate = useNavigate();

  const debounceValue = useDebounceValue(searchValue);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, editElement } = useGetDatapoints({
    filters,
    sorters,
    startDate,
    endDate
  });
  useEffect(() => {
    setFiltersDataSource([
      {
        field: 'data_source',
        type: 'eq',
        value: 'T'
      },
      {
        field: 'esg_category',
        type: 'eq',
        value: 'environmental'
      }
    ]);
  }, [mode]);
  const {
    data: dataSourceData,
    isLoading: isLoadingDataSource,
    isFetchingNextPage: isFetchingNextPageDataSource,
    fetchNextPage: fetchNextPageDataSource,
    editElement: editElementDataSource
  } = useGetMetrics({
    filters: filtersDataSource,
    sorters,
    mode,
    startDate,
    endDate
  });
  const renderCategory = (category: string) => {
    if (!category) return '-';
    if (category === 'social_governance') {
      return t(`categories.${mode}`);
    }
    return t(`categories.${category}`);
  };
  const [showCreate, setShowCreate] = useState(false);
  const renderEditButton = () => {
    const dropdownOptions = [
      /* {
        id: 'assignResponsible',
        name: tDataSource('assignResponsible'),
        onClick: () => {
          // setMetricToEdit(metric);
          console.log('not yet implemented');
        }
      }, */
      {
        id: 'editResponsible',
        name: tDataSource('editResponsible'),
        onClick: () => {
          setShowCreate(true);
        }
      }
    ];

    return (
      <div className='flex justify-end' style={{ width: '100%' }}>
        <ButtonDropdown
          button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
          options={dropdownOptions}
        />
      </div>
    );
  };

  const datapoint_number_array = [2, 10, 10, 6, 5, 5, 3, 3, 5, 8];
  const parseCustomData = () => {
    if (!dataSourceData) {
      return [];
    }
    return dataSourceData.items?.map((item, index) => {
      return {
        ...item,
        name: tDataSource(`list.${item.name}`),
        category: renderCategory(item.category),
        frameworks: renderFrameworks(item.frameworks, t),
        edit: renderEditButton(),
        datapoint_number: datapoint_number_array[index] || Math.floor(Math.random() * 10) + 1,
        esrs: 'E1',
        relevance: 'mandatory'
      };
    });
  };
  const parseData = () => {
    if (!data) {
      return [];
    }
    return data.items?.map((item) => {
      return {
        ...item,
        edit: renderEditButton()
      };
    });
  };
  const handleEditRelevance = async (value: SelectOptionFormat, data: EsgMetricsEnhanced) => {
    const newData = {
      ...data,
      start_date: data.start_date.replaceAll('/', '-'),
      end_date: data.end_date.replaceAll('/', '-'),
      relevance: value.id
    };

    if (!data.organization_data_point_id) {
      await createDatapointValue(
        parseDatapointToBackend(newData),
        data.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    } else {
      await updateDatapointValue(
        data.organization_data_point_id,
        parseDatapointToBackend(newData),
        data.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    }

    editElement(newData);
  };

  const [tasks, setTasks] = useState<IGetBackendTask[]>([]);
  const handleAddTask = (task: IGetBackendTask) => {
    setTasks((prev) => [task, ...prev]);
    setShowCreate(false);
    dispatch(setNotification(t('notification.createTask')));
    if (task.assigned_to === user?.id) {
      const newTasks = [...(user?.tasks?.items ?? [])];
      newTasks.push(task);
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total + 1,
          total_completed: user.tasks.total_completed,
          items: newTasks
        })
      );
    }
  };
  const columns = useColumns({
    setDatapointToEdit,
    handleEditRelevance,
    setShowPremiumModal,
    dataSource: mode === 'dataSources'
  });

  const sortingOptions = useSortingOptions();

  useEffect(() => {
    const newFilters = [...filters];
    if (subcategory) {
      const foundSubcategoryFilterIndex = filters.findIndex(
        (filter) => filter.field === 'esg_subcategory'
      );
      if (foundSubcategoryFilterIndex === -1) {
        newFilters.push({ field: 'esg_subcategory', type: 'eq', value: subcategory });
      } else {
        newFilters[foundSubcategoryFilterIndex] = {
          field: 'esg_subcategory',
          type: 'eq',
          value: subcategory
        };
      }
    }

    if (debounceValue) {
      newFilters.push({ field: 'name', type: 'il', value: debounceValue });
    } else {
      const foundSearchFilterIndex = filters.findIndex((filter) => filter.field === 'short_name');
      if (foundSearchFilterIndex !== -1) {
        newFilters.splice(foundSearchFilterIndex, 1);
      }
    }

    setFilters(newFilters);
  }, [subcategory, debounceValue]);

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };
  console.log(data, 'asdjkasdjkasdjkbasdjkbasdjkb');
  return (
    <div className='infinite-list-datapoints flex flex-col'>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu></Filters.Menu>
        <InfiniteList
          fetchData={fetchNextPage}
          data={mode === 'dataSources' ? parseCustomData() : parseData()}
          columns={columns}
          loading={isFetchingNextPage}
          firstLoading={isLoading}
          total={data?.total}
          header={
            <div
              className='flex justify-between items-center'
              style={{
                width: '100%'
              }}>
              <div className='flex items-center gap-x-2'>
                <FilterText
                  field='short_name'
                  type='il'
                  placeholder={t('esgMetrics.general.idDatapoint')}
                  size={InputSize.SMALL}
                />
                {flags?.webinar241024 ? (
                  <SlideToggleFacade
                    handleSwitchMode={(mode) => {
                      setMode(mode);
                      setOnlyFirstStep && setOnlyFirstStep(mode === 'dataSources');
                    }}
                    idSelected={mode}
                    options={toggleOptions}
                  />
                ) : (
                  <>
                    <Sorting
                      options={sortingOptions}
                      label={t('input.sorting.orderBy')}
                      placeholder={t('input.placeholderSelect')}
                      onSort={onSort}
                      defaultSort={{
                        id: sorters[0].field,
                        name: t(`input.sorting.datapoints.${sorters[0].field}`),
                        direction: sorters[0].order
                      }}
                    />
                  </>
                )}
              </div>
              <div className='flex justify-end items-center'>
                <Button
                  style={{
                    width: '7rem',
                    marginRight: '0.66rem'
                  }}
                  size='tiny'
                  lookAndFeel={'primary'}
                  disabled
                  text={t('esgMetrics.general.download')}
                />
                <TotalLegend
                  total={data?.total || 0}
                  // total2={pendings}
                  loading={false}
                  i18key={'esgMetrics'}
                />
              </div>
            </div>
          }
        />
      </Filters.Root>
      {datapointToEdit && datapointToEdit.value && (
        <Modal.WithPortal
          show={!!datapointToEdit && !!datapointToEdit.value}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            edit
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={editElement}
          />
        </Modal.WithPortal>
      )}
      {datapointToEdit && !datapointToEdit.value && (
        <Modal.WithPortal
          show={!!datapointToEdit && !datapointToEdit.value}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={editElement}
          />
        </Modal.WithPortal>
      )}
      {showPremiumModal && (
        <Modal.WithPortal show={showPremiumModal} onClose={() => setShowPremiumModal(false)}>
          <CSRDModalPremium />
        </Modal.WithPortal>
      )}
      {showCreate && (
        <Modal
          show={showCreate}
          onClose={() => setShowCreate(false)}
          maxWidth='600px'
          width='600px'>
          <CreateTask
            handleAddTask={handleAddTask}
            users={users}
            tags={tags}
            preSetOrganization={null}
          />
        </Modal>
      )}
    </div>
  );
};
