import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationContext } from '../../../../context/notificationContext';
import { UserContext } from '../../../../context/userContext';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { Shipment } from '../../../../types/entities/shipment';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../ui/modal/Modal';
import AddShipment from './addShipment/AddShipment';
import DeleteShipment from './deleteShipment/DeleteShipment';
import EditShipment from './editShipment/EditShipment';
import InputNuvo from './common/inputNuvo';
import './styles.scss';
import UploadShipmentsFile from './uploadShipmentsFile/UploadShipmentsFile';
import { ShipmentStatus } from '../../../../types/entitiesEnums/shipments';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import useFetchInfiniteList from '../../../ui/infiniteList/hooks/useFetchInfiniteList';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { SIZE_PAGINATION_BIG } from '../../../../constants';
import { getTransportRoutes } from '../../../../services/api/transportRoutes';
import useColumns from './hooks/useColumns';
import Sorting from '../../../ui/sorting/Sorting';
import useSortingOptions from './hooks/useSortingOptions';
import TotalLegendV2 from '../../../ui/totalLegend/TotalLegendV2';
import FilterHandlers from '../../../layout/NewFilters/FiltersHandlers';
import { FilterText } from '../../../layout/NewFilters/FilterText';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { Filters } from '../../../layout/NewFilters/Filters';
import { FilterSection } from '../../../layout/NewFilters/FilterSection';
import { SHIPMENT_STATUS } from './constants';
import WarningLabel from '../../../ui/statusLabels/warningLabel/WarningLabel';
import { InputSize } from '../../../../types/utilsEnums/input';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import formatNumber from '../../../../utils/formatNumber';
import Icon from '../../../ui/icon/Icon';
import { limitString } from '../../../../utils/limitString';
import FiltersDate from '../../../layout/NewFilters/FiltersDate';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import { ROUTES } from '../../../../constants/routes';
import { downloadByCategory } from 'services/api/download';
import DownloadCategoryModal from 'components/downloadCategoryModal/DownloadCategoryModal';

function Shipments() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const flags = useFeatureFlags();

  const user = useContext(UserContext);
  const setNotification = useContext(NotificationContext);

  const foundOrganization = useSelectedOrganization();

  const dispatch = useDispatch();
  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [searchParams, setSearchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction');
  const status = searchParams.get('status');

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'created_at',
      order: 'desc'
    }
  ]);

  const [shipmentToEdit, setShipmentToEdit] = useState<Shipment | null>();
  const [shipmentToDelete, setShipmentToDelete] = useState('');
  const [showAddShipment, setShowAddShipment] = useState(false);
  const [showUploadShipmentsFile, setShowUploadShipmentsFile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    const newFilters = [...filters];
    if (transportDirection) {
      const foundIndex = newFilters.findIndex((elem) => elem.field === 'transport_direction');
      if (foundIndex === -1)
        newFilters.push({
          field: 'transport_direction',
          value: transportDirection,
          type: 'eq'
        });
      else newFilters[foundIndex].value = transportDirection;
    }

    if (status) {
      const foundIndex = newFilters.findIndex((elem) => elem.field === 'status');
      if (foundIndex === -1)
        newFilters.push({
          field: 'status',
          value: [status],
          type: 'in'
        });
      else newFilters[foundIndex].value = [status];
      setOpen(true);
      // wait 2 seconds and delete the status from the url
      setTimeout(() => {
        searchParams.delete('status');
        setSearchParams(searchParams);
      }, 2000);
    }
    setFilters(newFilters);
  }, [transportDirection, status]);

  const fetch = async (page: number): Promise<Pagination<Shipment> | undefined> => {
    if (!filters.find((elem) => elem.field === 'transport_direction')) return;

    const response = await getTransportRoutes(page, SIZE_PAGINATION_BIG, filters, sorters);
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    addElement,
    removeElement,
    editElement,
    data,
    rest
  } = useFetchInfiniteList<Shipment>(fetch, [JSON.stringify(filters), JSON.stringify(sorters)]);

  const renderDropdownComponent = (shipment: Shipment) => {
    const options = [
      {
        id: `${shipment.id}-edit`,
        name: t('shipments.edit'),
        onClick: () => {
          setShipmentToEdit(shipment);
        }
      },
      {
        id: `${shipment.id}-delete`,
        name: t('shipments.delete'),
        onClick: () => {
          if (shipment.id) {
            setShipmentToDelete(shipment.id);
          }
        }
      }
    ];

    if (shipment.file_name) {
      options.push({
        id: `${shipment.id}-go-to-file`,
        name: t('shipments.goToFile'),
        onClick: () => {
          navigate(
            getUrl(
              `${ROUTES.MEASURE_UPLOADED_FILES}/transport-and-distribution-${transportDirection}`,
              {
                queryParams: generateQueryParamsFromObject({ name: shipment.file_name ?? '' })
              }
            )
          );
        }
      });
    }
    return <ButtonDropdown options={options} />;
  };

  const onCloseModal = () => {
    setShowAddShipment(false);
    setShowUploadShipmentsFile(false);
    setShipmentToEdit(null);
    setShipmentToDelete('');
  };

  const addShipment = (shipment: Shipment) => {
    addElement(shipment);
    onCloseModal();
    dispatch(setNotification(t(`notification.${transportDirection}.createShipment`)));
  };

  const handleShowAddShipmentModal = () => {
    setShowAddShipment(true);
  };

  const deleteShipment = (id: string) => {
    removeElement(id);
    dispatch(setNotification(t(`notification.${transportDirection}.deleteShipment`)));
    setShipmentToDelete('');
  };

  const editShipment = (value: Shipment) => {
    editElement(value);
    dispatch(setNotification(t(`notification.${transportDirection}.editShipment`)));

    setShipmentToEdit(null);
  };

  const columns = useColumns(transportDirection ?? '');

  const getErrorMessage = (errorMessages: string[]) => {
    if (errorMessages.length === 0) {
      return {
        error: t('general.withErrors'),
        tooltip: t('shipments.errors.general')
      };
    }
    if (errorMessages.length > 1) {
      return {
        error: t('general.reviewSeveral'),
        tooltip: (
          <>
            <span>{t('general.reviewTheFollowingIncidences')}</span>
            <ul>
              {errorMessages.map((elem: string) => (
                <li key={elem}>
                  {i18n.exists(`shipments.errors.${elem.toLowerCase()}_tooltip`)
                    ? t(`shipments.errors.${elem.toLowerCase()}_tooltip`)
                    : t('shipments.errors.general')}
                </li>
              ))}
            </ul>
          </>
        )
      };
    }
    const error = errorMessages[0];
    if (!error) {
      return {
        error: t('general.withErrors'),
        tooltip: t('shipments.errors.general')
      };
    }
    // check if t(`shipments.errors.${error.toLowerCase()}`) exists and tooltip also
    return {
      error: i18n.exists(`shipments.errors.${error.toLowerCase()}`)
        ? t(`shipments.errors.${error.toLowerCase()}`)
        : t('general.withErrors'),
      tooltip: i18n.exists(`shipments.errors.${error.toLowerCase()}_tooltip`)
        ? t(`shipments.errors.${error.toLowerCase()}_tooltip`)
        : t('shipments.errors.general')
    };
  };

  const statusIconSelect = (status: string, errorMessages: string[], showTooltip = true) => {
    if (status === ShipmentStatus.ACTIVE)
      return <SuccessLabel>{t('general.completed')}</SuccessLabel>;

    if (status === ShipmentStatus.ERROR) {
      if (showTooltip) {
        const { error, tooltip } = getErrorMessage(errorMessages);
        return (
          <TooltipWrapper text={tooltip} position='top' shouldAppear={!!tooltip}>
            <WarningLabel style={{ whiteSpace: 'nowrap' }}>{error}</WarningLabel>
          </TooltipWrapper>
        );
      }

      return <WarningLabel>{t('general.withErrors')}</WarningLabel>;
    }

    if (status === ShipmentStatus.PENDING) {
      return (
        <TooltipWrapper text={t('shipments.pendingTooltip')}>
          <PendingLabel>{t('general.pending')}</PendingLabel>
        </TooltipWrapper>
      );
    }

    return <></>;
  };

  const parseData = () => {
    const units = [
      { id: '61743a63-ff70-459c-9567-5eee8f7dfd5c', name: 'kilogram_(kg)', type: 'solid' },
      { id: 'cab66828-c2b1-431b-92af-f9ab37149d3c', name: 'metric_tonne_(t)', type: 'solid' }
    ];

    return data.map((shipment) => {
      const foundUnit = units.find((unit) => unit.id === shipment.unit_id);
      let originDestination = `${shipment.transport_origin} - ${shipment.transport_destination}`;
      if (
        !shipment.transport_origin?.replaceAll(',', '').trim() ||
        !shipment.transport_destination?.replaceAll(',', '').trim()
      ) {
        originDestination = shipment.kms ? `${shipment.kms} kms` : '-';
      }
      return {
        ...shipment,
        name: shipment.name ? limitString(shipment.name, 30) : '-',
        start_date:
          shipment.transport_direction === 'downstream'
            ? new Date(shipment.start_date).toLocaleDateString()
            : shipment.end_date
            ? new Date(shipment.end_date).toLocaleDateString()
            : '-',
        origin_destination: originDestination,
        transport_route_type: shipment.transport_route_type
          ? t(`shipments.${shipment.transport_route_type}`)
          : '-',
        supplier: shipment.supplier ? limitString(shipment.supplier, 30) : '-',
        transport_frequency: t(`shipments.${shipment.transport_frequency}`),
        quantity_transported:
          `${shipment.quantity_transported} ${
            foundUnit ? t(`units_short.${foundUnit.name}`) : ''
          }` || '-',
        status: flags?.shipmentsUpload
          ? statusIconSelect(shipment.status, shipment.error_messages ?? [])
          : statusIconSelect('active', []),
        co2e: (
          <span
            className='highlight-text-color'
            style={{ fontWeight: 600, textAlign: 'right', display: 'block', whiteSpace: 'nowrap' }}>
            {shipment.co2e !== undefined
              ? `${formatNumber(numberToDecimalNonZero(shipment.co2e))} kg`
              : '-'}
          </span>
        ),
        edit: renderDropdownComponent(shipment),
        disabled: shipment.status === 'loading' || shipment.status === 'error'
      };
    });
  };

  const handleShowUploadShipmentsFileModal = () => {
    setShowUploadShipmentsFile(true);
  };

  const onUploadShipmentsFile = () => {
    setShowUploadShipmentsFile(false);
    dispatch(setNotification(t(`notification.uploadShipmentFile`)));
  };

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };

  let buttonDisabled = true;

  if (foundOrganization) {
    buttonDisabled =
      transportDirection === 'downstream'
        ? total >= foundOrganization.limit_shipments
        : total >= foundOrganization.limit_deliveries;
  }

  if (!transportDirection) {
    return null;
  }
  const sortingOptions = useSortingOptions({ transportDirection });

  const Nuvo = buttonDisabled ? (
    <Button
      lookAndFeel='blocked'
      text={t(`shipments.${transportDirection}.uploadFile`)}
      size='small'
    />
  ) : (
    <InputNuvo type={transportDirection as 'downstream' | 'upstream'} />
  );

  return (
    <MainPageLayout
      sectionTitle={t('purchases.title')}
      title={t('shipments.start')}
      description={t(`shipments.${transportDirection}.startDescription`)}
      breadcrumb={
        <Breadcrumb
          crumbsReplace={[
            {
              key: 'shipments',
              value: t(`breadcrumb.${transportDirection}`)
            }
          ]}
        />
      }
      buttons={
        <>
          {flags?.nuvo ? (
            Nuvo
          ) : (
            <Button
              lookAndFeel={buttonDisabled ? 'blocked' : 'primary'}
              text={t(`shipments.${transportDirection}.uploadFile`)}
              size='small'
              onClick={handleShowUploadShipmentsFileModal}
            />
          )}
          <Button
            iconNode={<Icon icon='add' color='gray-dark' />}
            lookAndFeel={buttonDisabled ? 'blocked' : 'secondary'}
            text={t(`shipments.${transportDirection}.addManual`)}
            size='small'
            onClick={handleShowAddShipmentModal}
          />
        </>
      }>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu>
          <FiltersDate fields={['start_date']} />
          <FilterSection.Multiple
            title={t('purchases.status')}
            field='status'
            type='in'
            options={Object.values(SHIPMENT_STATUS).map((status) => ({
              value: status,
              label: statusIconSelect(status, [], false)
            }))}
          />
        </Filters.Menu>
        <InfiniteList
          data={parseData()}
          columns={columns}
          loading={loadingTable}
          firstLoading={firstLoading}
          fetchData={fetchData}
          total={total}
          header={
            <>
              <div className='flex gap-x-2 items-center'>
                <FilterText
                  field='name'
                  type='il'
                  placeholder={t('shipments.description')}
                  size={InputSize.MEDIUM}
                />
                <Sorting
                  options={sortingOptions}
                  label={t('input.sorting.orderBy')}
                  placeholder={t('input.placeholderSelect')}
                  onSort={onSort}
                  size={InputSize.MEDIUM}
                />
                <FilterHandlers blacklistedFilters={{ all: ['name', 'transport_direction'] }} />
              </div>
              <div className='flex gap-x-2 items-center'>
                <TooltipWrapper text={t('general.downloadButtonEmailTooltip')}>
                  <Button
                    lookAndFeel={'primary'}
                    iconNode={<Icon icon='download' color='white' />}
                    text={t('general.download')}
                    size='small'
                    onClick={() => setShowDownloadModal(true)}
                  />
                </TooltipWrapper>
                <TotalLegendV2
                  totalLabel='Total'
                  totalElements={[
                    { value: total, label: t(`shipments.${transportDirection}.total`) },
                    { value: rest?.total2, label: t('general.withError') }
                  ]}
                  style={{ marginLeft: 'auto' }}
                />
              </div>
            </>
          }
        />
      </Filters.Root>
      <Modal show={showUploadShipmentsFile} onClose={onCloseModal} width='600px' maxWidth='600px'>
        {user && (
          <UploadShipmentsFile
            user={user}
            transportDirection={transportDirection}
            onUploadFile={onUploadShipmentsFile}
          />
        )}
      </Modal>
      <Modal show={showAddShipment} onClose={onCloseModal} width='650px' maxWidth='650px'>
        {user && <AddShipment user={user} addShipment={addShipment} />}
      </Modal>
      <Modal show={!!shipmentToDelete} onClose={onCloseModal} width='500px' maxWidth='500px'>
        <DeleteShipment
          deleteShipment={deleteShipment}
          shipmentToDelete={shipmentToDelete}
          user={user}
        />
      </Modal>

      <Modal show={!!shipmentToEdit} onClose={onCloseModal} width='650px' maxWidth='650px'>
        {shipmentToEdit && user && (
          <EditShipment user={user} shipmentToEdit={shipmentToEdit} editShipment={editShipment} />
        )}
      </Modal>
      <Modal show={showDownloadModal} onClose={() => setShowDownloadModal(false)} width='500px'>
        <DownloadCategoryModal
          category={`transport_routes_${transportDirection}`}
          onClose={() => setShowDownloadModal(false)}
        />
      </Modal>
    </MainPageLayout>
  );
}

export default Shipments;
