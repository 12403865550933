import { useTranslation } from 'react-i18next';
import { useColumns } from '../hooks/useColumns';

import { Mode, Type } from '../../../../types/entitiesEnums/esgMetrics';
import SlideToggleFacade from '../../../ui/formComponents/slideToggle3/SlideToggleFacade';

import { getCurrentYear, getPreviousYear, parseDataForSelectIntensityMetric } from '../utils/utils';

import InputWrapperPlain from '../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import Select from '../../../ui/formComponents2/select/Select';
import TableComponent from '../../../ui/table/Table';
import { EsgMetricFrontend, EsgMetricInfo } from '../../../../types/entities/esgMetrics';

type Props = {
  data?: EsgMetricFrontend; //ojo cambiar
  loading: boolean;
  setAbsoluteOrIntensity: (value: SelectOptionFormat) => void;
  absoluteOrIntensity: SelectOptionFormat<string>;
  intensityMetricData: SelectOptionFormat<string>[];
  impactPerMetricIntensityMetric: SelectOptionFormat<string>;
  setImpactPerMetricIntensityMetric: (value: SelectOptionFormat) => void;
  mode?: string;
};

const EsgCard = ({
  data,
  loading,
  absoluteOrIntensity,
  setAbsoluteOrIntensity,
  intensityMetricData,
  impactPerMetricIntensityMetric,
  setImpactPerMetricIntensityMetric,
  mode
}: Props) => {
  const { t, i18n } = useTranslation();

  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const dateRanges = [
    [`${previousYear}/01/01`, `${previousYear}/12/31`],
    [`${currentYear}/01/01`, `${currentYear}/12/31`]
  ];

  const toggleOptions = [
    {
      id: Mode.ABSOLUT,
      name: t('dashboard.absolute')
    },
    {
      id: Mode.INTENSITY,
      name: t('dashboard.intensity')
    }
  ];

  const handleData = () => {
    return mode === Type.ENVIRONMENTAL ? data?.environmental : data?.socialGovernance;
  };

  const handleSwitchMode = (value: string) =>
    setAbsoluteOrIntensity(
      toggleOptions.find((option) => option.id === value) || { id: '', name: '' }
    );

  const { columnsMetrics } = useColumns();

  const processData = () => {
    let dataToReturn: EsgMetricInfo[] = [];
    if (
      loading ||
      (absoluteOrIntensity.id === Mode.INTENSITY && !impactPerMetricIntensityMetric.id)
    )
      return dataToReturn;

    dataToReturn = handleData() || [];

    return dataToReturn;
  };

  const processTextEmpty = () => {
    if (absoluteOrIntensity.id === Mode.INTENSITY && !impactPerMetricIntensityMetric.id)
      return t('table.selectIntensityMetric');
    return t('table.emptyEsg');
  };

  const dataToShow = processData();
  const textEmpty = processTextEmpty();

  return (
    <div className='flex-col impact-intensity-metric'>
      <div className='infinite-scroll-container card-border-color main-bg-color solid-border overflow-scroll'>
        <div className='flex gap-2 items-center justify-between'>
          <div className='flex gap-2 items-center'>
            <span className='font-14 weight-600'>{t('dashboard.impactByEsgMetric')}</span>
            <SlideToggleFacade
              handleSwitchMode={handleSwitchMode}
              idSelected={absoluteOrIntensity.id}
              options={toggleOptions}
            />
          </div>
          <div className='flex justify-end items-center' style={{ marginBottom: '1rem' }}>
            {absoluteOrIntensity.id === Mode.ABSOLUT || mode === 'socialAndGovernance' ? null : (
              <InputWrapperPlain iconV2='filter' width='20%'>
                <Select
                  onChange={(e) => setImpactPerMetricIntensityMetric(e)}
                  value={impactPerMetricIntensityMetric}
                  options={parseDataForSelectIntensityMetric(intensityMetricData)}
                  placeholder={t('dashboard.phIntensityMetric')}
                />
              </InputWrapperPlain>
            )}
          </div>
        </div>
        <div className='infinite-scroll-wrapper'>
          <TableComponent
            columns={columnsMetrics}
            data={dataToShow}
            loading={loading}
            emptyText={textEmpty}
            rowClassName='whitespace-normal-row'
            // sticky={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgCard;
