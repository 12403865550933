import { getDcycleProducts } from '../../../../../../services/api/dcycleProducts';
import { getPrimaryCodes } from '../../../../../../services/api/primaryCode';
import useFetchInfiniteList from '../../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { primaryCodeAdapter } from '../adapter';
import { dcycleProductAdapter } from '../adapter/dcycleProduct';

export const usePrimaryCodes = (filters: IFilter[]) => {
  const { data, loading, total, fetchData, firstLoading, editElement } = useFetchInfiniteList(
    getPrimaryCodes(filters),
    [JSON.stringify(filters)]
  );

  return { data: primaryCodeAdapter(data), loading, total, fetchData, firstLoading, editElement };
};

type Params = {
  code?: string;
  hasSuggestions: boolean;
};

export const useDcycleProducts = ({ code, hasSuggestions }: Params) => {
  const { data, loading } = useFetchInfiniteList(getDcycleProducts(code ?? '', hasSuggestions), [
    code,
    hasSuggestions
  ]);

  if (loading) {
    return { data: [] };
  }

  return { data: dcycleProductAdapter(data) };
};
