import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { useTranslation } from 'react-i18next';

export const WasteWaterDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <DescriptionBox
      descriptions={[
        t('ghgReport.wasteWaterDisclaimer.description1'),
        t('ghgReport.wasteWaterDisclaimer.description2'),
        t('ghgReport.wasteWaterDisclaimer.description3'),
        t('ghgReport.wasteWaterDisclaimer.description4'),
        t('ghgReport.wasteWaterDisclaimer.description5'),
        t('ghgReport.wasteWaterDisclaimer.description6'),
        t('ghgReport.wasteWaterDisclaimer.description7')
      ]}
    />
  );
};
