import { ReactComponent as Step1 } from 'assets/img/modal_bulk_create_materials_1.svg';
import { ReactComponent as Step2 } from 'assets/img/modal_bulk_create_materials_2.svg';
import { ReactComponent as Step3 } from 'assets/img/modal_bulk_create_materials_3.svg';

export const MODAL_BULK_IMAGES = {
  1: Step1,
  2: Step2,
  3: Step3
} as const;

export const MAX_NUVO_ENTRIES = 700_000;

export const NUVO_IDENTIFIER = 'lca_materials_template';

export const CATEGORY = 'LCA_MATERIALS';

export const LCA_MATERIAL_MANAGEMENT_SORTERS: ISorter[] = [
  { field: 'impact_source_id', order: 'desc' }
];

export const LCA_MATERIAL_MANAGEMENT_SIZE = 30;
