import { InfiniteData } from '@tanstack/react-query';
import { LCAFormMaterial } from 'components/lca/Scenarios/components/SelectMaterialModal/constants';

const adaptLCAMaterialToFormMaterial = (material: LCAMaterial): LCAFormMaterial => ({
  material_id: material.id,
  quantity: '',
  unit: '',
  unit_id: '',
  type: material.type,
  name: material.name,
  supplier: material.supplier,
  unit_list: material.unit_list,
  country: material.country,
  location: material.location,
  impact_source_id: material.impact_source_id
});

export const adaptLCAMaterialsForMaterialTable = (
  data: InfiniteData<IGetLCAMaterialsResponse, number>
) => {
  return data.pages.reduce(
    (acc, page) => {
      return {
        items: [...acc.items, ...page.items.map(adaptLCAMaterialToFormMaterial)],
        total: page.total
      };
    },
    { items: [] as LCAFormMaterial[], total: 0 }
  );
};
