import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CATEGORIES = {
  STATIONARY: 'stationary',
  RECHARGE: 'recharge',
  TRANSPORT: 'transport',
  WASTE_WATER_TREATMENT: 'waste_water_treatments',
  ELECTRICITY: 'electricity',
  EMPLOYEES: 'employees',
  INVESTMENTS: 'investments',
  TRAVELS: 'travels',
  TRANSPORT_DISTRIBUTION_UPSTREAM: 'transport_distribution_upstream',
  TRANSPORT_DISTRIBUTION_DOWNSTREAM: 'transport_distribution_downstream',
  WATER: 'water',
  PURCHASES: 'purchases',
  WASTES: 'wastes'
} as const;

export const CATEGORIES_BACK = {
  STATIONARY: 'stationary',
  RECHARGE: 'recharge',
  TRANSPORT: 'transport',
  WASTE_WATER_TREATMENT_WATER: 'waste_water_treatment_water',
  WASTE_WATER_TREATMENT_SLUDGE: 'waste_water_treatment_sludge',
  WASTE_WATER_TREATMENT_POPULATION: 'waste_water_treatment_population',
  WASTE_WATER_TREATMENT_DISCHARGE: 'waste_water_treatment_discharge',
  ELECTRICITY: 'electricity',
  EMPLOYEES_IN_ITINERE: 'employees_in_itinere',
  EMPLOYEES_IN_LABORE: 'employees_in_labore',
  INVESTMENTS: 'investments',
  TRAVELS: 'travels',
  TRANSPORT_DISTRIBUTION_UPSTREAM: 'transport_distribution_upstream',
  TRANSPORT_DISTRIBUTION_DOWNSTREAM: 'transport_distribution_downstream',
  WATER: 'water',
  PURCHASES: 'purchases',
  WASTES: 'wastes'
} as const;

const useCategories = () => {
  const { t, i18n } = useTranslation();
  const flags = useFeatureFlags();
  const categoriesConst = [
    { id: 'heat', name: t('facilityDetail.combustion') },
    { id: 'electricity', name: t('facilityDetail.electricity') },
    { id: 'recharge', name: t('facilityDetail.recharge') },
    { id: 'water', name: t('facilityDetail.water') },
    { id: 'waste', name: t('facilityDetail.waste') }
  ];
  if (flags?.customEfVehicles) {
    categoriesConst.push({ id: 'vehicles', name: t('measureMain.vehicles') });
  }
  const [categories, setCategories] = useState<SelectOptionFormat[]>(categoriesConst);

  useEffect(() => {
    setCategories(categoriesConst);
  }, [i18n.language]);

  const categoryObject = CATEGORIES_BACK;

  const categoryObjectFront = {
    STATIONARY: 'stationary',
    RECHARGE: 'recharge',
    TRANSPORT: 'transport',
    WASTE_WATER_TREATMENT: 'waste_water_treatment',
    ELECTRICITY: 'electricity',
    EMPLOYEES: 'employees',
    INVESTMENTS: 'investments',
    TRAVELS: 'travels',
    TRANSPORT_DISTRIBUTION_UPSTREAM: 'transport_distribution_upstream',
    TRANSPORT_DISTRIBUTION_DOWNSTREAM: 'transport_distribution_downstream',
    WATER: 'water',
    PURCHASES: 'purchases',
    WASTES: 'wastes'
  };

  return {
    categories,
    categoryObject,
    categoryObjectFront
  };
};

export default useCategories;
