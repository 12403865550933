import { useEffect, useState } from 'react';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { InputSize } from '../../../types/utilsEnums/input';
import InputText from '../../ui/formComponents2/inputText/InputText';
import InputWrapper from '../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { useFiltersContext } from './Filters';

type Props = {
  field: string;
  type: Exclude<FilterType, MultipleFilterType>;
  placeholder?: string;
  icon?: string;
  size?: InputSize | undefined;
};
export const FilterText: React.FC<Props> = ({
  field,
  type,
  placeholder,
  icon = '/images/icons/search.svg',
  size
}) => {
  const { filters, setFilters } = useFiltersContext();
  const [value, setValue] = useState<string>('');
  const filter = filters.find((filter) => filter.field === field) ?? {
    field,
    type,
    value: ''
  };

  if (Array.isArray(filter?.value)) {
    throw new Error('FilterText only accepts single values');
  }

  const debouncedValue = useDebounceValue(value, 500);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue === '') {
      setFilters((prev) => prev.filter((filter) => filter.field !== field));
      return;
    }

    setFilters((prev) => {
      const newFilters = prev.filter((filter) => filter.field !== field);

      newFilters.push({ field, type, value: debouncedValue });

      return newFilters;
    });
  }, [debouncedValue]);

  return (
    <InputWrapper icon={icon} size={size}>
      <InputText placeholder={placeholder} value={value} onChange={onChange} />
    </InputWrapper>
  );
};
