import { fetchOrganizationFrameworks, OrganizationFramework } from 'services/api/frameworks';
import { useAvailableFrameworks } from './useAvailableFrameworks';
import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext } from 'react';
import { convertStringToDate } from 'utils/convertDates';

const useOrganizationFrameworks = (organizationId?: string) => {
  const org = useSelectedOrganization();
  const orgId = organizationId ?? org?.id;
  const { startDate } = useContext(FilterDatesContext);
  const year = convertStringToDate(startDate).getFullYear();
  console.log('startDate', startDate);
  const { availableFrameworks, isLoading: loadingAvailableFrameworks } =
    useAvailableFrameworks(orgId);
  const flags = useFeatureFlags();
  const { data, isLoading } = useQuery<OrganizationFramework[] | null, Error>({
    queryKey: ['frameworkPlans', orgId, year],
    queryFn: async () => {
      if (!orgId) return null;
      return await fetchOrganizationFrameworks(year.toString(), orgId); // Assuming year "2025" is required
    },
    enabled: flags?.sotDcycleDemos && !!orgId
  });

  return {
    availableFrameworks,
    organizationFrameworks: data || [],
    frameworksLoading: loadingAvailableFrameworks || isLoading
  };
};

export default useOrganizationFrameworks;
