import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from 'actions/notification';
import { DOUBLE_MATERIALITY_ARTICLE } from 'constants/index';
import { UserContext } from 'context/userContext';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { sendEmails } from 'services/api/emails';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import SectionHeader from 'components/layout/sectionHeader/SectionHeader';
import Button from 'components/ui/button/Button';
import { CertificationPage } from 'components/ui/certificationPage/CertificationPage';
import HelpCTA from 'components/ui/helpCTA/HelpCTA';
import Modal from 'components/ui/modal/Modal';
import StepCarouselList from 'components/ui/stepCarouselList/StepCarouselList';
import useStepCarousel from 'components/ui/stepCarouselList/useStepCarousel';
import MoreInfoModal from './components/MoreInfoModal/MoreInfoModal';
import UploadModal from './components/UploadModal/UploadModal';
import { useNavigate } from 'react-router-dom';
import { useSteps } from './hooks/useSteps';
import { ROUTES } from 'constants/routes';
import { isKey } from 'utils/isKey';
import { PNGS } from '../../constants';

const DoubleMateriality = () => {
  const { t, i18n } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const allSteps = useSteps();

  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [loading, setLoading] = useState(false);

  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  const [showInterestGroup, setShowInterestGroup] = useState<boolean>(false);
  const handleSubmit = async () => {
    setLoading(true);

    const subject = `El usuario ${user?.email} de la organización ${selectedOrganization?.company_name} ha clicado en más información de Doble Materialidad`;

    const response = await sendEmails(subject, undefined, undefined, [
      'cecilia@dcycle.io',
      'vanesa@dcycle.io',
      'ricardo.mendez@dcycle.io',
      'clients@dcycle.io'
    ]);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setLoading(false);
    dispatch(setNotification(t('notification.success')));
  };

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: allSteps
  });

  const handleGoToArticle = () => {
    window.open(DOUBLE_MATERIALITY_ARTICLE['es'], '_blank');
  };

  const navigate = useNavigate();

  const { DM } = isKey(i18n.resolvedLanguage, PNGS) ? PNGS[i18n.resolvedLanguage] : PNGS.en;

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('title')} breadcrumb={<Breadcrumb />} />
      <div className='flex justify-between'>
        <StepCarouselList
          steps={steps}
          handleSelect={(id) => {
            handleSelect(id);
            setShowInterestGroup(false);
          }}
        />
        <HelpCTA description={t('helpDM')}>
          <div className='flex justify-between'>
            <Button
              lookAndFeel='primary'
              text={t('helpDMButton')}
              size='tiny'
              onClick={handleGoToArticle}
              style={{ marginLeft: '0.3rem' }}
            />
          </div>
        </HelpCTA>
      </div>

      {stepSelected?.id === 'doubleMateriality' && (
        <>
          <CertificationPage.Layout>
            <div className='flex'>
              <div className='flex-col'>
                <div className='flex-col justify-between'>
                  <CertificationPage.Title>{t('title')}</CertificationPage.Title>
                  <CertificationPage.Description>
                    <Trans i18nKey='doubleMateriality.startDescription' />
                  </CertificationPage.Description>
                  <CertificationPage.SectionLayout>
                    <CertificationPage.SectionTitle>{t('benefits')}</CertificationPage.SectionTitle>
                    <CertificationPage.Checks
                      checks={[t('check1'), t('check2'), t('check3'), t('check4')]}
                    />
                  </CertificationPage.SectionLayout>
                </div>

                <CertificationPage.ButtonSection>
                  <>
                    <Button
                      lookAndFeel='primary'
                      text={t('upload')}
                      size='medium'
                      onClick={() => setShowUpload(true)}
                      className='mt-4'
                      icon='/images/icons/uploadWhite.svg'
                      style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
                    />
                    <Button
                      lookAndFeel='secondary'
                      text={t('moreInfoModal.iWantToStartOne')}
                      size='medium'
                      onClick={() => {
                        handleSelect('companyContext');
                        setShowInterestGroup(false);
                      }}
                      loading={loading}
                      className='mt-4'
                      style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
                    />
                  </>
                </CertificationPage.ButtonSection>
              </div>
            </div>
            <DM className='w-full' />
          </CertificationPage.Layout>
          <Modal
            show={showUpload}
            onClose={() => setShowUpload(false)}
            maxWidth='650px'
            width='650px'>
            <UploadModal onClose={() => setShowUpload(false)} />
          </Modal>

          <Modal
            show={showMoreInfo}
            onClose={() => setShowMoreInfo(false)}
            maxWidth='613px'
            width='613px'>
            <MoreInfoModal onClose={() => setShowMoreInfo(false)} />
          </Modal>
        </>
      )}
      {stepSelected?.id === 'companyContext' && (
        <div className='infography-esg-metrics__container'>
          <img
            src={`/images/placeholders/dobleMatPaso${showInterestGroup ? '1.2' : '1'}.png`}
            className='infography-esg-metrics'
          />
          <button
            onClick={() => setShowInterestGroup(true)}
            className='infography-fakebtn'></button>
        </div>
      )}
      {stepSelected?.id === 'engageStakeholders' && (
        <div style={{ position: 'relative' }}>
          <img src='/images/placeholders/dobleMatPaso2.png' className='infography-esg-metrics' />
          <a
            style={{
              width: '9.2%',
              height: '4.8%',
              position: 'absolute',
              top: '48.8%',
              left: '79.4%',
              cursor: 'pointer'
            }}
            target='_blank'
            href='https://docs.google.com/forms/d/e/1FAIpQLSd8eaRUvYXwPQLOYnBuDIBgC_QizUNUZaHJFopaO8RtUAUigQ/viewform'
            rel='noreferrer'></a>
        </div>
      )}
      {stepSelected?.id === 'irosGapAnalysis' && (
        <div>
          <img
            src='/images/placeholders/dobleMatPaso3.png'
            className='infography-esg-metrics'
            onClick={() => navigate(ROUTES.MEASURE_ESG_METRICS + '/environmental')}
          />
        </div>
      )}
    </section>
  );
};

export default DoubleMateriality;
