type ChartData = {
  x: `${string}-${string}`;
  y: number;
};

export const parseDataCo2 = <T extends { co2e: number; year: number; month: number }>(
  dataCo2: ChartData[],
  data: T[]
) => {
  return dataCo2.map((elem) => {
    const [yearNum, dateNum] = elem.x.split('-').map(Number);

    const foundData = data.find((item) => item.year === yearNum && item.month === dateNum);

    if (!foundData) {
      return {
        x: elem.x,
        y: 0
      };
    }

    return {
      x: elem.x,
      y: elem.y + foundData.co2e / 1000
    };
  });
};
