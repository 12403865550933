import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationContext } from '../../../../../context/notificationContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { FilterSection } from '../../../../layout/NewFilters/FilterSection';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../layout/NewFilters/Filters';
import FilterHandlers from '../../../../layout/NewFilters/FiltersHandlers';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { ICON_SIZE_MAP } from '../../../../ui/icon/utils/constants';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../ui/modal/Modal';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import AddPurchase from '../addPurchase/AddPurchase';
import { PURCHASE_STATUS } from '../constants';
import { Buttons } from './components/Buttons';
import InviteSuppliersModalContent from './components/InviteSuppliersModalContent/InviteSuppliersModalContent';
import { ModalSupplier } from './components/ModalSupplier/ModalSupplier';
import { useColumns } from './hooks/useColumns';
import { useGetSuppliers } from './hooks/useGetSuppliers';
import useMockedData from './hooks/useMockedData';
import { useTotal } from './hooks/useTotal';
import { Reviewing } from '../components/Reviewing';
import { PurchasesStatusTag } from '../components/statusTag/StatusTag';
import { usePurchasesLoader } from '../../../../../reducers/purchasesLoader';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import { useSuppliersInReview } from './hooks/useSuppliersInReview';
import { IPurchaseSupplierFrontend } from 'types/purchaseSupplier';
import { SuppliersManagementEditForm } from './components/SuppliersManagementEditForm/SuppliersManagementEditForm';

type ContextProps = {
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  children: React.ReactNode;
};

type Context = {
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  setShowAddPurchase: (value: boolean) => void;
  showAddPurchase: boolean;
  total?: number;
  setTotal: (newTotal: ((prev: number) => number) | number) => void;
  totalLoading: boolean;
};

type EmptyTextProps = {
  isFiltering: boolean;
  isUploading: boolean;
};
const EmptyText: React.FC<EmptyTextProps> = ({ isFiltering, isUploading }) => {
  const { t } = useTranslation();

  if (isUploading) {
    return (
      <div className='flex gap-x-2 items-center'>
        <LoaderTables mode='fit' />
        <span>{t('purchases.uploadingSuppliers')}</span>
      </div>
    );
  }

  if (isFiltering) {
    return <span>{t('purchases.suppliersTable.emptyFiltered')}</span>;
  }

  return <span>{t('purchases.suppliersTable.empty')}</span>;
};

const SupplierContext = createContext<Context | null>(null);

export const useSupplierContext = () => {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error('useSupplier must be used within a SupplierProvider');
  }
  return context;
};

const SupplierProvider = ({
  children,
  setShowUploadPurchasesFile,
  showUploadPurchasesFile
}: ContextProps) => {
  const [showAddPurchase, setShowAddPurchase] = useState(false);

  const { total, setTotal, loading } = useTotal();

  return (
    <SupplierContext.Provider
      value={{
        setShowUploadPurchasesFile,
        showUploadPurchasesFile,
        setShowAddPurchase,
        showAddPurchase,
        total,
        totalLoading: loading,
        setTotal
      }}>
      {children}
    </SupplierContext.Provider>
  );
};

type MainProps = {
  children?: React.ReactNode;
};

const SuppliersManagementMain = ({ children }: MainProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedOrganization = useSelectedOrganization();

  const setNotification = useContext(NotificationContext);

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [showInviteSuppliers, setShowInviteSuppliers] = useState(false);
  const [supplierToUpdate, setSupplierToUpdate] = useState<IPurchaseSupplierFrontend | null>(null);
  const isUploading = usePurchasesLoader();

  const { showAddPurchase, setShowAddPurchase, setShowUploadPurchasesFile, setTotal } =
    useSupplierContext();

  const { data, loading, firstLoading, fetchData, total, refreshData } = useGetSuppliers({
    filters
  });

  const { data: suppliersInReview } = useSuppliersInReview();

  const onCloseModal = () => {
    setShowAddPurchase(false);
    setShowUploadPurchasesFile(false);
  };

  const addPurchase = () => {
    setShowAddPurchase(false);
    setShowUploadPurchasesFile(false);
    dispatch(setNotification(t('notification.createPurchase')));
    setTotal((prev) => (prev ? prev + 1 : 1));
    refreshData();
  };

  const onClickInviteSuppliers = () => {
    setShowInviteSuppliers(true);
  };

  const { columns } = useColumns({ setSupplierToUpdate });

  const {
    columns: mockedColumns,
    data: mockedData,
    modalData,
    handleCloseModalData
  } = useMockedData();

  const isMocked =
    selectedOrganization?.company_name === 'Dcycle Demos' ||
    selectedOrganization?.company_name === 'Logística Marta';

  const columnsToRender = isMocked ? mockedColumns : columns;
  const dataToRender = isMocked ? mockedData : data;
  const totalToRender = isMocked ? mockedData.length : total;
  const fetchDataToRender = isMocked ? () => new Promise<void>(() => null) : fetchData;

  const infiniteListData = isUploading ? [] : dataToRender;
  return (
    <>
      {children}
      <Filters.Root setFilters={setFilters} setOpen={setOpen} open={open} filters={filters}>
        <Filters.Menu>
          <FilterSection.Multiple
            type='in'
            field='status'
            title={t('purchases.suppliersTable.filters.status')}
            options={Object.values(PURCHASE_STATUS).map((status) => ({
              label: (
                <PurchasesStatusTag
                  status={status}
                  text={`purchases.suppliersTable.tags.${status}`}
                />
              ),
              value: status
            }))}
          />
        </Filters.Menu>
        <Modal show={showAddPurchase} onClose={onCloseModal} width='600px' maxWidth='600px'>
          <AddPurchase addPurchase={addPurchase} />
        </Modal>
        <Modal
          show={showInviteSuppliers}
          onClose={() => setShowInviteSuppliers(false)}
          width='700px'
          maxWidth='700px'>
          {showInviteSuppliers && <InviteSuppliersModalContent />}
        </Modal>
        <Modal
          show={Boolean(supplierToUpdate)}
          onClose={() => setSupplierToUpdate(null)}
          width='600px'
          maxWidth='600px'>
          {supplierToUpdate && (
            <SuppliersManagementEditForm data={supplierToUpdate} setData={setSupplierToUpdate} />
          )}
        </Modal>
        <InfiniteList
          fetchData={fetchDataToRender}
          header={
            <>
              <div className='flex gap-x-2'>
                <FilterText
                  field='business_name'
                  type='il'
                  placeholder={t('purchases.suppliersTable.filters.placeholders.businessName')}
                />

                <FilterHandlers blacklistedFilters={{ all: ['business_name'] }} />
              </div>

              <div className='flex gap-x-4 items-center'>
                <Reviewing.Root isReviewing={!isUploading && Boolean(suppliersInReview)}>
                  <Reviewing.Tooltip text={t('purchases.suppliersTable.reviewingTooltip')}>
                    <Reviewing.Main
                      title={t('purchases.suppliersTable.reviewing', { count: suppliersInReview })}
                      setFilters={setFilters}
                    />
                  </Reviewing.Tooltip>
                </Reviewing.Root>

                <Button
                  size='small'
                  lookAndFeel='primary'
                  text={t('purchases.suppliersTable.inviteSuppliers')}
                  onClick={onClickInviteSuppliers}
                  iconNode={<Icon icon='link' color='white' size={ICON_SIZE_MAP.SMALL} />}
                  style={{ whiteSpace: 'nowrap' }}
                />

                <TotalLegend
                  total={totalToRender}
                  loading={loading}
                  i18key='purchases.suppliersTable'
                />
              </div>
            </>
          }
          total={totalToRender}
          firstLoading={firstLoading}
          columns={columnsToRender}
          data={infiniteListData}
          loading={loading}
          emptyText={<EmptyText isUploading={isUploading} isFiltering={Boolean(filters.length)} />}
        />
      </Filters.Root>
      <Modal show={!!modalData} onClose={handleCloseModalData} maxWidth='650px' width='650px'>
        <ModalSupplier onClose={handleCloseModalData} modalData={modalData} />
      </Modal>
    </>
  );
};

export const SuppliersManagement = {
  Main: SuppliersManagementMain,
  Buttons,
  Context: SupplierProvider
};
