import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import controller from '../../../../../../environments/controller';
import { LastPeriod } from '../../../../../../types/entities/employee';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import Line from '../stepSummary/Line';

type Props = {
  lastPeriod: LastPeriod;
};

export const LastPeriodSummary = ({ lastPeriod }: Props) => {
  const { startDate, endDate } = useParams();
  const { t } = useTranslation();
  const { handleInItinere, handleNewAnswer } = useContext(EmployeeFormContext);
  const environment = controller();

  const handleClickSubmit = async () => {
    if (startDate && endDate) {
      lastPeriod.start_date = startDate;
      lastPeriod.end_date = endDate;
    }

    const response = await fetch(
      `${environment.backendUrl}/webhook/employees/form/${lastPeriod.employee_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
        },
        body: JSON.stringify(lastPeriod)
      }
    );

    if (response.status === 200) {
      handleNewAnswer && handleNewAnswer();
    }
  };

  return (
    <>
      <div className='summary-steps-container'>
        {lastPeriod.updated_at && (
          <Line
            title={t('employeeForm.summaryData.dateTitle')}
            text={`${convertDateBackToFront(lastPeriod.start_date)} - ${convertDateBackToFront(
              lastPeriod.end_date
            )}`}
          />
        )}
        {lastPeriod.transport_type === 'telecommuting' ? (
          <Line
            title={t('employeeForm.summaryData.workModelTitle')}
            text={t(`employees.${lastPeriod.transport_type}`)}
          />
        ) : (
          <>
            <Line
              title={t('employeeForm.summaryData.transportTitle')}
              text={t(`employees.${lastPeriod.transport_type}`)}
            />
            <Line
              title={t('employeeForm.summaryData.workModelTitle')}
              text={t(`employeeForm.goToOffice`)}
            />
          </>
        )}

        {lastPeriod.weekly_travels.length !== 0 && (
          <Line
            title={t('employeeForm.summaryData.weeklyTravelsTitle')}
            text={String(lastPeriod.weekly_travels.length)}
          />
        )}
        {lastPeriod.daily_trips !== 0 && (
          <Line
            title={t('employeeForm.summaryData.dailyTripsTitle')}
            text={String(lastPeriod.daily_trips)}
          />
        )}
        {lastPeriod.total_km !== 0 && (
          <Line
            title={t('employeeForm.summaryData.totalKmTitle')}
            text={String(lastPeriod.total_km)}
          />
        )}
      </div>
      <div className='employee-form-button-container '>
        <Button
          lookAndFeel={'primary'}
          size='small'
          onClick={handleClickSubmit}
          text={t('employeeForm.samePeriod')}
        />
        <Button
          lookAndFeel={'secondary'}
          size='small'
          onClick={handleInItinere}
          text={t('employeeForm.changePeriod')}
        />
      </div>
    </>
  );
};
