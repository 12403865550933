export const INVALID_COUNTRIES_FOR_CURRENCIES = [
  'ES',
  'GB',
  'LV',
  'MT',
  'IS',
  'LI',
  'ME',
  'UA',
  'XK',
  'GE'
];
