import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { FormDataVehicles, VehicleFuel } from '../../../../types/entities/vehicle';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import SelectCardWrapper from '../../../ui/selectCard/SelectCardWrapper';
import useCards from '../hooks/useCards';
import useCountries from '../hooks/useCountries';
import FormElementFull from 'components/ui/formComponents/formElementFull/FormElementFull';
import StepCarouselList from 'components/ui/stepCarouselList/StepCarouselList';
import useStepCarousel from 'components/ui/stepCarouselList/useStepCarousel';
import { useNavigate } from 'react-router-dom';
import WarningWrapper from 'components/ui/warningWrapper/WarningWrapper';
import { getUniqueItemsByProperties } from 'utils/removeDuplicates';
import { useEffect, useState } from 'react';
import { ROUTES } from 'constants/routes';
import { EmissionFactorGroupType } from 'types/entities/emissionFactor';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import Icon from 'components/ui/icon/Icon';

type Props = {
  formData: FormDataVehicles;
  setFormData: (value: any) => void;
  unknownVehicles: any[];
  fuels: VehicleFuel[];
  groups: EmissionFactorGroupType[];
  edit?: boolean;
};
const FormCharacteristics = ({
  formData,
  setFormData,
  unknownVehicles,
  fuels,
  groups,
  edit
}: Props) => {
  const { t } = useTranslation();

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [showVehicleFuelDisclaimer, setShowVehicleFuelDisclaimer] = useState(false);
  const [showAdblueDisclaimer, setShowAdblueDisclaimer] = useState(false);
  const [groupSelected, setGroupSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });
  const [showRecalculateDisclaimer, setShowRecalculateDisclaimer] = useState(false);

  const countriesOptions = useCountries();

  const cards = useCards();

  const navigate = useNavigate();

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: [
      {
        id: 'default',
        text: t('facilityDetail.default')
      },
      {
        id: 'custom',
        text: t('facilityDetail.custom')
      }
    ]
  });
  useEffect(() => {
    const foundFuel = fuels.find((elem) => elem.id === formData.fuel.id);
    if (foundFuel?.fuel === 'adblue') {
      setShowAdblueDisclaimer(true);
    } else {
      setShowAdblueDisclaimer(false);
    }
    if (foundFuel && foundFuel.units.length === 1 && foundFuel.units[0].name === 'litre_(l)') {
      setShowVehicleFuelDisclaimer(true);
    } else {
      setShowVehicleFuelDisclaimer(false);
    }

    if (foundFuel) {
      setFormData((prev: any) => ({
        ...prev,
        emissionFactor: ''
      }));
      setGroupSelected({
        id: '',
        name: ''
      });
    }
  }, [formData.fuel.id]);

  useEffect(() => {
    if (formData.emissionFactor && groups.length > 0) {
      handleSelect('custom');
      const foundCustomEF = groups.find((group) => group.group_id === formData.emissionFactor);
      if (foundCustomEF) {
        setGroupSelected({
          id: foundCustomEF.group_id,
          name: foundCustomEF.group_name
        });
        setFormData((prev: any) => ({
          ...prev,
          fuel: { id: '', name: '' }
        }));
      }
    }
  }, [formData.emissionFactor, JSON.stringify(groups)]);

  const unknownVehiclesMapped = unknownVehicles.map((elem: { type: string }) => {
    return {
      id: elem.type,
      name: t(`vehicles.${elem.type}`)
    };
  });

  const unknownVehiclesFiltered = getUniqueItemsByProperties(unknownVehiclesMapped, ['id']);

  const onChangeUnknownType = async (value: SelectOptionFormat) => {
    setFormData((prev: any) => ({
      ...prev,
      unknownType: value,
      fuel: { id: '', name: '' },
      errors: prev.errors.filter((elem: ErrorType) => elem.error !== 'unknownType')
    }));
  };

  const onClickCard = (id: string) => {
    setFormData((prev: any) => ({
      ...prev,
      type: id,
      errors: prev.errors.filter((elem: ErrorType) => elem.error !== 'type')
    }));
  };

  const onSelectGroup = (newGroup: SelectOptionFormat) => {
    setGroupSelected(newGroup);
    const groupToGet = groups.find((group) => group.group_id === newGroup.id);
    onChangeValue('emissionFactor')(groupToGet?.group_id ?? '');
    if (edit) {
      setShowRecalculateDisclaimer(true);
    }
  };

  const renderByStep = () => {
    if (stepSelected?.id === 'default') {
      return (
        <FormSelect
          icon={'/images/icons/plug.svg'}
          placeholder={t('vehicles.fuelPlaceholder')}
          label={t('vehicles.fuel')}
          value={formData.fuel}
          onChange={(value: SelectOptionFormat) => {
            onChangeValue('fuel')(value);
            if (edit) {
              setShowRecalculateDisclaimer(true);
            }
          }}
          options={
            fuels
              ? fuels.map((elem) => {
                  return {
                    id: elem.id,
                    name: t(`vehicle_fuels.${elem.fuel}`)
                  };
                })
              : []
          }
          error={formData.errors.find((elem) => elem.error === 'fuel')}
        />
      );
    }
    return (
      <FormSelect
        label={
          <span>
            {t('facilityDetail.emissionFactor')}{' '}
            <a
              onClick={() => navigate(ROUTES.CUSTOM_EMISSION_FACTORS)}
              className='highlight-text-color pointer'>
              {t('facilityDetail.emissionFactorHere')}
            </a>
          </span>
        }
        icon={'/images/icons/fire50.svg'}
        placeholder={t('facilityDetail.selectGroup')}
        options={groups?.map((group) => ({
          id: group.group_id,
          name: group.group_name
        }))}
        value={groupSelected}
        onChange={onSelectGroup}
        error={formData.errors.find((error) => error.error === 'emissionFactor')}
      />
    );
  };

  return (
    <>
      <div className='vehicle-type'>
        <h3 className='body1-bold-font'>{t('vehicles.vehicleOwnership')}</h3>
        <SelectCardWrapper
          cards={cards}
          onClickCard={onClickCard}
          error={formData.errors.find((elem) => elem.error === 'type')}
          type={
            cards.find((elem) => elem.id === formData.type) ?? {
              id: '',
              icon: '',
              title: ''
            }
          }
        />
      </div>
      <FormWrapper>
        <FormElementFull>
          <div className='type-selector'>
            <span className='input-label-font on-light-text-color '>
              {t('facilityDetail.selectType')}
              {': '}
            </span>
            <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'small'} />
          </div>
        </FormElementFull>
        <FormText
          icon={'/images/icons/person.svg'}
          label={t('vehicles.name')}
          placeholder={t('vehicles.namePlaceholder')}
          value={formData.name}
          onChange={onChangeValue('name')}
        />
        <FormText
          icon={'/images/icons/star.svg'}
          label={t('vehicles.carRegistrationOptional')}
          placeholder={t('vehicles.carRegistrationPlaceholder')}
          value={formData.carRegistration}
          onChange={onChangeValue('carRegistration')}
          error={formData.errors.find((elem) => elem.error === 'carRegistration')}
        />
        <FormSelect
          icon={'/images/icons/catalog.svg'}
          placeholder={t('vehicles.vehicleTypePlaceholder')}
          label={t('templates.vehicles.vehicle_use')}
          value={formData.vehicleType}
          onChange={onChangeValue('vehicleType')}
          options={[
            {
              id: 'passenger',
              name: t('vehicles.passenger')
            },
            {
              id: 'freight',
              name: t('vehicles.freight')
            }
          ]}
          error={formData.errors.find((elem) => elem.error === 'vehicleType')}
        />
        <FormSelect
          icon={'/images/icons/pin.svg'}
          placeholder={t('vehicles.selectCountry')}
          label={t('vehicles.country')}
          value={formData.country}
          onChange={onChangeValue('country')}
          options={countriesOptions}
          error={formData.errors.find((elem) => elem.error === 'country')}
          sort={false}
        />
        <FormSelect
          icon={'/images/icons/tesla.svg'}
          placeholder={t('vehicles.typePlaceholder')}
          label={t('vehicles.type')}
          value={formData.unknownType}
          onChange={onChangeUnknownType}
          options={unknownVehiclesFiltered}
          error={formData.errors.find((elem) => elem.error === 'unknownType')}
        />
        {renderByStep()}
        <div className='flex flex-col gap-1'>
          {showRecalculateDisclaimer && (
            <WarningWrapper
              style={{
                marginTop: '1rem',
                padding: '0.5rem',
                justifyContent: 'flex-start',
                width: 'fit-content',
                gap: '1rem'
              }}>
              <Icon icon='warning' color='warning' />
              <span className='font-12'>{t('vehicles.editVehicleDisclaimer')}</span>
            </WarningWrapper>
          )}
          {showVehicleFuelDisclaimer && (
            <WarningWrapper
              style={{
                marginTop: '1rem',
                padding: '0.5rem',
                justifyContent: 'flex-start',
                width: 'fit-content',
                gap: '1rem'
              }}>
              <Icon icon='warning' color='warning' />
              <span className='font-12'>{t('vehicles.fuelUnitDisclaimer')}</span>
            </WarningWrapper>
          )}
          {showAdblueDisclaimer && (
            <BlueWrapper
              style={{
                marginTop: '1rem',
                padding: '0.5rem',
                justifyContent: 'flex-start',
                width: 'fit-content',
                gap: '1rem'
              }}>
              <Icon icon='information' color='blocked' />
              <span className='font-12'>{t('vehicles.adBlueDisclaimer')}</span>
            </BlueWrapper>
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default FormCharacteristics;
