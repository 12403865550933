import { useLocation } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import { FrameworkDatapoint } from './DatapointListRenderer';
import { useContext } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useBaseData, useCSRDOutputData, useGHGOutputData } from 'customHooks/api/useSolOrgData';
import { UserContext } from 'context/userContext';
import { BaseData } from 'services/api/sotOrganizationData';

const OutputDataSource = () => {
  const { t, i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  const sector = foundOrganization?.sector;
  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];
  const organizationId = user?.selectedOrganization;
  const datapoint: FrameworkDatapoint = state?.datapoint;
  const getTranslatedName = (data: { name_es: string; name_en: string; name_pt: string }) => {
    switch (i18n.language) {
      case 'es':
        return data.name_es;
      case 'pt':
        return data.name_pt;
      default:
        return data.name_en;
    }
  };

  const queryprops = {
    outputDataId: datapoint.id,
    sector: sector || '',
    year: year || '',
    organizationId: organizationId || ''
  };

  const { baseData, isLoading, error } = useBaseData(queryprops);

  const baseDataRenderer = (baseDataArray: BaseData[]) => (
    <div className='flex-col gap-4'>
      {baseDataArray.map((baseDataItem, index) => (
        <span key={index} className='font-14 semibold-font'>
          {getTranslatedName(baseDataItem)}
        </span>
      ))}
    </div>
  );
  const isGHGProtocol = pathname.includes('ghg_protocol');
  const isCSRD = pathname.includes('csrd');

  if (isLoading) {
    return <LoaderCard style={{ gridColumn: '1 / -1' }} />;
  }

  if (error) {
    return (
      <div className='error-message'>{t('error.fetchingData', { message: error.message })}</div>
    );
  }

  return (
    <div className='framework-dashboard__body'>
      <CardPanel className='p-6 gap-2'>
        <div className='framework-scope__iconTitle flex-col gap-2 justify-center'>
          {isGHGProtocol && (
            <span className='font-12 regular-font'>
              {datapoint.sources?.length
                ? datapoint.sources
                    .map((source) => t(`controlPanel.frameworkProgress.sources.${source}`))
                    .join(', ')
                : null}
            </span>
          )}
          {isCSRD && (
            <span className='font-10 regular-font text-neutral-gray-20'>
              {datapoint.datapoint_id}
            </span>
          )}
          <h2 className='font-20 regular-font'>{getTranslatedName(datapoint)}</h2>
        </div>
        {baseData && baseDataRenderer(baseData)}
      </CardPanel>
    </div>
  );
};

export default OutputDataSource;
