import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { adaptEsgMetricsBackToFront } from '../../../../adapters/esgMetrics';
import { esgMetrics } from '../../../../services/api/total_impacts';
import { EsgMetricBackend } from '../../../../types/entities/esgMetrics';

type Props = {
  intensityMetric?: string | null;
  dateRanges: string[][];
  mode: string;
  enabled?: boolean;
};

export const useGetEsgMetrics = ({ intensityMetric, dateRanges, mode, enabled }: Props) => {
  const { t } = useTranslation();
  return useQuery({
    queryKey: ['esgMetrics', dateRanges, mode, intensityMetric],
    queryFn: async () => esgMetrics(intensityMetric || null, dateRanges, mode),
    select: (data: EsgMetricBackend[]) => adaptEsgMetricsBackToFront(data, t),
    enabled
  });
};
