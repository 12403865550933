import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import { createVehicle } from '../../../../services/api/vehicle';
import { FormDataVehicles, Vehicle, VehicleFuel } from '../../../../types/entities/vehicle';
import FormVehicle from '../formVehicle/FormVehicle';

type Props = {
  addVehicle: (value: Vehicle) => void;
};
const CreateVehicle = ({ addVehicle }: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const [error, setError] = useState<ErrorType>();

  const handleAddVehicle = async (
    formData: FormDataVehicles,
    unknownVehicles: any[],
    fuels: VehicleFuel[]
  ) => {
    let unknownVehicleId = null;
    const foundVehicle = unknownVehicles.find(
      (vehicle) => vehicle.type === formData.unknownType.id
    );
    unknownVehicleId = foundVehicle ? foundVehicle.id : null;

    let nameValue = formData.name;
    if (!nameValue) {
      nameValue = formData.unknownType.id;
    }

    const vehicle = {
      unknown_vehicle_id: unknownVehicleId,
      vehicle_fuel_id: formData.fuel.id || null,
      type: formData.vehicleType.id ? formData.vehicleType.id : '',
      name: nameValue,
      ownership: formData.type,
      license_plate: formData.carRegistration,
      is_known: false,
      organization_id: user?.selectedOrganization ?? '',
      country: formData.country.id,
      custom_emission_factor_id: formData.emissionFactor ?? null
    };

    const newVehicle = await createVehicle(vehicle);
    if (typeof newVehicle === 'string' && newVehicle.includes('Vehicle with license plate')) {
      setError({
        error: 'carRegistration',
        description: t('error.licensePlateAlreadyExists')
      });
      return;
    }

    if (addVehicle && newVehicle) {
      const foundFuel = fuels?.find((fuel) => fuel.id === newVehicle.vehicle_fuel_id);
      if (foundFuel) {
        newVehicle.vehicle_fuel_id = foundFuel?.id;
        newVehicle.vehicle_fuel = foundFuel.fuel;
      }
      addVehicle({
        ...newVehicle,
        unknown_vehicle_type: formData.unknownType.id,
        fuel: foundFuel?.fuel
      });
    }
  };
  return (
    <FormVehicle
      onSubmit={handleAddVehicle}
      title={t('vehicles.createNewVehicle')}
      description={t('vehicles.createNewVehicleDescription')}
      errorRequest={error}
      edit={false}
    />
  );
};

export default CreateVehicle;
