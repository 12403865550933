import React from 'react';
import { StatusTag } from '../../../../ui/statusTag';
import { Status } from '../../../../../types/utilsEnums/status';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import { FILTER_IN_REVIEW } from '../constants';

type ReviewContextType = {
  isReviewing: boolean;
};

const ReviewContext = React.createContext<ReviewContextType | null>(null);

const Root: React.FC<ReviewContextType> = ({ children, isReviewing }) => {
  return <ReviewContext.Provider value={{ isReviewing }}>{children}</ReviewContext.Provider>;
};

const useReviewing = () => {
  const context = React.useContext(ReviewContext);

  if (context === null) {
    throw new Error('useReviewing must be used within a Root');
  }

  return context;
};

type Props = {
  title: string;
  setFilters: (filters: IFilter[]) => void;
};

const Main: React.FC<Props> = ({ title, setFilters }) => {
  const { isReviewing } = useReviewing();

  if (!isReviewing) {
    return null;
  }

  const onClick = () => {
    setFilters([FILTER_IN_REVIEW]);
  };

  return (
    <button className='reset' onClick={onClick}>
      <StatusTag
        status={Status.IN_PROGRESS}
        text={title}
        style={{ justifyContent: 'center', whiteSpace: 'nowrap' }}
      />
    </button>
  );
};

type TooltipProps = {
  text: string;
};

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const { isReviewing } = useReviewing();

  if (!isReviewing) {
    return null;
  }

  return (
    <TooltipWrapper text={text} position='top'>
      {children}
    </TooltipWrapper>
  );
};

export const Reviewing = {
  Root,
  Main,
  Tooltip
};
