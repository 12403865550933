import controller from 'environments/controller';
import moment from 'moment';
import type { BasicEmployee } from 'types/entities/employee';
import type { EmployeePeriodFormType } from 'types/entities/employeePeriod';
import { CommutingType } from 'types/entitiesEnums/employeePeriod';
import apiFetch from 'utils/apiFetch';
import { getUrl } from 'utils/url';

export const getEmployees = async (urlOptions?: URLOptions) => {
  try {
    const response = await apiFetch('GET', getUrl('/employees', urlOptions));

    return response.data as Promise<Pagination<any>>;
  } catch (error) {
    return undefined;
  }
};

export const resendPendingEmployeesForm = async (
  organization_id: string,
  lang: string,
  start_date: string,
  end_date: string
) => {
  try {
    const response = await apiFetch(
      'POST',
      '/employees/pending',
      {
        lang,
        start_date,
        end_date
      },
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data === 'MAX_EMAILS_EXCEEDED') {
      return 'MAX_EMAILS_EXCEEDED';
    }
    return null;
  }
};

export const resendToEmployeesForm = async (
  organization_id: string,
  lang: string,
  emails: string[],
  start_date: string,
  end_date: string
) => {
  try {
    const response = await apiFetch(
      'POST',
      '/employees/form',
      {
        lang,
        emails,
        organization_id,
        start_date,
        end_date
      },
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

type EmployeePost = {
  email?: string;
  name?: string;
  status: string;
  organization_id: string;
};

export const postEmployees = async (formData: BasicEmployee, organization_id: string) => {
  try {
    const data: EmployeePost = {
      email: formData.email || undefined,
      name: formData.name || undefined,
      status: 'uploaded',
      organization_id
    };

    const response = await apiFetch('POST', '/employees', data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

type EmployeeFormQrType = {
  name?: string;
  employee_period: {
    transport_type?: string;
    vehicle_size?: string;
    fuel_type?: string;
    renewable_energy?: string;
    carpool?: boolean;
    total_km?: number;
    weekly_travels?: number[];
    daily_trips: number;
    situation?: string;
    origin?: string;
    destination?: string;
    commuting_type?: string;
    start_date?: string;
    end_date?: string;
  };
};

const environment = controller();

export const postEmployeesFormQr = async (
  formData: EmployeePeriodFormType,
  organization_id: string
) => {
  try {
    const data: EmployeeFormQrType = {
      name: formData.name,
      employee_period: {
        transport_type: formData.telecommuting.id === 'yes' ? undefined : formData.transport.id,
        vehicle_size: formData.size,
        fuel_type: formData.fuelType.id,
        renewable_energy: formData?.renewableEnergy ?? undefined,
        carpool: formData.carpooling.id === 'yes' ? true : false,
        total_km: Number(formData.totalKm),
        weekly_travels: Array.from({ length: Number(formData.weeklyTravels) }, (_, index) => index),
        daily_trips: Number(formData.dailyTrips),
        situation: 'active',
        commuting_type: CommutingType.IN_ITINERE,
        start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: formData.endDate
          ? moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : undefined
      }
    };

    const response = await fetch(
      `${environment.backendUrl}/webhook/employees/form/qr/${organization_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
        },
        body: JSON.stringify(data)
      }
    );

    return response;
  } catch (error) {
    return null;
  }
};

type EmployeeFormType = {
  transport_type?: string;
  vehicle_size?: string;
  fuel_type?: string;
  renewable_energy?: string;
  carpool?: boolean;
  total_km?: number;
  weekly_travels?: number[];
  daily_trips: number;
  situation?: string;
  commuting_type?: string;
  start_date?: string;
  end_date?: string;
};

export const postEmployeesForm = async (
  formData: EmployeePeriodFormType,
  id: string,
  type: CommutingType
) => {
  try {
    const data: EmployeeFormType = {
      transport_type: formData.telecommuting.id === 'yes' ? undefined : formData.transport.id,
      vehicle_size: formData.size || undefined,
      fuel_type: formData.fuelType.id || undefined,
      renewable_energy: formData?.renewableEnergy ?? undefined,
      carpool: formData.carpooling.id === 'yes' ? true : false,
      total_km: Number(formData.totalKm),
      weekly_travels: Array.from({ length: Number(formData.weeklyTravels) }, (_, index) => index),
      daily_trips: Number(formData.dailyTrips),
      situation: 'active',
      commuting_type: type,
      start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: formData.endDate
        ? moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined
    };

    const response = await fetch(`${environment.backendUrl}/webhook/employees/form/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
      },
      body: JSON.stringify(data)
    });

    return response;
  } catch (error) {
    return null;
  }
};

type EmployeePatch = {
  email?: string;
  name?: string;
  status: string;
  organization_id: string;
};

export const patchEmployees = async (
  id: string,
  formData: BasicEmployee,
  organization_id: string
) => {
  try {
    const data: EmployeePatch = {
      ...formData,
      status: 'uploaded',
      organization_id
    };

    const response = await apiFetch('PATCH', `/employees/${id}`, data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
