import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useEffect, useState } from 'react';
import { getGroups } from 'services/api/customEFOld';
import { EmissionFactorGroupType } from 'types/entities/emissionFactor';
import { getUnknownVehicles, getVehicleFuels } from '../../../../services/api/vehicle';
import { VehicleFuel } from '../../../../types/entities/vehicle';

type Props = {
  country: string;
  unknownType: string;
};
const useGetDataForm = ({ country, unknownType }: Props) => {
  const [unknownVehicles, setUnknownVehicles] = useState<any[]>([]);
  const [fuels, setFuels] = useState<VehicleFuel[] | null>(null);
  const [groups, setGroups] = useState<EmissionFactorGroupType[]>([]);

  const selectedOrganization = useSelectedOrganization();
  const fetchData = async () => {
    let data = null;
    data = await getUnknownVehicles();
    if (data) {
      setUnknownVehicles(data);
    }

    if (unknownType && country) {
      // For now only Spain
      data = await getVehicleFuels('ES', unknownType);
      if (data) {
        setFuels(data);
      }
    }

    data = await getGroups(selectedOrganization?.id ?? '');

    if (data?.response?.status >= 400) return;
    setGroups(data.items.filter((elem: EmissionFactorGroupType) => elem.category === 'vehicles'));
  };

  useEffect(() => {
    fetchData();
  }, [country, unknownType]);

  return { unknownVehicles, fuels, groups };
};

export default useGetDataForm;
