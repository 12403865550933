import { useFiltersContext } from '../Filters';

type Params = {
  field: string;
  type: MultipleFilterType;
};
export const useDefaultOnChangeSection = ({ field, type }: Params) => {
  const { setFilters } = useFiltersContext();

  const onChange = (item: string) => (value: boolean) => {
    if (!value) {
      setFilters((prev) => {
        const newFilters = [...prev];

        const filterIndex = newFilters.findIndex((filter) => filter.field === field);

        const filter = { ...newFilters[filterIndex] };

        if (!Array.isArray(filter.value)) {
          throw new Error('Filter value must be an array when used on multiple filter component');
        }

        filter.value = filter.value.filter((value) => value !== item);

        if (!filter.value.length) {
          return newFilters.filter((filter) => filter.field !== field);
        }

        newFilters[filterIndex] = filter;

        return newFilters;
      });

      return;
    }

    setFilters((prev) => {
      const newFilters = [...prev];

      const filterIndex = newFilters.findIndex((filter) => filter.field === field);

      if (filterIndex === -1) {
        newFilters.push({
          field,
          type,
          value: [item]
        });

        return newFilters;
      }

      const filter = { ...newFilters[filterIndex] };

      if (!Array.isArray(filter.value)) {
        throw new Error('Filter value must be an array when used on the sidebar');
      }

      if (filter.value.includes(item)) {
        return newFilters;
      }

      filter.value.push(item);

      newFilters[filterIndex] = filter;

      return newFilters;
    });
  };

  return { onChange };
};
