import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { createFacility } from '../../../../../services/api/facility';
import { createHubSubcontracted } from '../../../../../services/api/hubs';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import BlueWrapper from '../../../../ui/blueWrapper/BlueWrapper';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import Icon from '../../../../ui/icon/Icon';
import CreateHubForm from '../hubForm/CreateHubForm';

type Props = {
  handleAddHub: (value: IBackendHub) => void;
};
const CreateHub = ({ handleAddHub }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'hubs' });
  const [formData, setFormData] = useState<FormDataHubs>({
    type: '',
    name: '',
    hubCategory: {
      id: '',
      name: ''
    },
    address: '',
    categories: [
      { id: 'heat', name: t('facilityDetail.combustion') },
      { id: 'electricity', name: t('facilityDetail.electricity') },
      { id: 'recharge', name: t('facilityDetail.recharge') },
      { id: 'water', name: t('facilityDetail.water') },
      { id: 'wastes', name: t('facilityDetail.waste') }
    ],
    isSuperCharger: false,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const selectedOrganization = useSelectedOrganization();

  const handleErrors = () => {
    const optionalFields: string[] = [];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleSubmit = async () => {
    const newErrors = handleErrors();
    if (newErrors) return;
    setLoadingButton(true);

    let hubCreated = null;

    const body: any = {
      name: formData.name,
      type: formData.type,
      address: formData.address,
      organization_id: selectedOrganization ? selectedOrganization?.id : '',
      category: undefined,
      supercharger: formData.isSuperCharger ?? false
    };

    // select just the ids of the categories
    body.categories = formData.categories.map((elem) => elem.id);

    // if the hub is a supercharger, select only the electricity category
    if (formData.isSuperCharger) {
      body.categories = formData.categories
        .filter((elem) => elem.id === 'electricity')
        .map((elem) => elem.id);
    }

    if (formData.type === 'subcontracted') {
      body.category = formData.hubCategory.id !== '' ? formData.hubCategory.id : undefined;
      hubCreated = await createHubSubcontracted(body);
      if (hubCreated?.error?.response?.status === 409) {
        hubCreated = null;
        setFormData((prev) => ({
          ...prev,
          errors: prev.errors.concat({
            error: 'name',
            description: t('hubForm.duplicateId')
          })
        }));
      }
      if (hubCreated?.error?.response?.status === 400) {
        hubCreated = null;
        setFormData((prev) => ({
          ...prev,
          errors: prev.errors.concat({
            error: 'address',
            description: t('hubForm.moreAccurateAddress')
          })
        }));
      }
    } else {
      body.hub_category = formData.hubCategory.id !== '' ? formData.hubCategory.id : undefined;
      //  Create hub as a facility with hub_category
      hubCreated = await createFacility(body);
      if (hubCreated?.error?.response?.status === 409) {
        hubCreated = null;
        setFormData((prev) => ({
          ...prev,
          errors: prev.errors.concat({
            error: 'name',
            description: t('hubForm.duplicateId')
          })
        }));
      }
      if (hubCreated?.error?.response?.status === 400) {
        hubCreated = null;
        setFormData((prev) => ({
          ...prev,
          errors: prev.errors.concat({
            error: 'address',
            description: t('hubForm.moreAccurateAddress')
          })
        }));
      }
    }
    console.log(hubCreated);
    setLoadingButton(false);
    if (!hubCreated) return;

    handleAddHub(hubCreated);
  };

  return (
    <div className='create-hub'>
      <FormHeader title={t('createHub.title')} description={t('createHub.description')} />
      <BlueWrapper style={{ padding: '1em', justifyContent: 'space-between' }}>
        <>
          <Icon icon='multi_star' color='blocked' />
          <span style={{ width: '95%' }}>{t('hubForm.disclaimerSwitch')}</span>
        </>
      </BlueWrapper>
      <CreateHubForm formData={formData} setFormData={setFormData} />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          onClick={handleSubmit}
          loading={loadingButton}
          text={t('saveAndClose')}
        />
      </FormButtonSection>
    </div>
  );
};

export default CreateHub;
