import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Crumb from './Crumb';
import './styles.scss';

type CustomCrumb = {
  key: string; // value in the url, for example: the id of an installation
  value: string; // value that you want to appear in the breadcrumb
};
type Props = {
  crumbsReplace?: CustomCrumb[];
  excludeTranslationIndexes?: number[];
};

function Breadcrumb({ crumbsReplace = [], excludeTranslationIndexes = [] }: Props) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // Split the input string by '/' and filter out empty strings
  const parts = pathname.split('/').filter(Boolean);
  const finalCrumbs: CustomCrumb[] = [];

  // If the map is empty, just return the parts as key-value pairs (key = value)
  if (crumbsReplace.length === 0) {
    finalCrumbs.push(...parts.map((part) => ({ key: part, value: decodeURIComponent(part) })));
  } else {
    // Iterate over the parts and handle the mapping
    let i = 0;
    while (i < parts.length) {
      // Try to find a matching key in the crumbsReplace
      const matchedItem = crumbsReplace.find(
        (item) => parts.slice(i, i + item.key.split('/').length).join('/') === item.key
      );

      if (matchedItem) {
        // If a match is found, use the mapped value and skip the matching segments
        finalCrumbs.push({ key: matchedItem.key, value: decodeURIComponent(matchedItem.value) });
        i += matchedItem.key.split('/').length;
      } else {
        // If no match is found, treat the part as key = value
        finalCrumbs.push({ key: parts[i], value: decodeURIComponent(parts[i]) });
        i++;
      }
    }
  }
  const crumbs = finalCrumbs.map((crumb) => crumb.key);
  return (
    <div className='breadcrumb'>
      {finalCrumbs.map((crumb, index) => {
        const customCrumbFound = crumbsReplace.find((customCrumb) => customCrumb.key === crumb.key);
        const shouldSkipTranslation = excludeTranslationIndexes.includes(index);
        if (customCrumbFound) {
          return (
            <Crumb
              index={index}
              key={`crumb-${index}`}
              crumbs={crumbs}
              crumb={`${customCrumbFound.value.substring(0, 30)}${
                customCrumbFound.value.length > 40 ? '...' : ''
              }`}
            />
          );
        }
        return (
          <Crumb
            index={index}
            crumbs={crumbs}
            crumb={shouldSkipTranslation ? crumb.value : t(`breadcrumb.${crumb.value}`)}
            key={`crumb-${index}`}
          />
        );
      })}
    </div>
  );
}

type CustomBreadcrumbProps = {
  breadCrumbs: CustomCrumb[];
  handleCrumbClick: (key: string) => void;
};

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({ breadCrumbs, handleCrumbClick }) => {
  return (
    <div className='breadcrumb'>
      {breadCrumbs.map((crumb, index) => {
        return (
          <Crumb.Custom
            index={index}
            crumbs={breadCrumbs.map((breadCrumb) => breadCrumb.key)}
            crumb={`${crumb.value.substring(0, 30)}${crumb.value.length > 40 ? '...' : ''}`}
            key={`crumb-${index}`}
            handleCrumbClick={handleCrumbClick}
          />
        );
      })}
    </div>
  );
};

Breadcrumb.Custom = CustomBreadcrumb;

export default Breadcrumb;
