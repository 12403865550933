import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteVehicle } from '../../../../services/api/vehicle';
import { User } from '../../../../types/entities/user';
import Button from '../../../ui/button/Button';
import './styles.scss';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  removeVehicle: (id: string) => void;
  vehicleToDelete: string;
  user: User | null;
};
function DeleteVehicle({ user, vehicleToDelete, removeVehicle }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteVehicle();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteVehicle = async () => {
    if (!user?.selectedOrganization) {
      return;
    }
    setLoadingButton(true);
    await deleteVehicle(vehicleToDelete, user?.selectedOrganization);
    if (vehicleToDelete) {
      removeVehicle(vehicleToDelete);
    }
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal-vehicles'>
      <FormHeader
        title={t('vehicles.deleteVehicle')}
        description={t('vehicles.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('vehicles.delete')}
          onClick={handleDeleteVehicle}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default DeleteVehicle;
