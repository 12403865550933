import { useTranslation } from 'react-i18next';
import { PremiumFeatureModal } from '../PremiumFeatureModal';
import { ReactComponent as Iso14001IMG } from 'assets/img/iso_14001.svg';
import { KPI_14001_ARTICLE } from '../../../../constants';
import Button from 'components/ui/button/Button';
import { useDownload } from 'customHooks/useDownload';
import { downloadEsgExcel } from 'services/api/esg';
import { Mode } from 'types/entitiesEnums/esgMetrics';
import { getCurrentYear, getPreviousYear } from 'components/dashboard/dashboardEsg/utils/utils';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

export const Iso14001Modal = () => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'premiumFeatureAdvise.modalInstances.iso14001'
  });

  const flags = useFeatureFlags();

  const { download, downloading } = useDownload();

  const onClick = async () => {
    const previousYear = getPreviousYear();
    const currentYear = getCurrentYear();

    const dateRanges = [
      [`${previousYear}/01/01`, `${previousYear}/12/31`],
      [`${currentYear}/01/01`, `${currentYear}/12/31`]
    ];

    const url = downloadEsgExcel({
      dateRanges,
      mode: Mode.ABSOLUT,
      intensitymetricGroupId: '',
      lang: i18n.resolvedLanguage
    });

    await download(url, 'iso_14001.xlsx');
  };

  return (
    <PremiumFeatureModal.Layout>
      <PremiumFeatureModal.Title>
        <div className='flex gap-x-2'>
          <span>{t('title')}</span> <PremiumFeatureModal.ProTag />
        </div>
      </PremiumFeatureModal.Title>
      <PremiumFeatureModal.ImageContainer>
        <Iso14001IMG />
      </PremiumFeatureModal.ImageContainer>
      <PremiumFeatureModal.BulletPointContainer>
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.1.title')}
          description={t('bulletPoints.1.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.2.title')}
          description={t('bulletPoints.2.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.3.title')}
          description={t('bulletPoints.3.description')}
        />
      </PremiumFeatureModal.BulletPointContainer>
      <PremiumFeatureModal.Button.Container>
        <PremiumFeatureModal.Button.Schedule />
        {flags?.isDcycleDemos && (
          <Button
            lookAndFeel='secondary'
            text={t('download')}
            onClick={onClick}
            loading={downloading}
            size='medium'
          />
        )}

        {!flags?.isDcycleDemos && (
          <PremiumFeatureModal.Button.ReadMore url={KPI_14001_ARTICLE.es} />
        )}
      </PremiumFeatureModal.Button.Container>
    </PremiumFeatureModal.Layout>
  );
};
