import { setNotification } from 'actions/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const download = async (downloadURLPromise: Promise<string>, customName?: string) => {
    if (downloading) {
      return;
    }

    try {
      setDownloading(true);

      const downloadURL = await downloadURLPromise;

      const link = document.createElement('a');

      link.href = downloadURL;

      if (customName) {
        link.setAttribute('download', customName);
      }

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      dispatch(setNotification(t('notification.downloadFile')));
    } catch {
      dispatch(setNotification(t('notification.error')));
    } finally {
      setDownloading(false);
    }
  };

  return { download, downloading };
};
