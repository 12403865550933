import type { VALIDATION_FACILITIES_CATEGORIES } from 'types/entities/facility';
import apiFetch from 'utils/apiFetch';
import { getUrl } from 'utils/url';

const FACILITIES_ENDPOINT = '/data-validations/validate-facilities';

export const getFacility = async (facility_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/facilities/${facility_id}`,
      {},
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getWasteWaterFacility = async (facility_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/facilities/${facility_id}`,
      {},
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getFacilities = async (organization_id?: string, returnRequest = false) => {
  try {
    const headers = organization_id ? { 'x-organization-id': organization_id } : {};
    const request = apiFetch('GET', '/facilities', {}, headers);
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getFacilitiesPaginated = async (page: number, filters: IFilter[]) => {
  const url = getUrl('/facilities', {
    filters,
    queryParams: [
      { key: 'page', value: page },
      {
        key: 'size',
        value: 100
      }
    ]
  });

  const response = await apiFetch('GET', url);

  return response.data;
};

type FacilityBody = {
  name: string;
  type: string;
  country: string;
  organization_id: string;
  categories: string[];
  hub_category?: string;
};
export const createFacility = async (facility: FacilityBody) => {
  try {
    const response = await apiFetch('POST', '/facilities', facility);
    return response.data;
  } catch (err) {
    if (facility.type) {
      return { error: err };
    }
    return null;
  }
};

type WasteWaterFacilityBody = {
  name: string;
  type: string;
  country: string;
  address?: string;
  logistic_factor?: number;
  cups_list?: string[];
  status?: string;
  wwt_line_id: string;
  sludge_line_id: string;
  wwd_line_id: string;
  methane_burned: boolean;
  categories: string[];
};
export const createFacilityTreatment = async (facility: WasteWaterFacilityBody) => {
  try {
    const response = await apiFetch('POST', '/facilities/waste_water', facility);
    return response.data;
  } catch (err) {
    return null;
  }
};

type UpdateFacilityBody = {
  name?: string;
  type?: string;
  status?: string;
  categories?: string[];
};
export const updateFacility = async (facility_id: string, facility: UpdateFacilityBody) => {
  try {
    const response = await apiFetch('PATCH', `/facilities/${facility_id}`, facility);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteFacility = async (facility_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/facilities/${facility_id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getFacilitiesValidation = async (
  startDate: number,
  endDate: number,
  category: VALIDATION_FACILITIES_CATEGORIES,
  selectedId: [string]
) => {
  try {
    const response = await apiFetch(
      'GET',
      `${FACILITIES_ENDPOINT}?start_date=${startDate}&end_date=${endDate}&category=${category}&list_facility_ids=${selectedId.join(
        ','
      )}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};
