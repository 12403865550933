import { months } from 'constants/months';
import { numberToScientificNotation } from 'utils/numberToDecimal';

export const getRowsFromData = <T extends { month: number; co2e: number; year: number }>(
  data: T[],
  dataCo2: { x: `${string}-${string}`; y: number }[],
  lang: string
) => {
  return dataCo2.map((elem) => {
    const [yearNum, monthNum] = elem.x.split('-').map(Number);

    const month = months.find((month) => month.number === monthNum);

    const monthData = data.find((data) => data.month === monthNum && data.year === yearNum);

    const co2e = monthData?.co2e ?? 0;

    return [month?.name[lang] ?? '', numberToScientificNotation(co2e / 1000)];
  });
};
