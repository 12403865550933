import { useContext, useState } from 'react';
import CompanyEnvironmentImpact from './components/companyEnviromentImpact/CompanyEnvironmentImpact';
import './styles.scss';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import useGetData from './hooks/useGetData';
import List from './components/list/List';
import ImpactByToc from './components/impactByToc/ImpactByToc';
import YearComparisonFuel from './components/yearComparisonFuel/YearComparisonFuel';
import ImpactByCategory from './components/impactByCategory/ImpactByCategory';
import { useAmplitude } from 'customHooks/useAmplitude';
import { EVENTS } from 'constants/amplitude';

const DashboardLogistic = () => {
  useAmplitude(EVENTS.PAGE_VIEW);
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedOrganization = useSelectedOrganization();

  const { dataScopes } = useGetData({ startDate, endDate });

  if (!selectedOrganization) return null;

  return (
    <div className='dashboard__body-logistic'>
      <CompanyEnvironmentImpact
        data={dataScopes}
        organization={selectedOrganization}
        loading={loading}
      />
      <List />
      <ImpactByToc />
      <YearComparisonFuel />
      <ImpactByCategory />
    </div>
  );
};

export default DashboardLogistic;
