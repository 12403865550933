import { useContext, useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { UserContext } from 'context/userContext';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

export const useAmplitude = (event_name: string) => {
  const user = useContext(UserContext);
  const isDcycle = user?.email.includes('@dcycle.io') ? 'Internal user' : 'External user';
  const pagePath = window.location.pathname;
  const pageUrl = window.location.href;
  const organizationFound = useSelectedOrganization();
  const eventAmplitude = {
    org_id: organizationFound?.id,
    org_name: organizationFound?.company_name,
    is_internal: isDcycle,
    page_path: pagePath,
    page_url: pageUrl
  };

  useEffect(() => {
    amplitude.track(event_name, eventAmplitude);
  }, []);
};
