import controller from '../../environments/controller';
import { IPostBackendBusinessTravel } from '../../types/entities/businessTravels';
import apiFetch from '../../utils/apiFetch';

type Post = {
  value: IPostBackendBusinessTravel;
  organizationId: string;
};

export const postBusinessTravel = async ({ value, organizationId }: Post) => {
  try {
    const response = await apiFetch('POST', `/business_travels`, {
      ...value,
      organization_id: organizationId
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

type Patch = {
  id: string;
  value: IPostBackendBusinessTravel;
  organizationId: string;
};

export const patchBusinessTravel = async ({ id, value, organizationId }: Patch) => {
  try {
    const response = await apiFetch('PATCH', `/business_travels/${id}`, {
      ...value,
      organization_id: organizationId
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

type Delete = {
  id: string;
  organizationId: string;
};

export const deleteBusinessTravel = async ({ id, organizationId }: Delete) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/business_travels/${id}`,
      {},
      { 'x-organization-id': organizationId }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

type PostWebhook = {
  id: string;
  value: IPostBackendBusinessTravel;
};

export const postBusinessTravelForm = async ({ id, value }: PostWebhook) => {
  try {
    const environment = controller();

    const response = await fetch(`${environment.backendUrl}/webhook/business_travels/form/${id}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_WEBHOOK_API_KEY || ''
      },
      body: JSON.stringify({
        ...value
      })
    });
    return response.json();
  } catch (err) {
    return null;
  }
};

type BusinessTravelCsvBody = {
  file: string;
  file_name: string;
};
export const sendBusinessTravelsFileByEmail = async (
  body: BusinessTravelCsvBody,
  organization_id: string
) => {
  try {
    const response = await apiFetch('POST', `/business_travels/upload`, body, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err: any) {
    if (
      err?.response?.status === 422 &&
      err.response.data &&
      'invalid_csv_file' in err.response.data
    ) {
      return err.response.data;
    }
    return null;
  }
};

type BusinessTravelTemplateBody = {
  data: string;
  file_name: string;
};

export const getPresignedUrlBusinessTravels = async (body: BusinessTravelTemplateBody) => {
  try {
    const response = await apiFetch('POST', `/business_travels/bulk/csv`, body);
    return response.data;
  } catch (err: any) {
    if (
      err?.response?.status === 422 &&
      err.response.data &&
      'invalid_csv_file' in err.response.data
    ) {
      return err.response.data;
    }
    return null;
  }
};

export const deleteBusinessTravelFromOrganization = async (
  org_id: string,
  status: string,
  startDate?: string,
  endDate?: string
) => {
  try {
    type QueryParams = {
      status?: string;
      start_date?: string;
      end_date?: string;
    };
    const queryParams: QueryParams = {};
    if (status && status !== 'all') {
      queryParams['status'] = status;
    }
    if (startDate && endDate) {
      queryParams['start_date'] = startDate;
      queryParams['end_date'] = endDate;
    }
    const response = await apiFetch(
      'DELETE',
      `/business_travels/organizations/${org_id}`,
      null,
      {
        'x-organization-id': org_id
      },
      queryParams
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getBusinessTravelByOrgIdStatus = async (org_id: string, status = 'error') => {
  try {
    const response = await apiFetch(
      'GET',
      `/business_travels/organizations/${org_id}/template`,
      null,
      {
        'x-organization-id': org_id
      },
      { status }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export type BusinessTravelQueryParams = {
  start_date?: number;
  end_date?: number;
  description?: string;
  filters?: string;
  page: number;
  size: number;
};
export const getBusinessTravels = async (queryParams: BusinessTravelQueryParams) => {
  const response = await apiFetch('GET', `/business_travels`, null, {}, queryParams);

  return response.data;
};
