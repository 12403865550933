import { useEffect, useState } from 'react';
import { getUnits, getUnitsByCountry } from '../../../../../../../services/api/unit';
import { useFeatureFlags } from '../../../../../../../customHooks/useFeatureFlags';
import { INVALID_COUNTRIES_FOR_CURRENCIES } from 'constants/countries';

type Props = {
  type: string;
  country: string;
};
const useGetUnits = ({ type, country }: Props) => {
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const [loading, setLoading] = useState(false);

  const flags = useFeatureFlags();
  // get exactly cubic_metre_(m3) and kilowatt_hour_(kwh) (should be refactor)
  useEffect(() => {
    const getUnitWater = async () => {
      if (type !== 'water') {
        return;
      }

      const data = await getUnits({ type: 'gas' });

      if (data?.response?.status >= 400) return;

      setUnits(data.filter((elem: Unit) => elem.name === 'cubic_metre_(m3)'));
    };

    const getUnitElectricity = async () => {
      if (type !== 'electricity') {
        return;
      }

      const dataElectricity = await getUnits({ type: 'energy' });

      if (dataElectricity?.response?.status >= 400) {
        return;
      }

      const data = [...dataElectricity];

      // Call endpoint that returns units of currency given a country
      if (!INVALID_COUNTRIES_FOR_CURRENCIES.includes(country.toUpperCase())) {
        const dataCurrency = await getUnitsByCountry(country);
        if (dataCurrency && dataCurrency.id) {
          data.push(dataCurrency);
        }
      }

      const units = data.filter(
        (elem: Unit) =>
          elem.name === 'kilowatt_hour_(kwh)' ||
          (flags?.eurosInInvoices && elem.type === 'fiat_currency')
      );

      setUnits(units);
    };

    setLoading(true);
    getUnitWater();
    getUnitElectricity();
    setLoading(false);
  }, [type]);

  return { units, setUnits, loading };
};

export default useGetUnits;
