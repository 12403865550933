import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { createTransportSections } from '../../../../../services/api/transportRoutes';
import { Shipment, ShipmentForm, TransportSection } from '../../../../../types/entities/shipment';

import { User } from '../../../../../types/entities/user';

import apiFetch from '../../../../../utils/apiFetch';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { convertStringToDate } from '../../../../../utils/convertDates';
import { formatDate } from '../../../../../utils/formatDate';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormShipment from '../common/FormShipment';

type Props = {
  addShipment: (shipment: Shipment) => void;
  user: User;
};
function AddShipment({ addShipment, user }: Props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction') || 'downstream';

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());

  const [formData, setFormData] = useState<ShipmentForm>({
    name: '',
    quantity: '',
    unit: {
      id: '',
      name: ''
    },
    supplier: '',
    refrigerated: {
      id: 'no',
      name: t('shipments.no')
    },
    startDate: formatDate(today),
    frequency: {
      id: 'once',
      name: t('shipments.once')
    },
    transportSections: [
      {
        location_origin_id: '',
        location_destination_id: '',
        origin: '',
        destination: '',
        transport_type: '',
        transport_kilometers: ''
        //km
      }
    ],
    errors: [] as ErrorType[]
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleAddShipment = async () => {
    // Call api to add shipment and then add it to the list

    //meter km
    const optionalFields = ['supplier', 'name'];
    // Check if there are errors in the form
    const newErrors = checkFormErrors(formData, [], optionalFields);
    formData.transportSections.forEach((elem, index) => {
      if (!elem.transport_kilometers && (elem.destination === '' || elem.origin === '')) {
        newErrors.push({ error: `transportSectionsRoute-${index}` });
      }
      if (elem.transport_type === '') {
        newErrors.push({ error: `transportSectionsTransportType-${index}` });
      }
      if (!elem.transport_kilometers && elem.destination === elem.origin) {
        newErrors.push({
          error: `transportSectionsRoute-${index}`,
          description: t('error.originAndDestinationCannotBeTheSame')
        });
      }
    });

    if (newErrors.length > 0) {
      setFormData((prev) => ({ ...prev, errors: newErrors }));
      return;
    }
    try {
      setLoadingButton(true);

      const transportTypeList = formData.transportSections.map((section) => section.transport_type);

      const transportTypeSet = new Set(transportTypeList);

      const startDateParsed = new Date(
        moment(convertStringToDate(formData.startDate)).format('YYYY-MM-DD')
      );
      const endDateParsed = new Date(
        moment(convertStringToDate(formData.startDate)).format('YYYY-MM-DD')
      );

      if (transportDirection === 'downstream') {
        endDateParsed.setDate(endDateParsed.getDate());
      }
      if (transportDirection === 'upstream') {
        startDateParsed.setDate(startDateParsed.getDate());
      }

      // Create transport route
      const responseTransportRoute = await apiFetch('post', '/transport_routes', {
        name: formData.name,
        quantity_transported: parseFloat(formData.quantity),
        unit_id: formData.unit.id,
        supplier: formData.supplier,
        refrigerated: formData.refrigerated.id === 'yes',
        start_date: startDateParsed,
        end_date: endDateParsed,
        organization_id: user?.selectedOrganization,
        transport_direction: transportDirection,
        transport_frequency: formData.frequency.id
        // transport_km: ''
      });

      // Bulk post transport sections
      const transportSectionsCreate: TransportSection[] = [];
      formData.transportSections.forEach((elem, index) => {
        let transport_type = elem.transport_type;
        let travel_method = undefined;
        let electric = false;
        if (
          elem.transport_type === 'truck' ||
          elem.transport_type === 'car' ||
          elem.transport_type === 'motorbike' ||
          elem.transport_type === 'bicycle'
        ) {
          transport_type = 'road';
          travel_method = elem.transport_type;
        }
        if (elem.transport_type === 'electric_car') {
          transport_type = 'road';
          travel_method = 'car';
          electric = true;
        }
        if (elem.transport_type === 'electric_bicycle') {
          transport_type = 'road';
          travel_method = 'bicycle';
          electric = true;
        }
        if (elem.transport_type === 'electric_kick_scooter') {
          transport_type = 'road';
          travel_method = 'electric_kick_scooter';
          electric = true;
        }

        transportSectionsCreate.push({
          location_origin_id:
            index === 0 ? uuidv4() : transportSectionsCreate[index - 1].location_destination_id,
          location_destination_id: uuidv4(),
          origin: elem.origin,
          destination: elem.destination,
          transport_type,
          travel_method,
          electric,
          transport_route_id: responseTransportRoute.data.id
        });
      });

      const data = await createTransportSections(
        user?.selectedOrganization ?? '',
        transportSectionsCreate
      );

      if (
        data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
        data === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
        data === 'SAME_COUNTRY_FOR_MARITIME_TRANSPORT'
      ) {
        setFormData((prev) => ({
          ...prev,
          errors: [...prev.errors, { error: 'invalidTravelMode' }]
        }));
        setLoadingButton(false);
        return;
      }

      addShipment({
        id: responseTransportRoute.data.id,
        name: formData.name,
        quantity_transported: parseFloat(formData.quantity),
        unit_id: formData.unit.id,
        supplier: formData.supplier,
        refrigerated: formData.refrigerated.id === 'yes',
        start_date: startDateParsed,
        end_date: endDateParsed,
        transport_route_type: transportTypeSet.size === 1 ? transportTypeList[0] : 'multiple',
        transport_origin: formData.transportSections[0].origin,
        transport_destination:
          formData.transportSections[formData.transportSections.length - 1].destination,
        transport_frequency: formData.frequency.id,
        transport_direction: transportDirection,
        status: responseTransportRoute.data.status
        // transport_km: ''
      });

      setLoadingButton(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (
        err.response.status === 422 &&
        (err.response.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
          err.response.data === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
          err.response.data === 'SAME_COUNTRY_FOR_MARITIME_TRANSPORT')
      ) {
        setFormData((prev) => ({
          ...prev,
          errors: [...formData.errors, { error: 'invalidTravelMode' }]
        }));
      }
      setLoadingButton(false);
    }
  };

  return (
    <div className='new-shipment'>
      <FormHeader
        title={t(`shipments.${transportDirection}.createNewShipment`)}
        description={t(`shipments.${transportDirection}.createNewShipmentDescription`)}
      />
      {(transportDirection === 'upstream' || transportDirection === 'downstream') && (
        <FormShipment
          formData={formData}
          setFormData={setFormData}
          transportDirection={transportDirection}
        />
      )}
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('shipments.save')}
          onClick={handleAddShipment}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {formData.errors && formData.errors.find((elem) => elem.error === 'invalidTravelMode') && (
        <span className='error-text error-font error-text-color'>
          {t('error.invalidTravelMode')}
        </span>
      )}
    </div>
  );
}

export default AddShipment;
