import SectionHeader from 'components/layout/sectionHeader/SectionHeader';
import { MaterialCard } from './components/MaterialCard';
import Button from 'components/ui/button/Button';
import CardWrapper from 'components/ui/cards/common/CardWrapper';
import { Filters } from 'components/layout/NewFilters/Filters';
import { useState } from 'react';
import { FilterText } from 'components/layout/NewFilters/FilterText';
import Icon from 'components/ui/icon/Icon';
import FilterHandlers from 'components/layout/NewFilters/FiltersHandlers';
import TotalLegendV2 from 'components/ui/totalLegend/TotalLegendV2';
import { useLCAMaterials } from '../hooks/useLCAMaterials';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import { ModalCreateMaterial } from './components/ModalCreateMaterial';
import { getDownloadURLMaterials } from 'services/api/lca/materialManagement';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { ModalBulkCreateMaterials } from './components/ModalBulkCreateMaterials';
import { NoMaterials } from './components/NoMaterials';
import { FilterSection } from 'components/layout/NewFilters/FilterSection';
import useCountries from 'components/measure/vehicles/hooks/useCountries';
import LoaderTables from 'components/ui/loaders/loaderTables/LoaderTables';
import { LCA_MATERIAL_MANAGEMENT_SIZE, LCA_MATERIAL_MANAGEMENT_SORTERS } from './constants';
import { useSelector } from 'customHooks/useSelector';
import { useDispatch } from 'react-redux';
import { setNotification } from 'actions/notification';
import { useTranslation } from 'react-i18next';

export const LCAMaterialManagement = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openCreateManual, setOpenCreateManual] = useState(false);
  const [openCreateBulk, setOpenCreateBulk] = useState(false);

  const org = useSelectedOrganization();
  const [downloading, setDownloading] = useState(false);

  const { lcaMaterialsLoader } = useSelector((state) => state.lcaMaterialsLoader);

  const { t } = useLCATranslation();
  const { t: tNotification } = useTranslation('translation', { keyPrefix: 'notification' });

  const dispatch = useDispatch();

  const countries = useCountries();

  const { data, fetchNextPage, hasNextPage, isLoading, total, isFetching } = useLCAMaterials({
    filters,
    sorters: LCA_MATERIAL_MANAGEMENT_SORTERS,
    size: LCA_MATERIAL_MANAGEMENT_SIZE
  });

  const noMaterials = !data?.length && !filters.length && !isLoading;

  const onClickMassUpload = () => {
    setOpenCreateBulk(true);
  };

  const onClickManualAdd = () => {
    setOpenCreateManual(true);
  };

  const onClickDownload = async () => {
    if (!org) return;

    setDownloading(true);
    await getDownloadURLMaterials(org.id);
    setDownloading(false);
    dispatch(setNotification(tNotification(`downloadFileEmail`)));
  };

  return (
    <>
      <ModalCreateMaterial show={openCreateManual} onClose={() => setOpenCreateManual(false)} />
      <ModalBulkCreateMaterials show={openCreateBulk} onClose={() => setOpenCreateBulk(false)} />
      <Filters.Root filters={filters} setFilters={setFilters} open={isOpen} setOpen={setIsOpen}>
        <Filters.Menu>
          <FilterSection.Layout title={t('materialManagement.supplier')}>
            <FilterText field='supplier' type='il' placeholder='IKEA S.A.' />
          </FilterSection.Layout>
          <FilterSection.Multiple
            field='country'
            options={countries.map((country) => ({
              value: country.id,
              label: country.name
            }))}
            title={t('materialManagement.supplierCountry')}
            type='in'
            maxElements={5}
          />
        </Filters.Menu>
        <div className='flex-col gap-y-6 h-95svh'>
          <SectionHeader title={t('materialManagement.title')} breadcrumb={<Breadcrumb />} />

          <CardWrapper
            style={{
              padding: '1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              flexGrow: 1,
              height: '100%'
            }}>
            {!isLoading && noMaterials && (
              <NoMaterials
                onClickMassUpload={onClickMassUpload}
                onClickManualAdd={onClickManualAdd}
              />
            )}
            {!noMaterials && (
              <>
                <div className='flex justify-between items-center'>
                  <div className='flex gap-x-4'>
                    <FilterText
                      field='name'
                      type='il'
                      placeholder={t('materialManagement.filterTextPlaceholder')}
                    />
                    <FilterHandlers blacklistedFilters={{ all: ['name'] }} />
                  </div>
                  <div className='bg-neutral-gray-80 p-2 flex gap-x-2 rounded-4'>
                    <Button
                      text={t('materialManagement.downloadMaterials')}
                      lookAndFeel='secondary'
                      size='small'
                      iconNode={
                        !downloading ? <Icon icon='download' color='gray-dark' /> : undefined
                      }
                      onClick={onClickDownload}
                      loading={downloading}
                    />
                    <TotalLegendV2
                      totalElements={[{ value: total, label: t('materialManagement.materials') }]}
                      totalLabel={t('materialManagement.totalMaterials')}
                    />
                  </div>
                </div>

                <div className='flex-grow h-full overflow-y-auto' id='infinite-scroll-container'>
                  <InfiniteScroll
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    dataLength={data?.length ?? 0}
                    loader={isFetching ? <LoaderTables mode='fit' /> : null}
                    scrollableTarget='infinite-scroll-container'>
                    <div className='grid-cols-3 gap-4'>
                      <div className='bg-neutral-gray-80 py-6 h-full border-box rounded-8 grid-cols-1'>
                        <div className='flex gap-x-2 justify-center items-center'>
                          <Button
                            lookAndFeel='primary'
                            iconNode={<Icon icon='upload' color='white' />}
                            text={t('materialManagement.uploadMaterials')}
                            size='small'
                            style={{ width: 'fit-content' }}
                            onClick={onClickMassUpload}
                          />
                          <Button
                            lookAndFeel='secondary'
                            iconNode={<Icon icon='add' color='gray-dark' />}
                            text={t('materialManagement.addMaterial')}
                            size='small'
                            style={{ width: 'fit-content' }}
                            onClick={onClickManualAdd}
                          />
                        </div>
                      </div>
                      {isLoading && <LoaderTables mode='fit' />}
                      {lcaMaterialsLoader && (
                        <div className='flex gap-x-2 items-center col-span-2'>
                          <LoaderTables mode='fit' />
                          <span className='inter font-12 weight-400 text-black'>
                            {t('materialManagement.uploadingMaterials')}
                          </span>
                        </div>
                      )}
                      {!lcaMaterialsLoader &&
                        data?.map((material) => (
                          <MaterialCard key={material.id} material={material} />
                        ))}
                    </div>
                  </InfiniteScroll>
                </div>
              </>
            )}
          </CardWrapper>
        </div>
      </Filters.Root>
    </>
  );
};
