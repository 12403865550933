import { TransportSection } from 'types/entities/shipment';
import apiFetch from 'utils/apiFetch';
import { getUrl } from 'utils/url';

export const uploadTransportRoutesFile = async (
  file: string,
  file_name: string,
  organization_id: string,
  transport_direction: string
) => {
  try {
    const response = await apiFetch('POST', '/transport_routes/csv', {
      file_name,
      data: file,
      org_id: organization_id,
      transport_direction
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteShipmentsFromOrganization = async (
  organization_id: string,
  transport_direction: string,
  status: string,
  startDate?: string,
  endDate?: string
) => {
  try {
    const params: any = {
      transport_direction,
      status: status !== 'all' ? status : null
    };
    if (startDate) {
      params['start_date'] = startDate;
    }
    if (endDate) {
      params['end_date'] = endDate;
    }
    const response = await apiFetch(
      'DELETE',
      `/transport_routes/organizations/${organization_id}`,
      null,
      {
        x_organization_id: organization_id
      },
      params
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getShipmentsByOrgIdStatus = async (
  organization_id: string,
  transport_direction: string
) => {
  try {
    const response = await apiFetch(
      'GET',
      `/transport_routes/organizations/${organization_id}/template`,
      null,
      {
        x_organization_id: organization_id
      },
      {
        transport_direction,
        shipment_status: 'error'
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const createTransportSections = async (
  organization_id: string,
  transport_sections: TransportSection[]
) => {
  try {
    const response = await apiFetch('POST', '/transport_sections', transport_sections, {
      x_organization_id: organization_id
    });
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      error.response.status === 422 &&
      (error.response.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
        error.response.data === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
        'SAME_COUNTRY_FOR_MARITIME_TRANSPORT')
    ) {
      return error.response.data;
    }
    return null;
  }
};

type UpdateTransportSection = {
  origin: string;
  destination: string;
  transport_type: string;
  travel_method?: string;
};
export const patchTransportSections = async (
  organization_id: string,
  transport_sections: UpdateTransportSection[]
) => {
  try {
    const response = await apiFetch('PATCH', '/transport_sections', transport_sections, {
      x_organization_id: organization_id
    });
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      error.response.status === 422 &&
      (error.response.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES' ||
        error.response.data === 'INVALID_TRANSPORT_SECTION_PROVIDED' ||
        'SAME_COUNTRY_FOR_MARITIME_TRANSPORT')
    ) {
      return error.response.data;
    }
    return null;
  }
};

export const getPresignedUrlShipments = async (file_name: string, transport_direction: string) => {
  try {
    const response = await apiFetch('POST', '/transport_routes/bulk/csv', {
      file_name,
      direction: transport_direction
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendShipmentFileByEmail = async (
  file: File,
  file_name: string,
  transport_direction: string
) => {
  try {
    const response = await apiFetch('POST', '/transport_routes/upload', {
      file,
      file_name,
      test: false,
      direction: transport_direction
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTransportRoutes = async (
  page: number,
  size: number,
  filters: IFilter[],
  sorters: ISorter[]
) => {
  try {
    const queryParams: QueryParams[] = [];
    queryParams.push({
      key: 'page',
      value: page.toString()
    });
    queryParams.push({
      key: 'size',
      value: size.toString()
    });

    const url = getUrl('/transport_routes', {
      filters,
      sorters,
      queryParams
    });
    const response = await apiFetch('GET', url);
    return response.data;
  } catch (error) {
    return null;
  }
};

type UpdateShipmentBody = {
  name?: string;
  supplier?: string;
  start_date: Date;
  end_date: Date;
  quantity_transported: number;
  unit_id: string;
  organization_id: string;
};
export const updateShipment = async (id: string, shipment: UpdateShipmentBody) => {
  try {
    const response = await apiFetch('PATCH', `/transport_routes/${id}`, shipment);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTransportCombinations = async () => {
  try {
    const response = await apiFetch('GET', '/transport_sections/travel_method_characteristics');
    return response.data;
  } catch (error) {
    return null;
  }
};
