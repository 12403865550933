import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { getLCAMaterials } from 'services/api/lca/lcaPortfolio';

type Params<T extends object> = {
  filters?: IFilter[];
  sorters?: ISorter[];
  size?: number;
  adapter?: (data: InfiniteData<IGetLCAMaterialsResponse, number>) => { items: T[]; total: number };
};

const defaultAdapter = (data: InfiniteData<IGetLCAMaterialsResponse, number>) => {
  return data.pages.reduce(
    (acc, page) => {
      return {
        items: [...acc.items, ...page.items],
        total: page.total
      };
    },
    { items: [] as LCAMaterial[], total: 0 }
  );
};

export const useLCAMaterials = <
  T extends object = LCAMaterial,
  _Y extends { items: T[]; total: number } = { items: T[]; total: number }
>({
  filters,
  sorters,
  size,
  adapter
}: Params<T>) => {
  const org = useSelectedOrganization();

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['lca-materials', org?.id, JSON.stringify(filters), JSON.stringify(sorters), size],
    queryFn: ({ pageParam }) => getLCAMaterials({ page: pageParam, filters, sorters, size }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * (size ?? 10) >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
    select: (data) => {
      if (!adapter) {
        return defaultAdapter(data) as _Y;
      }

      return adapter(data);
    }
  });

  return {
    data: data?.items,
    total: data?.total ?? 0,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  };
};
