import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDatapointToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useDebounceValue from '../../../../../../../customHooks/useDebounceValue';
import { createDatapointValue, updateDatapointValue } from '../../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../../types/entities/esgMetrics';
import { InputSize } from '../../../../../../../types/utilsEnums/input';
import { FilterText } from '../../../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../../../layout/NewFilters/Filters';
import Button from '../../../../../../ui/button/Button';
import InfiniteList from '../../../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../../../ui/modal/Modal';
import Sorting from '../../../../../../ui/sorting/Sorting';
import TotalLegend from '../../../../../../ui/totalLegend/TotalLegend';
import '../../styles.scss';
import { ModalDatapoint } from '../modal/ModalDatapoint';
import useColumns from './hooks/useColumns';
import useSortingOptions from './hooks/useSortingOptions';
import { CSRDModalPremium } from './components/CSRDModalPremium';
import { useGetDatapoints } from './hooks/useGetDatapoints';

type Props = {
  category: string;
  subcategory?: string;
};

export const DatapointsTable = ({ category, subcategory }: Props) => {
  const { t } = useTranslation();
  const [datapointToEdit, setDatapointToEdit] = useState<EsgMetricsEnhanced>();
  const [filters, setFilters] = useState<IFilter[]>([
    { field: 'esg_category', type: 'eq', value: category },
    { field: 'data_source', type: 'eq', value: 'F' }
  ]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'short_name',
      order: 'asc'
    }
  ]);
  const { startDate, endDate } = useContext(FilterDatesContext);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const debounceValue = useDebounceValue(searchValue);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, editElement } = useGetDatapoints({
    filters,
    sorters,
    startDate,
    endDate
  });

  const handleEditRelevance = async (value: SelectOptionFormat, data: EsgMetricsEnhanced) => {
    const newData = {
      ...data,
      start_date: data.start_date.replaceAll('/', '-'),
      end_date: data.end_date.replaceAll('/', '-'),
      relevance: value.id
    };

    if (!data.organization_data_point_id) {
      await createDatapointValue(
        parseDatapointToBackend(newData),
        data.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    } else {
      await updateDatapointValue(
        data.organization_data_point_id,
        parseDatapointToBackend(newData),
        data.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    }

    editElement(newData);
  };

  const columns = useColumns({
    category,
    subcategory,
    setDatapointToEdit,
    handleEditRelevance,
    setShowPremiumModal
  });

  const sortingOptions = useSortingOptions();

  useEffect(() => {
    const newFilters = [...filters];
    if (subcategory) {
      const foundSubcategoryFilterIndex = filters.findIndex(
        (filter) => filter.field === 'esg_subcategory'
      );
      if (foundSubcategoryFilterIndex === -1) {
        newFilters.push({ field: 'esg_subcategory', type: 'eq', value: subcategory });
      } else {
        newFilters[foundSubcategoryFilterIndex] = {
          field: 'esg_subcategory',
          type: 'eq',
          value: subcategory
        };
      }
    }

    if (debounceValue) {
      newFilters.push({ field: 'name', type: 'il', value: debounceValue });
    } else {
      const foundSearchFilterIndex = filters.findIndex((filter) => filter.field === 'short_name');
      if (foundSearchFilterIndex !== -1) {
        newFilters.splice(foundSearchFilterIndex, 1);
      }
    }

    setFilters(newFilters);
  }, [subcategory, debounceValue]);

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };
  return (
    <div className='infinite-list-datapoints flex flex-col'>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu></Filters.Menu>
        <InfiniteList
          fetchData={fetchNextPage}
          data={data?.items ?? []}
          columns={columns}
          loading={isFetchingNextPage}
          firstLoading={isLoading}
          total={data?.total}
          header={
            <div
              className='flex justify-between items-center'
              style={{
                width: '100%'
              }}>
              <div className='flex items-center gap-x-2'>
                <FilterText
                  field='short_name'
                  type='il'
                  placeholder={t('esgMetrics.general.idDatapoint')}
                  size={InputSize.SMALL}
                />
                <Sorting
                  options={sortingOptions}
                  label={t('input.sorting.orderBy')}
                  placeholder={t('input.placeholderSelect')}
                  onSort={onSort}
                  defaultSort={{
                    id: sorters[0].field,
                    name: t(`input.sorting.datapoints.${sorters[0].field}`),
                    direction: sorters[0].order
                  }}
                />
              </div>
              <div className='flex justify-end items-center'>
                <Button
                  style={{
                    width: '7rem',
                    marginRight: '0.66rem'
                  }}
                  size='tiny'
                  lookAndFeel={'primary'}
                  disabled
                  text={t('esgMetrics.general.download')}
                />
                <TotalLegend
                  total={data?.total || 0}
                  // total2={pendings}
                  loading={false}
                  i18key={'esgMetrics'}
                />
              </div>
            </div>
          }
        />
      </Filters.Root>
      {datapointToEdit && datapointToEdit.value && (
        <Modal.WithPortal
          show={!!datapointToEdit && !!datapointToEdit.value}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            edit
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={editElement}
          />
        </Modal.WithPortal>
      )}
      {datapointToEdit && !datapointToEdit.value && (
        <Modal.WithPortal
          show={!!datapointToEdit && !datapointToEdit.value}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={editElement}
          />
        </Modal.WithPortal>
      )}
      {showPremiumModal && (
        <Modal.WithPortal show={showPremiumModal} onClose={() => setShowPremiumModal(false)}>
          <CSRDModalPremium />
        </Modal.WithPortal>
      )}
    </div>
  );
};
