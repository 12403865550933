export enum CarSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  NONE = ''
}

export enum TransportOptions {
  CAR = 'car',
  CAR_SMALL = 'carSmall',
  CAR_MEDIUM = 'carMedium',
  CAR_LARGE = 'carLarge',
  MOTORBIKE = 'motorbike',
  METRO = 'metro',
  WALKING = 'walking',
  TRAIN = 'train',
  BUS = 'bus',
  TROLLEYBUS = 'trolleybus',
  BICYCLE = 'bicycle',
  TELECOMMUTING = 'telecommuting',
  AIRCRAFT = 'aircraft',
  TRAM = 'tram',
  ELECTRIC_KICK_SCOOTER = 'electric_kick_scooter',
  FERRY = 'ferry'
}

export enum CommutingType {
  IN_LABORE = 'in_labore',
  IN_ITINERE = 'in_itinere'
}
