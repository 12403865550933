import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLCATranslation } from '../../../customHooks/translations/useLCATranslation';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { Card } from '../../layout/Cards/Cards';
import { FilterText } from '../../layout/NewFilters/FilterText';
import { Filters } from '../../layout/NewFilters/Filters';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import TotalLegendV2 from '../../ui/totalLegend/TotalLegendV2';
import { DeleteLCAModal } from '../deleteLCAModal/DeleteLCAModal';
import { CreateLCA } from './CreateLCA/CreateLCA';
import { EditLCAModal } from './EditLCA/EditLCAModal';
import { useLCAPortfolio } from './hooks/useLCAPortfolio';
import YoutubeVideo from '../../ui/videoPlayer/YoutubeVideo';
import VideoTutorialPopup from '../../ui/videoTutorialPopup/VideoTutorialPopup';
import Modal from '../../ui/modal/Modal';
import { useDuplicateLCA } from './hooks/useDuplicateLCA';
import { Status } from '../../../types/utilsEnums/status';
import Label from '../../ui/label/Label';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import { AZKOYEN_OLD_IDS } from './LCADetail/constants';

export const LCAPortfolio = () => {
  const { t } = useLCATranslation();

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [lcaToDelete, setLCAToDelete] = useState<string | null>(null);
  const [lcaToEdit, setLCAToEdit] = useState<string | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const { duplicateLCA } = useDuplicateLCA();

  const flags = useFeatureFlags();

  const { data, isLoading, fetchNextPage, hasNextPage } = useLCAPortfolio({
    filters,
    old_flow: !flags?.lcaScenariosDiagram
  });

  const getOptions = (item: { id: string }) => {
    const options = [
      {
        id: item.id + '-delete',
        name: t('portfolio.delete'),
        onClick: () => {
          setLCAToDelete(item.id);
        }
      },
      {
        id: item.id + '-edit',
        name: t('portfolio.edit'),
        onClick: () => {
          setLCAToEdit(item.id);
        }
      }
    ];

    if (!AZKOYEN_OLD_IDS.includes(item.id)) {
      options.push({
        id: item.id + '-duplicate',
        name: t('portfolio.duplicate'),
        onClick: () => {
          duplicateLCA({ acv_id: item.id });
        }
      });
    }

    return options;
  };

  /* if (!isLoading && !data?.items.length && !flags?.lcaScenariosDiagram) {
    return <RequestLCA />;
  } */

  return (
    <section className='flex-col gap-y-6 h-95svh overflow-hidden'>
      <Filters.Root.FiltersOnly filters={filters} setFilters={setFilters}>
        {Boolean(lcaToDelete) && (
          <DeleteLCAModal lcaToDelete={lcaToDelete} onClose={() => setLCAToDelete(null)} />
        )}
        {Boolean(lcaToEdit) && <EditLCAModal lcaId={lcaToEdit} setLcaId={setLCAToEdit} />}

        <SectionHeader title={t('portfolio.sectionHeader')} />
        <div className='flex items-center justify-between w-full'>
          <div className='card-bg-color w-70 p-2 card-border-color border-solid border-1 rounded-8'>
            <FilterText field='name' type='il' placeholder={t('portfolio.searchBar')} />
          </div>
          <div className='card-bg-color p-2 card-border-color border-solid border-1 rounded-8'>
            <TotalLegendV2
              totalLabel={t('portfolio.total')}
              totalElements={[
                {
                  value: data?.total ?? 0,
                  label: t('portfolio.lca')
                }
              ]}
            />
          </div>
        </div>

        <VideoTutorialPopup
          title={t('video.title')}
          description={t('video.description')}
          image='/images/michel.png'
          onClick={() => setShowVideo(true)}
        />
        <Modal.WithPortal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
          <YoutubeVideo url='https://www.youtube.com/embed/zuBlMV44jTM?si=oT8FZhYxGOaQnjuu' />
        </Modal.WithPortal>

        <div className='flex-grow h-full overflow-y-auto' id='infinite-scroll-container'>
          {isLoading && <LoaderTables />}

          {!isLoading && data && (
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={data?.items.length ?? 0}
              loader={null}
              scrollableTarget='infinite-scroll-container'>
              <Card.Container cardSizeInRem={10}>
                <CreateLCA />
                {data?.items.map((item) => (
                  <Card.Link to={item.id} key={item.id} disabled={item.status === Status.LOADING}>
                    <Card.Layout type='item' hoverEnabled={!(item.status === Status.LOADING)}>
                      {item.status !== Status.LOADING && (
                        <Card.ButtonDropdown options={getOptions(item)} position='top' />
                      )}
                      <Card.Title text={item.name} disabled={item.status === Status.LOADING} />
                      <Card.Subtitle
                        text={item.description}
                        disabled={item.status === Status.LOADING}
                      />

                      {item.status === Status.LOADING && (
                        <TooltipWrapper text={t('portfolio.duplicatingTooltip')}>
                          <Label lookAndFeel='secondary'>{t('portfolio.duplicating')}</Label>
                        </TooltipWrapper>
                      )}
                    </Card.Layout>
                  </Card.Link>
                ))}
              </Card.Container>
            </InfiniteScroll>
          )}
        </div>
      </Filters.Root.FiltersOnly>
    </section>
  );
};
